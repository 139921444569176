import axiosClient from "utils/api/axios-client";

export default {
    getReport: (params) => {
        const url = "/api/report-playlists";
        return axiosClient.get(url, { params });
    },
    exportReport: (params) => {
        const url = `/api/report-playlists/export`;
        return axiosClient.get(url, { params });
    },
};
