import {
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

export default function Actions({
    record,
    onEdit,
    onDelete,
    onConfigKeywords,
    onConfigComments,
    onConfigChannels,
    onConfigHobbies,
    onConfigApp,
}) {
    const KEY_ACTIONS = {
        EDIT: "edit",
        CONFIG_KEYWORDS: "config-keywords",
        CONFIG_COMMENTS: "config-comments",
        CONFIG_CHANNELS: "config-channels",
        CONFIG_HOBBIES: "config-hobbies",
        CONFIG_APP: "config-app",
        DELETE: "delete",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.EDIT:
                onEdit(record);
                break;
            case KEY_ACTIONS.CONFIG_KEYWORDS:
                onConfigKeywords(record);
                break;
            case KEY_ACTIONS.CONFIG_COMMENTS:
                onConfigComments(record);
                break;
            case KEY_ACTIONS.CONFIG_CHANNELS:
                onConfigChannels(record);
                break;
            case KEY_ACTIONS.CONFIG_HOBBIES:
                onConfigHobbies(record);
                break;
            case KEY_ACTIONS.CONFIG_APP:
                onConfigApp(record);
                break;
            case KEY_ACTIONS.DELETE:
                onDelete(record);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.EDIT,
            label: "Sửa",
            icon: <EditOutlined />,
        },
        {
            key: KEY_ACTIONS.DELETE,
            danger: true,
            label: "Xóa",
            icon: <DeleteOutlined />,
        },
    ];
    if (record.phase === "feed") {
        actions.splice(
            1,
            0,
            {
                key: KEY_ACTIONS.CONFIG_KEYWORDS,
                label: "Cấu hình từ khóa",
                icon: <SettingOutlined />,
            },
            {
                key: KEY_ACTIONS.CONFIG_CHANNELS,
                label: "Cấu hình kênh",
                icon: <SettingOutlined />,
            },
            {
                key: KEY_ACTIONS.CONFIG_COMMENTS,
                label: "Cấu hình bình luận",
                icon: <SettingOutlined />,
            },
            {
                key: KEY_ACTIONS.CONFIG_HOBBIES,
                label: "Cấu hình sở thích",
                icon: <SettingOutlined />,
            },
            {
                key: KEY_ACTIONS.CONFIG_APP,
                label: "Cấu hình app nuôi",
                icon: <SettingOutlined />,
            }
        );
    } else if (record.phase === "seeding") {
        actions.splice(1, 0, {
            key: KEY_ACTIONS.CONFIG_APP,
            label: "Cấu hình app",
            icon: <SettingOutlined />,
        });
    }
    return (
        <Dropdown menu={{ items: actions, onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
