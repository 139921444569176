import {
    Button,
    Card,
    Col,
    Input,
    Modal,
    Row,
    Select,
    Space,
    Statistic,
    Switch,
    Table,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    HddOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
} from "@ant-design/icons";
import moment from "moment/moment";
import { paginationParams } from "utils/constants";
import * as hostAction from "./slice";

function Host() {
    const dispatch = useDispatch();
    const { hosts, summary, pagination, isLoading, isLoadingSummary } =
        useSelector((state) => state.host);

    const [searchName, setSearchName] = useState("");
    const [searchRun, setSearchRun] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);

    useEffect(() => {
        dispatch(hostAction.fetchHosts(pagination));
        dispatch(hostAction.fetchHostSummary());
    }, []);

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSelectSearchRun = (run) => {
        setSearchRun(run);
        dispatch(
            hostAction.fetchHosts({
                ...pagination,
                run: run,
                name: searchName,
                current: 1,
            })
        );
    };

    const onChangeRunning = (id, run) => {
        Modal.confirm({
            title: `Xác nhận ${run ? "Start" : "Stop"} host?`,
            content: `Bạn có chắc muốn ${run ? "Start" : "Stop"} host này?`,
            onOk: () => dispatch(hostAction.updateRunningHost(id, run ? 1 : 0)),
            centered: true,
        });
    };

    const onStartRunningMany = () => {
        const selectedHosts = selectedRows.map((e) => e.vm_id);
        Modal.confirm({
            title: `Xác nhận Start host?`,
            content: `Bạn có chắc muốn Start ${selectedHosts.length} host đã chọn?`,
            onOk: () => dispatch(hostAction.startHosts(selectedHosts)),
            centered: true,
        });
    };

    const onStopRunningMany = () => {
        const selectedHosts = selectedRows.map((e) => e.vm_id);
        Modal.confirm({
            title: `Xác nhận Stop host?`,
            content: `Bạn có chắc muốn Stop ${selectedHosts.length} host đã chọn?`,
            onOk: () => dispatch(hostAction.stopHosts(selectedHosts)),
            centered: true,
        });
    };

    const onSearch = (searchValue) => {
        dispatch(
            hostAction.fetchHosts({
                ...pagination,
                name: searchValue,
                run: searchRun,
                current: 1,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            hostAction.fetchHosts({
                ...pagination,
                name: searchName,
                run: searchRun,
            })
        );
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "vm_id",
            width: "120px",
            fixed: "left",
        },
        {
            title: "IP",
            dataIndex: "ip",
            width: "300px",
            fixed: "left",
        },
        {
            title: "Running",
            align: "center",
            width: "130px",
            fixed: "left",
            render: (record) => (
                <Switch
                    onChange={(checked) =>
                        onChangeRunning(record.vm_id, checked)
                    }
                    checked={record.run === 1}
                />
            ),
        },
        {
            title: "Ghi chú",
            dataIndex: "note",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Profiles",
            dataIndex: "profiles",
            width: "130px",
            align: "center",
        },
        {
            title: "Devices",
            dataIndex: "devices",
            width: "120px",
            align: "center",
        },
        {
            title: "Version",
            dataIndex: "version",
            width: "120px",
            align: "center",
        },
        {
            title: "Ngày tạo",
            dataIndex: "create_date",
            align: "center",
            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "update_time",
            align: "center",
            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
    ];

    const rowSelection = {
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    };
    return (
        <>
            <Typography.Title level={2}>QUẢN LÝ HOST</Typography.Title>
            <br />
            <Row gutter={12} justify="start">
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Total Host"
                            value={summary.total}
                            prefix={<HddOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Running"
                            value={summary.running}
                            prefix={<PlayCircleOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Stop"
                            value={summary.stop}
                            prefix={<PauseCircleOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                {selectedRows.length ? (
                    <Space size="middle">
                        <Button
                            type="primary"
                            danger
                            icon={<PauseCircleOutlined />}
                            onClick={onStopRunningMany}
                        >
                            Dừng tất cả
                        </Button>
                        <Button
                            type="primary"
                            icon={<PlayCircleOutlined />}
                            onClick={onStartRunningMany}
                        >
                            Chạy tất cả
                        </Button>
                    </Space>
                ) : (
                    <>&nbsp;</>
                )}
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Tìm theo trạng thái"
                        onChange={onSelectSearchRun}
                    >
                        <Select.Option key="all" value="">
                            Tất cả
                        </Select.Option>
                        <Select.Option key="running" value="1">
                            Running
                        </Select.Option>
                        <Select.Option key="stop" value="0">
                            Stop
                        </Select.Option>
                    </Select>
                    <Input.Search
                        placeholder="Tìm kiếm tên host"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            <Table
                bordered
                loading={isLoading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={hosts}
                rowKey={(record) => record.vm_id}
                pagination={{
                    ...paginationParams,
                    ...pagination,
                }}
                scroll={{ x: 1500, y:'calc(100vh - 600px)' }}
                onChange={onTableChange}
            />
        </>
    );
}

export default Host;
