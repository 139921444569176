import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/sim-infos";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/sim-infos/summary";
        return axiosClient.get(url, { params });
    },
    insert: (payload) => {
        const url = "/api/sim-infos";
        return axiosClient.post(url, payload);
    },
    exportSim: () => {
        const url = `/api/sim-infos/export`;
        return axiosClient.get(url);
    },
};
