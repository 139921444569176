import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    proxys: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "proxy",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },

        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setProxys: (state, action) => {
            state.proxys = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addProxy: (state, action) => {
            state.isCompleted = true;
            state.proxys.unshift(action.payload);
        },
        removeProxy: (state, action) => {
            state.isCompleted = true;
            state.proxys = state.proxys.filter(
                (proxy) => proxy.id !== action.payload.id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setProxys,
    setPagination,
    addProxy,
    removeProxy,
} = slice.actions;

export const fetchProxys = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setProxys(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const insertProxy = (listProxy) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.insert(listProxy);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import danh sách proxy thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProxys({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const updateEnable = (ids, enable) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.updateEnable(ids, enable);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success(
                    `${enable == 1 ? "Enable" : "Disable"} proxy thành công!`
                );
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProxys({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const moveToGroup = (ids, group_id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.moveToGroup(ids, group_id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success(
                    `Chuyển nhóm thành công ${ids.length} proxy !!`
                );
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProxys({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
