import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/clicks/report";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/clicks/report/summary";
        return axiosClient.get(url, { params });
    },
    getFilter: (params) => {
        const url = "/api/clicks/report/filter";
        return axiosClient.get(url, { params });
    },
    getTotalReport: (params) => {
        const url = "/api/clicks/manage/totalReport";
        return axiosClient.get(url, { params });
    },
};
