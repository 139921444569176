import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/projects";
        return axiosClient.get(url, { params });
    },
    getList: (params) => {
        const url = "/api/projects/list";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/projects/${id}`;
        return axiosClient.get(url);
    },
    create: (payload) => {
        const url = `/api/projects`;
        return axiosClient.post(url, payload);
    },
    update: (payload) => {
        const url = `/api/projects/${payload.id}`;
        return axiosClient.put(url, payload);
    },
    delete: (id) => {
        const url = `/api/projects/${id}`;
        return axiosClient.delete(url);
    },
    deleteMany: (ids) => {
        const url = `/api/projects/delete-many`;
        return axiosClient.post(url, ids);
    },
};
