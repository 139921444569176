import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    profiles: [],
    summary: {},
    filterData: {},
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isLoadingSummary: false,
    isLoadingFilter: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingSummary: (state, action) => {
            state.isLoadingSummary = action.payload;
        },
        setIsLoadingFilter: (state, action) => {
            state.isLoadingFilter = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setProfiles: (state, action) => {
            state.profiles = action.payload;
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setFilter: (state, action) => {
            state.filterData = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addProfile: (state, action) => {
            state.isCompleted = true;
            state.profiles.unshift(action.payload);
        },
        editProfile: (state, action) => {
            state.isCompleted = true;
            state.profiles = state.profiles.map((profile) =>
                profile.id === action.payload.id ? action.payload : profile
            );
        },
        removeProfile: (state, action) => {
            state.isCompleted = true;
            state.profiles = state.profiles.filter(
                (profile) => profile.id !== action.payload.id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingSummary,
    setIsLoadingFilter,
    setIsCompleted,
    setIsProcessing,
    setProfiles,
    setSummary,
    setFilter,
    setPagination,
    addProfile,
    editProfile,
    removeProfile,
} = slice.actions;

export const fetchProfileSummary = () => {
    return async (dispatch) => {
        dispatch(setIsLoadingSummary(true));
        try {
            const data = await api.getSummary();
            dispatch(setSummary(data.data));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingSummary(false));
        }
    };
};
export const fetchProfiles = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        dispatch(setIsLoadingFilter(true));
        try {
            const data = await api.getAll(params);
            dispatch(setProfiles(data.data));
            dispatch(setFilter(data.filterData));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
            dispatch(setIsLoadingFilter(false));
        }
    };
};

export const insertProfile = (listProfile) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.insert(listProfile);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import danh sách profile thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProfiles({ pagination: { current: 1, pageSize: 10 } })
                );
                dispatch(fetchProfileSummary());
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const moveToGroup = (ids, group_id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.moveToGroup(ids, group_id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success(
                    `Chuyển nhóm thành công ${ids.length} profile !!`
                );
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProfiles({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const restore = (ids, byCsv) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.restore(ids, byCsv);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success(`Restore thành công ${ids.length} profile !!`);
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProfiles({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const exportProfile = (params) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.exportProfile(params);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                exp(data);
                toast.success("Xuất thành công danh sách profiles");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProfiles({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deleteProfile = (emails) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.deleteProfile(emails);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Xóa thành công danh sách profiles");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchProfiles({ pagination: { current: 1, pageSize: 10 } })
                );
                dispatch(fetchProfileSummary());
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

const exp = (data) => {
    try {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `profiles.csv`;
        link.click();
    } catch (error) {
        console.log(error);
    }
};

export default slice.reducer;
