import { Button, Input, Modal, Row, Space, Table, Tag, Typography } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PlusOutlined } from "@ant-design/icons";
import { paginationParams } from "utils/constants";
import Actions from "./components/Action";
import ProjectDetailModal from "./components/DetailModal";
import * as projectAction from "./slice";

function Project() {
    const dispatch = useDispatch();
    const { projects, pagination, isLoading, isProcessing, isCompleted } =
        useSelector((state) => state.project);

    const [searchName, setSearchName] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedProject, setSelectedProject] = useState(null);
    const [openDetailModal, setOpenDetailModal] = useState(false);

    useEffect(() => {
        dispatch(projectAction.fetchProjects(pagination));
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setSelectedProject(null);
        }
    }, [isCompleted]);

    const onOpenDetailModal = (project = null) => {
        setSelectedProject(project);
        setOpenDetailModal(true);
    };

    const onCloseDetailModal = () => {
        setOpenDetailModal(false);
        setSelectedProject(null);
        dispatch(projectAction.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSubmit = (project) => {
        if (!project.id) {
            dispatch(projectAction.createProject(project));
        } else {
            dispatch(projectAction.updateProject(project));
        }
    };

    const onDelete = (project) => {
        Modal.confirm({
            title: `Xác nhận xóa project`,
            content: `Bạn có chắc chắn muốn xóa project này?`,
            onOk: () => dispatch(projectAction.deleteProject(project.id)),
            centered: true,
        });
    };

    const onDeleteMany = () => {
        const selectedProjects = selectedRows.map((e) => e.id);
        Modal.confirm({
            title: `Xác nhận xóa project`,
            content: `Bạn có chắc chắn muốn xóa ${selectedProjects.length} project đã chọn?`,
            onOk: () =>
                dispatch(projectAction.deleteProjects(selectedProjects)),
            centered: true,
        });
    };

    const onSearch = () => {
        dispatch(
            projectAction.fetchProjects({
                ...pagination,
                name: searchName,
                current: 1,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            projectAction.fetchProjects({
                ...pagination,
                name: searchName,
            })
        );
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "100px",
            fixed: "left",
        },
        {
            title: "Tên",
            dataIndex: "name",
            fixed: "left",
            render: (name) => <a>{name}</a>,
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Tổng user",
            dataIndex: "user_count",
            align: "center",
        },
        {
            title: "Số user đang nuôi",
            dataIndex: "feed_user_count",
            align: "center",
        },
        {
            title: "Số user đang SEO",
            dataIndex: "seed_user_count",
            align: "center",
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            align: "center",
            width: "10%",
            render: (priority) =>
                priority ? (
                    <Tag color="warning">Đang ưu tiên</Tag>
                ) : (
                    <Tag>Mặc định</Tag>
                ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "10%",
            fixed: "right",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                />
            ),
        },
    ];

    const rowSelection = {
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>QUẢN LÝ PROJECT</Typography.Title>
                <Space size="middle">
                    <Input.Search
                        placeholder="Tìm kiếm tên project"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <br />
            {selectedRows.length ? (
                <Space size="middle" className="mb-4">
                    <Button type="primary" danger onClick={onDeleteMany}>
                        Xóa tất cả
                    </Button>
                </Space>
            ) : null}
            <Table
                bordered
                loading={isLoading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={projects}
                rowKey={(record) => record.id}
                pagination={{
                    ...paginationParams,
                    ...pagination,
                }}
                scroll={{ x: 1500, y: 'calc(100vh - 430px)' }}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <ProjectDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCloseDetailModal}
                    project={selectedProject}
                />
            )}
        </>
    );
}

export default Project;
