import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/profiles";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/profiles/summary";
        return axiosClient.get(url, { params });
    },
    getListGroup: () => {
        const url = "/api/profiles/get-list-group";
        return axiosClient.get(url);
    },
    moveToGroup: (ids, group_id, bycsv) => {
        const url = `/api/profiles/move-group`;
        return axiosClient.patch(url, { ids, group_id, bycsv });
    },
    restore: (ids, bycsv) => {
        const url = `/api/profiles/restore`;
        return axiosClient.patch(url, { ids, bycsv });
    },
    insert: (payload) => {
        const url = "/api/profiles";
        return axiosClient.post(url, payload);
    },
    exportProfile: (params) => {
        const url = `/api/profiles/export`;
        return axiosClient.get(url, { params });
    },
    deleteProfile: (payload) => {
        const url = `/api/profiles/delete`;
        return axiosClient.post(url, payload);
    },
};
