import { Layout } from "antd";
import * as authActions from "features/auth/slice";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Sider from "./Sider";
const { Content, Footer } = Layout;

export default function MyLayout() {
    const { isLoggedIn, userInfo } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        dispatch(authActions.checkTokenAndLogin());
    }, [location.pathname]);

    if (!isLoggedIn || !userInfo) {
        return <></>;
    }
    return (
        <>
            <Layout hasSider>
                <Sider />
                <Layout>
                    <Header />
                    <Content className="p-6">
                        <div className="bg-white p-6 pt-1">
                            <Outlet />
                        </div>
                    </Content>
                    <Footer className="!bg-white">
                        Copyright by BoostGo.Corp © 2023
                    </Footer>
                </Layout>
            </Layout>
        </>
    );
}

Layout.propTypes = {
    children: PropTypes.node,
};
