import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/plans";
        return axiosClient.get(url, { params });
    },
    update: (payload) => {
        const url = `/api/plans/${payload.id}`;
        return axiosClient.put(url, payload);
    },
    delete: (id) => {
        const url = `/api/plans/${id}`;
        return axiosClient.delete(url);
    },
    deleteMany: (ids) => {
        const url = `/api/plans/delete-many`;
        return axiosClient.post(url, ids);
    },
    import_: (payload) => {
        const url = `/api/plans/import`;
        return axiosClient.post(url, payload);
    },
};
