import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { Select, Input, InputNumber, Form, Modal } from "antd";
const { Option } = Select;

const inputNode = (keyAction, options = [], onChange) => {
    switch (keyAction) {
        case 1:
        case 3:
        case 4:
        case 8:
        case 9:
            return (
                <Select
                    showSearch
                    style={{ width: 250 }}
                    placeholder="Chuyển tới nhóm...."
                    onChange={onChange}
                >
                    {options?.map((e, i) => (
                        <Option key={i} value={e.value}>
                            {e.label}
                        </Option>
                    ))}
                </Select>
            );
        case 2:
        case 6:
        case 10:
            return <Input style={{ width: 250 }} onChange={onChange} />;
        case 5:
        case 7:
            return (
                <InputNumber
                    style={{ width: 250 }}
                    min={0}
                    onChange={onChange}
                />
            );
        default:
            return <></>;
    }
};

function EditAllModal(props) {
    const { open, onSubmit, onCancel, isProcessing, keyAction, options } =
        props;

    const [valueUpdate, setValueUpdate] = useState("");

    const onChange = (val) => {
        if (val.target) {
            setValueUpdate(val.target.value);
        } else {
            setValueUpdate(val);
        }
    };

    const titles = {
        1: "Chuyển nhóm tất cả các device đã chọn",
        2: "Chuyển host tất cả các device đã chọn",
        3: "Chuyển state tất cả các device đã chọn",
        4: "Chuyển phase tất cả các device đã chọn",
        5: "Cài số user tối đa cho tất cả các device đã chọn",
        6: "Cài activity cho tất cả các device đã chọn",
        7: "Cài số thời gian một phiên cho tất cả cho các device đã chọn",
        8: "Cài bật tắt use static proxy cho tất cả các device đã chọn",
        9: "Cài bật tắt skip watch cho tất cả các device đã chọn",
        10: "Cài phiên bản bypass cho tất cả các device đã chọn",
    };
    const onOK = () => {
        onSubmit(valueUpdate, keyAction);
    };

    return (
        <Modal
            title={titles[keyAction]}
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            key={keyAction}
            width="1000px"
        >
            <Form>{inputNode(keyAction, options, onChange)}</Form>
        </Modal>
    );
}

export default EditAllModal;
