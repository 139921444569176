import { SafetyCertificateOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Row, Space, Typography } from "antd";
import * as authActions from "features/auth/slice";
import { useDispatch, useSelector } from "react-redux";

export default function Header() {
    const dispatch = useDispatch();

    const { userInfo } = useSelector((state) => state.auth);

    const KEY_ACTIONS = {
        SIGN_OUT: "sign-out",
    };

    const onLogout = () => {
        dispatch(authActions.logout());
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.SIGN_OUT:
                onLogout();
                break;
            default:
                break;
        }
    };

    const items = [
        {
            key: KEY_ACTIONS.SIGN_OUT,
            label: "Đăng xuất",
        },
    ];

    return (
        <>
            <Layout.Header className="!sticky top-0 w-full !bg-white flex justify-end z-10">
                {/* <Row justify="space-between" align="middle">
                    <Typography.Title level={2} style={{ marginTop: "10px" }}>
                        REPORT PROFILE
                    </Typography.Title> */}
                <Dropdown menu={{ items, onClick: onClick }}>
                    <Space>
                        <div className="flex items-center gap-x-2 cursor-pointer">
                            <span>
                                Xin chào,{" "}
                                <span className="font-bold">
                                    {userInfo?.username}
                                </span>
                            </span>
                            <Avatar
                                className="bg-green-500"
                                icon={
                                    userInfo?.group_user === "admin" ? (
                                        <SafetyCertificateOutlined />
                                    ) : (
                                        <UserOutlined />
                                    )
                                }
                            />
                        </div>
                    </Space>
                </Dropdown>
                {/* </Row> */}
            </Layout.Header>
        </>
    );
}
