import { SyncOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { randomRanger } from "utils/common";

const { Tag } = require("antd");

export const COLUMNS = [
    {
        title: "ID",
        dataIndex: "id",
        width: "120px",
        fixed: "left",
    },
    {
        title: "Email",
        dataIndex: "email",
        width: "260px",
        fixed: "left",
        render: (data) => <a>{data}</a>,
    },
    {
        title: "Group",
        dataIndex: "group",
    },
    {
        title: "Status",
        dataIndex: "status",
        align: "center",
    },
    {
        title: "State",
        dataIndex: "state",
        align: "center",
        render: (state) =>
            state === "DONE" ? (
                <Tag color="green">DONE</Tag>
            ) : (
                <Tag icon={<SyncOutlined spin />} color="gold">
                    RUNING
                </Tag>
            ),
    },
    {
        title: "Phase",
        dataIndex: "phase",
        align: "center",
    },
    {
        title: "Add day",
        dataIndex: "add_day",
        render: () =>
            dayjs()
                .subtract(randomRanger(0, 100000), "minutes")
                .format("DD/MM/YYYY HH:mm"),
    },
    {
        title: "GMail",
        children: [
            {
                title: "Total Time (h)",
                dataIndex: "gmail_total_time",
                align: "center",
                width: "120px",
            },
            {
                title: "Time /Day (h)",
                dataIndex: "gmail_time_day",
                align: "center",
                width: "120px",
            },
            {
                title: "Send Mail",
                dataIndex: "gmail_send_mail",
                align: "center",
                width: "120px",
            },
            {
                title: "Read mail",
                dataIndex: "gmail_read_mail",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access",
                dataIndex: "gmail_time_access",
                align: "center",
                width: "120px",
            },
        ],
    },
    {
        title: "GDrive",
        children: [
            {
                title: "Total Time (h)",
                dataIndex: "gdrive_total_time",
                align: "center",
                width: "120px",
            },
            {
                title: "Time /Day (h)",
                dataIndex: "gdrive_time_day",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access",
                dataIndex: "gdrive_time_access",
                align: "center",
                width: "120px",
            },
        ],
    },
    {
        title: "GMap",
        children: [
            {
                title: "Total Time (h)",
                dataIndex: "gmap_total_time",
                align: "center",
                width: "120px",
            },
            {
                title: "Time /Day (h)",
                dataIndex: "gmap_time_day",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access",
                dataIndex: "gmap_time_access",
                align: "center",
                width: "120px",
            },
        ],
    },
    {
        title: "GStore",
        children: [
            {
                title: "Total Time (h)",
                dataIndex: "gstore_total_time",
                align: "center",
                width: "120px",
            },
            {
                title: "Time /Day (h)",
                dataIndex: "gstore_time_day",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access",
                dataIndex: "gstore_time_access",
                align: "center",
                width: "120px",
            },
        ],
    },
    {
        title: "GNews",
        children: [
            {
                title: "Total Time (h)",
                dataIndex: "gnews_total_time",
                align: "center",
                width: "120px",
            },
            {
                title: "Time /Day (h)",
                dataIndex: "gnews_time_day",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access",
                dataIndex: "gnews_time_access",
                align: "center",
                width: "120px",
            },
        ],
    },
    {
        title: "GChrome",
        children: [
            {
                title: "Total Time (h)",
                dataIndex: "gchrome_total_time",
                align: "center",
                width: "120px",
            },
            {
                title: "Time /Day (h)",
                dataIndex: "gchrome_time_day",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access App",
                dataIndex: "gchrome_time_access",
                align: "center",
                width: "120px",
            },
            {
                title: "Search keywords",
                dataIndex: "gchrome_search_keywords",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access Web",
                dataIndex: "gchrome_time_access_web",
                align: "center",
                width: "120px",
            },
            {
                title: "Access Discovery",
                dataIndex: "gchrome_access_discovery",
                align: "center",
                width: "120px",
            },
            {
                title: "Access Search",
                dataIndex: "gchrome_access_search",
                align: "center",
                width: "120px",
            },
            {
                title: "Access Other",
                dataIndex: "gchrome_access_other",
                align: "center",
                width: "120px",
            },
        ],
    },
    {
        title: "Youtube",
        children: [
            {
                title: "Total Time (h)",
                dataIndex: "yt_total_time",
                align: "center",
                width: "120px",
            },
            {
                title: "Time /Day (h)",
                dataIndex: "yt_time_day",
                align: "center",
                width: "120px",
            },
            {
                title: "Time Access App",
                dataIndex: "yt_time_access",
                align: "center",
                width: "120px",
            },
            {
                title: "Access Search",
                dataIndex: "yt_access_search",
                align: "center",
                width: "120px",
            },
            {
                title: "Subscribe Channel",
                dataIndex: "yt_subscribe_channel",
                align: "center",
                width: "120px",
            },
            {
                title: "Watch Video",
                dataIndex: "yt_watch_video",
                align: "center",
                width: "130px",
            },
            {
                title: "Watch Video Search",
                dataIndex: "yt_watch_video_search",
                align: "center",
                width: "130px",
            },
            {
                title: "Watch Video Subscribe",
                dataIndex: "yt_watch_video_subscribe",
                align: "center",
                width: "130px",
            },
            {
                title: "Watch Video Discovery",
                dataIndex: "yt_watch_video_discovery",
                align: "center",
                width: "130px",
            },
            {
                title: "Watch Video Playlist",
                dataIndex: "yt_watch_video_playlist",
                align: "center",
                width: "130px",
            },
            {
                title: "Like",
                dataIndex: "yt_like",
                align: "center",
                width: "120px",
            },
            {
                title: "Comment",
                dataIndex: "yt_comment",
                align: "center",
                width: "120px",
            },
            {
                title: "Share",
                dataIndex: "yt_share",
                align: "center",
                width: "120px",
            },
            {
                title: "Save Playlist",
                dataIndex: "yt_save_playlist",
                align: "center",
                width: "120px",
            },
            {
                title: "Show Ads",
                dataIndex: "yt_show_ads",
                align: "center",
                width: "120px",
            },
            {
                title: "Watch Ads",
                dataIndex: "yt_watch_ads",
                align: "center",
                width: "120px",
            },
            {
                title: "Skip Ads",
                dataIndex: "yt_skip_ads",
                align: "center",
                width: "120px",
            },
            {
                title: "Click Ads",
                dataIndex: "yt_click_ads",
                align: "center",
                width: "120px",
            },
        ],
    },
];
