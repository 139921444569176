import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Spin, Tag, Upload } from "antd";
import * as csvtojson from "csvtojson";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../api";
import { randomRanger } from "utils/common";

function ConfigCommentsModal(props) {
    const { open, onCancel, isProcessing, group } = props;
    const [comments, setComments] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchComments = async () => {
        setIsLoading(true);
        try {
            const data = await api.getComments(group.id);
            setComments(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (open && group) {
            fetchComments();
        }
    }, [open]);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson({ noheader: true })
                .fromString(text)
                .then((jsonObject) => {
                    const data = jsonObject
                        .filter((e) => !!e.field1)
                        .map((e) => ({
                            import: true,
                            id: randomRanger(1, 99999) + Date.now(),
                            data: e.field1.trim(),
                        }));

                    setComments([...comments, ...data]);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            return false;
        },
    };

    const onRemoveComment = (ev, cmt) => {
        if (cmt.import) {
            setComments(comments.filter((c) => c.id !== cmt.id));
        } else {
            ev.preventDefault();
            Modal.confirm({
                title: `Xác nhận xóa bình luận`,
                content: `Bạn có chắc chắn muốn xóa bình luận này?`,
                centered: true,
                onOk: async () => {
                    try {
                        await api.deleteComment(group.id, cmt.id);
                        setComments(
                            comments.filter((c) => !c.id || c.id !== cmt.id)
                        );
                        toast.success("Xóa bình luận thành công!");
                    } catch (err) {
                        console.log(err);
                    }
                },
            });
        }
    };

    const onRemoveAllComment = () => {
        if (!comments.filter((c) => !c.import).length) {
            setComments([]);
        } else {
            Modal.confirm({
                title: `Xác nhận xóa tất cả bình luận`,
                content: `Bạn có chắc chắn muốn xóa tất cả bình luận của group này?`,
                centered: true,
                onOk: async () => {
                    try {
                        await api.deleteComments(group.id);
                        setComments([]);
                        toast.success("Xóa tất cả bình luận thành công!");
                    } catch (err) {
                        console.log(err);
                    }
                },
            });
        }
    };

    const onOK = async () => {
        setIsLoading(true);
        try {
            const importData = comments.filter((e) => e.import);
            if (importData.length) {
                await api.importComments(group.id, importData);
                fetchComments();
                toast.success("Cập nhật comment cho group thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal
            title="Cấu hình bình luận"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={600}
        >
            <Spin spinning={isLoading} tip="Loading...">
                <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`/files/temp/group_comments_template.csv`}>
                        Download temp
                    </a>
                    <br />
                    <Row justify="space-between" align="middle">
                        <h3>
                            Danh sách bình luận chưa sử dụng (
                            {comments.filter((e) => !e.import).length}):
                        </h3>
                        {comments.length ? (
                            <Button
                                size="small"
                                type="text"
                                danger
                                onClick={onRemoveAllComment}
                            >
                                Xóa tất cả
                            </Button>
                        ) : null}
                    </Row>
                    {comments.map((e) => (
                        <Tag
                            closeIcon
                            key={e.id}
                            onClose={(ev) => onRemoveComment(ev, e)}
                            color={e.import ? "processing" : "default"}
                            style={{
                                fontSize: 14,
                                lineHeight: "14px",
                                padding: "4px 8px 6px",
                                marginBottom: "8px",
                            }}
                        >
                            {e.data}
                        </Tag>
                    ))}
                </div>
            </Spin>
        </Modal>
    );
}

export default ConfigCommentsModal;
