import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/comments";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/comments/summary";
        return axiosClient.get(url, { params });
    },
    insert: (payload) => {
        const url = "/api/comments";
        return axiosClient.post(url, payload);
    },
    exportComment: () => {
        const url = `/api/comments/export`;
        return axiosClient.get(url);
    },
    getFilter: (params) => {
        const url = "/api/comments/filter";
        return axiosClient.get(url, { params });
    },
};
