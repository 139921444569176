import { UploadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { Button, Col, Row, Table, Upload, Modal } from "antd";
import { COLUMNS } from "../columns2";
import { randomRanger } from "utils/common";
import dayjs from "dayjs";
import React from 'react';

const { confirm } = Modal;

const fileHeaders = [
    "url",
    "url_type",
    "title",
    "group_id",
    "album_name",
    "channel_name",
    "usrname_channel",
    "url_channel",
    "key_search",
    "service",
    "max_watch_time",
    "min_watch_time",
    "video_time",
    "max_view",
    "suggest_videos",
    "suggest_percent",
    "search_percent",
    "ctr_search",
    "playlist_percent",
    "source_playlist",
    "ctr_playlist",
    "drop_percent",
    "view_full_percent",
    "direct_percent",
    "external_percent",
    "external_source",
    "channel_percent",
    "browser_percent",
    "notify_percent",
    "hour_view",
    "priority",
    "loop_count",
    "page_watch",
    "like_percent",
    "max_like",
    "max_dislike",
    "dislike_percent",
    "max_sub",
    "subscribe_percent",
    "max_comment",
    "cmt_percent",
    "cmt_user_percent",
    "before_view_per",
    "after_view_per",
    "duration_view_ads",
    "skip_ads_percent",
    "click_ads_percent",
    "view_page_ads",
    "start_time",
    "stop_time",
    "enable",
];

function ImportModal(props) {
    const { open, onSubmit, onCancel, isProcessing, data: _data, listUrl } = props;
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson({ headers: fileHeaders })
                .fromString(text)
                .then((jsonObject) => {
                    jsonObject = jsonObject.filter((row) =>
                        Object.values(row).some((value) => value !== "")
                    );
                    console.log(jsonObject);
                    for (const row of jsonObject) {
                        for (const key in row) {
                            if (!fileHeaders.includes(key)) delete row.key;
                            if (row[key] === "") row[key] = null;
                            if (
                                row[key] &&
                                ["start_time", "stop_time"].includes(key)
                            )
                                row[key] = dayjs(row[key], "M/D/YYYY H:mm")
                                    .second(0)
                                    .format("YYYY-MM-DD HH:mm:ss");
                        }
                    }
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const showConfirm = (duplicatedUrl) => {
        confirm({
          title: 'Cảnh báo',
          icon: <ExclamationCircleFilled />,
          content: (<div>
            Có <b className="text-danger">{duplicatedUrl.length}</b> url ĐÃ TỒN TẠI:
            {duplicatedUrl.map((text, index) => (
                <React.Fragment key={index}>
                    <br />
                    • <span className="text-danger"><b>{text}</b></span>
                </React.Fragment>
            ))}<br/>
            Tiếp tục sẽ ghi đè cấu hình playlist mới lên playlist đã tồn tại
          </div>),
          onOk() {
            onSubmit(dataImport);
          },
          onCancel() {
            console.log('Cancel');
          },
        });
      };

    const checking = () => {
        const duplicatedUrl = dataImport.filter(item => listUrl.some(obj => obj.url === item.url));
        if(duplicatedUrl.length > 0) {
            const arrayOfUrls = duplicatedUrl.map(obj => obj.url);
            showConfirm(arrayOfUrls);
        } else {
            onSubmit(dataImport);
        }
    }

    const columns = fileHeaders.map((e) => {
        const column = { ...COLUMNS.find((cl) => cl.cKey === e) };
        if (e === "group_id") {
            column.render = (data) => data.group_id;
            column.title = "Group ID";
        }
        return column;
    });

    return (
        <Modal
            title="Import Playlist"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={checking}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={1200}
        >
            <Row gutter={50}>
                <Col span="24">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`/files/temp/playlist_template.csv`}>
                        Download temp
                    </a>
                    <br />
                    <br />
                    {showPreviewTable && (
                        <Table
                            bordered
                            size="small"
                            sticky={{ offsetHeader: 65 }}
                            scroll={{ x: "100%" }}
                            rowKey={() => Date.now() + randomRanger(0, 10000)}
                            columns={columns}
                            dataSource={dataImport}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default ImportModal;
