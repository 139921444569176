import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/hosts";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/hosts/summary";
        return axiosClient.get(url, { params });
    },
    updateRunning: (id, run) => {
        const url = `/api/hosts/${id}`;
        return axiosClient.patch(url, { run });
    },
    startRunningMany: (ids) => {
        const url = `/api/hosts/start-many`;
        return axiosClient.post(url, ids);
    },
    stopRunningMany: (ids) => {
        const url = `/api/hosts/stop-many`;
        return axiosClient.post(url, ids);
    },
};
