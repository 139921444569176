import { formItemLayoutHorizontal } from "utils/constants";

const { Form, Modal, Input, Tabs } = require("antd");

const _formItemLayoutHorizontal = {
    ...formItemLayoutHorizontal,
    size: "small",
    labelAlign: "left",
    labelCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 12 },
    },
};

function ProjectDetailModal(props) {
    const [form] = Form.useForm();
    const { open, isProcessing, onSubmit, onCancel, project } = props;
    const initialValues = project
        ? {
              ...project,
          }
        : {
              name: "",
              description: "",
              mx_time: 0,
              mx_like: 0,
              mx_dislike: 0,
              mx_sub: 0,
              mx_comment: 0,
              mx_share: 0,
              mx_mail_send: 0,
              mx_mail_read: 0,
              mx_click_ads: 0,
              mx_time_yt: 0,
              mx_time_gm: 0,
              mx_time_chrome: 0,
              mx_time_drive: 0,
              mx_time_store: 0,
              mx_time_news: 0,
          };

    const onOK = () => {
        form.validateFields()
            .then((values) => onSubmit({ ...initialValues, ...values }))
            .catch((err) => {
                console.error(err);
            });
    };

    const tabItems = [
        {
            key: "info",
            label: "Thông tin",
            children: (
                <Form
                    {...formItemLayoutHorizontal}
                    form={form}
                    initialValues={initialValues}
                >
                    <Form.Item
                        {...formItemLayoutHorizontal}
                        name="name"
                        label="Tên"
                        rules={[
                            {
                                required: true,
                                message: "Trường này là bắt buộc",
                            },
                        ]}
                    >
                        <Input placeholder="Tên project" />
                    </Form.Item>
                    <Form.Item
                        {...formItemLayoutHorizontal}
                        name="description"
                        label="Mô tả"
                    >
                        <Input.TextArea placeholder="Mô tả" />
                    </Form.Item>
                </Form>
            ),
        },
        {
            key: "config",
            label: "Cấu hình",
            children: (
                <Form
                    {..._formItemLayoutHorizontal}
                    form={form}
                    initialValues={initialValues}
                >
                    <Form.Item
                        name={"mx_time"}
                        label="Max Time hoạt động/ngày (h)"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_time_yt"}
                        label="Max Time Youtube/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_time_gm"}
                        label="Max Time GMail/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_time_chrome"}
                        label="Max Time GChrome/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_time_drive"}
                        label="Max Time GDrive/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_time_store"}
                        label="Max Time GStore/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_time_news"}
                        label="Max Time GNews/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_like"}
                        label="Max Like/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_dislike"}
                        label="Max Dislike/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_sub"}
                        label="Max Subscribe/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_comment"}
                        label="Max Comment/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_share"}
                        label="Max Share/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_mail_read"}
                        label="Max Mail đã đọc/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_mail_send"}
                        label="Max Mail đã gửi/ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                    <Form.Item
                        name={"mx_click_ads"}
                        label="Max Click Ads /ngày"
                        style={{ marginBottom: "12px" }}
                    >
                        <Input
                            type="number"
                            min={0}
                            style={{ padding: "3px 10px" }}
                        />
                    </Form.Item>
                </Form>
            ),
        },
    ];
    return (
        <Modal
            title="PROJECT"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="460px"
        >
            <Tabs defaultActiveKey="info" items={tabItems}></Tabs>
        </Modal>
    );
}

export default ProjectDetailModal;
