import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    plans: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "reportPlan",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setPlans: (state, action) => {
            state.plans = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const { setIsLoading, setIsProcessing, setIsCompleted, setPlans, setPagination } = slice.actions;

export const fetchPlans = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getReport(params);
            dispatch(setPlans(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const exportReport = (params) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.exportReport(params);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                exp(data);
                toast.success("Xuất thành công report plan");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchPlans({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

const exp = (data) => {
    try {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `report_plan.csv`;
        link.click();
    } catch (error) {
        console.log(error);
    }
};

export default slice.reducer;
