import { ExclamationCircleFilled } from "@ant-design/icons";
import { useState } from "react";
//import { toast } from "react-toastify";
import { Col, Row, Select, Modal } from "antd";

const { confirm } = Modal;

function MoveGroupModal(props) {
    const { open, onSubmit, onCancel, isProcessing, options } = props;
    const [selectedOption, setSelectedOption] = useState("");

    const handleUChange = (value) => {
        setSelectedOption(value);
    };

    const handleSearch = (input, option) => {
        return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };

    const handleSort = (optionA, optionB) => {
        return (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase());
    };

    const showConfirm = () => {
        console.log(selectedOption);
        confirm({
            title: "Cánh báo!!",
            icon: <ExclamationCircleFilled />,
            content:
                "Bạn chắc chắn muốn chuyển nhóm tất cả profile email đã chọn?",
            onOk() {
                onSubmit(selectedOption);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    return (
        <Modal
            title="Chuyển nhóm tất cả profile email đã chọn"
            okText="Chuyển"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={showConfirm}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="24">
                    <Select
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Chuyển tới nhóm...."
                        onChange={handleUChange}
                        optionFilterProp="children"
                        filterOption={handleSearch}
                        filterSort={handleSort}
                        value={selectedOption}
                        options={options}
                    />
                </Col>
            </Row>
        </Modal>
    );
}

export default MoveGroupModal;
