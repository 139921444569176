import {
    Button,
    Input,
    Modal,
    Row,
    Segmented,
    Select,
    Space,
    Table,
    Tag,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    BarsOutlined,
    ExperimentOutlined,
    PlusOutlined,
    RocketOutlined,
} from "@ant-design/icons";
import Actions from "./components/Action";
import GroupUserDetailModal from "./components/DetailModal";
import * as groupUserAction from "./slice";
import projectApi from "../project/api";
import { paginationParams } from "utils/constants";
import { toast } from "react-toastify";
import ConfigHobbiesModal from "./components/ConfigHobbiesModal";
import ConfigAppModal from "./components/ConfigAppModal";
import ConfigKeywordsModal from "./components/ConfigKeywordsModal";
import ConfigCommentsModal from "./components/ConfigCommentsModal";
import ConfigChannelsModal from "./components/ConfigChannelsModal";

const { Paragraph } = Typography;

function GroupUser() {
    const dispatch = useDispatch();
    const { groupUsers, listGroup, pagination, isLoading, isLoadingListGroup, isProcessing, isCompleted } =
        useSelector((state) => state.groupUser);

    const [searchPhase, setSearchPhase] = useState("");
    const [searchProject, setSearchProject] = useState("");
    const [searchName, setSearchName] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedGroupUser, setSelectedGroupUser] = useState(null);
    const [openDetailModal, setOpenDetailModal] = useState(false);
    const [openConfigKeywordsModal, setOpenConfigKeywordsModal] =
        useState(false);
    const [openConfigCommentsModal, setOpenConfigCommentsModal] =
        useState(false);
    const [openConfigChannelsModal, setOpenConfigChannelsModal] =
        useState(false);
    const [openConfigHobbiesModal, setOpenConfigHobbiesModal] = useState(false);
    const [openConfigAppModal, setOpenConfigAppModal] = useState(false);
    const [projects, setProjects] = useState([]);
    const [keywords, setKeywords] = useState("");
    const [hobbies, setHobbies] = useState(`{}`);

    useEffect(() => {
        dispatch(groupUserAction.fetchGroupUsers(pagination));
        dispatch(groupUserAction.fetchListGroup());
        const fetchProjects = async () => {
            try {
                const projects = await projectApi.getList();
                if (projects.error) {
                    toast.error(projects.error);
                } else {
                    setProjects(projects.data);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };

        fetchProjects();
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenDetailModal(false);
            setOpenConfigKeywordsModal(false);
            setOpenConfigAppModal(false);
            setOpenConfigHobbiesModal(false);
            setSelectedGroupUser(null);
        }
    }, [isCompleted]);

    useEffect(() => {
        if (!openDetailModal) {
            setHobbies(`{}`);
            setKeywords("");
        }
    }, [openDetailModal]);

    const onOpenDetailModal = (groupUser = null) => {
        setSelectedGroupUser(groupUser);
        setOpenDetailModal(true);
        if (groupUser?.hobbies) setHobbies(groupUser.hobbies);
        if (groupUser?.keywords) setKeywords(groupUser.keywords);
    };

    const onCloseDetailModal = () => {
        setOpenDetailModal(false);
        setSelectedGroupUser(null);
        dispatch(groupUserAction.setIsProcessing(false));
    };

    const onOpenConfigKeywordsModal = (groupUser = null) => {
        setSelectedGroupUser(groupUser);
        setOpenConfigKeywordsModal(true);
    };

    const onCloseConfigKeywordsModal = () => {
        setOpenConfigKeywordsModal(false);
        if (!openDetailModal) setSelectedGroupUser(null);
        dispatch(groupUserAction.setIsProcessing(false));
    };

    const onOpenConfigCommentsModal = (groupUser = null) => {
        setSelectedGroupUser(groupUser);
        setOpenConfigCommentsModal(true);
    };

    const onCloseConfigCommentsModal = () => {
        setOpenConfigCommentsModal(false);
        if (!openDetailModal) {
            dispatch(groupUserAction.refreshGroupUser(selectedGroupUser));
            setSelectedGroupUser(null);
        }
        dispatch(groupUserAction.setIsProcessing(false));
    };

    const onOpenConfigChannelsModal = (groupUser = null) => {
        setSelectedGroupUser(groupUser);
        setOpenConfigChannelsModal(true);
    };

    const onCloseConfigChannelsModal = () => {
        setOpenConfigChannelsModal(false);
        if (!openDetailModal) {
            dispatch(groupUserAction.refreshGroupUser(selectedGroupUser));
            setSelectedGroupUser(null);
        }
        dispatch(groupUserAction.setIsProcessing(false));
    };

    const onOpenConfigHobbiesModal = (groupUser = null) => {
        setSelectedGroupUser(groupUser);
        setOpenConfigHobbiesModal(true);
    };

    const onCloseConfigHobbiesModal = () => {
        setOpenConfigHobbiesModal(false);
        if (!openDetailModal) setSelectedGroupUser(null);
        dispatch(groupUserAction.setIsProcessing(false));
    };

    const onOpenConfigAppModal = (groupUser = null) => {
        setSelectedGroupUser(groupUser);
        setOpenConfigAppModal(true);
    };

    const onCloseConfigAppModal = () => {
        setOpenConfigAppModal(false);
        if (!openDetailModal) setSelectedGroupUser(null);
        dispatch(groupUserAction.setIsProcessing(false));
    };

    const onChangeNameSearch = (e) => {
        setSearchName(e.target.value);
    };

    const onSelectPhaseSearch = (phase) => {
        setSearchPhase(phase);
        dispatch(
            groupUserAction.fetchGroupUsers({
                ...pagination,
                phase: phase,
                project: searchProject,
                name: searchName,
                current: 1,
            })
        );
    };

    const onSelectProjectSearch = (project) => {
        setSearchProject(project);
        dispatch(
            groupUserAction.fetchGroupUsers({
                ...pagination,
                project: project,
                phase: searchPhase,
                name: searchName,
                current: 1,
            })
        );
    };

    const onSearch = (searchVal) => {
        dispatch(
            groupUserAction.fetchGroupUsers({
                ...pagination,
                name: searchVal,
                phase: searchPhase,
                project: searchProject,
                current: 1,
            })
        );
    };

    const onSaveConfigKeywords = (keywords) => {
        if (openDetailModal) {
            setKeywords(keywords);
            setOpenConfigKeywordsModal(false);
        } else if (selectedGroupUser)
            dispatch(
                groupUserAction.updateGroupUser({
                    id: selectedGroupUser.id,
                    keywords,
                })
            );
    };

    const onSaveConfigComments = (config) => {
        // if (openDetailModal) {
        //     setComments(keywords);
        //     setOpenConfigCommentsModal(false);
        // } else if (selectedGroupUser)
        //     dispatch(
        //         groupUserAction.refreshGroupUser({
        //             id: selectedGroupUser.id,
        //             keywords,
        //         })
        //     );
        console.log(config);
    };

    const onSaveConfigHobbies = (hobbies) => {
        if (openDetailModal) {
            setHobbies(hobbies);
            setOpenConfigHobbiesModal(false);
        } else if (selectedGroupUser){
            dispatch(
                groupUserAction.updateGroupUser({
                    id: selectedGroupUser.id,
                    hobbies,
                })
            );
        }
           
    };

    const onSaveConfigApp = (config) => {
        console.log(config);
    };

    const onSubmit = (groupUser) => {
        if (!groupUser.id) {
            dispatch(groupUserAction.createGroupUser(groupUser));
        } else {
            dispatch(groupUserAction.updateGroupUser(groupUser));
        }
    };

    const onDelete = (groupUser) => {
        Modal.confirm({
            title: `Xác nhận xóa group user`,
            content: `Bạn có chắc chắn muốn xóa group user này?`,
            onOk: () => dispatch(groupUserAction.deleteGroupUser(groupUser.id)),
            centered: true,
        });
    };

    const onDeleteMany = () => {
        const selectedGroupUsers = selectedRows.map((e) => e.id);
        Modal.confirm({
            title: `Xác nhận xóa group user`,
            content: `Bạn có chắc chắn muốn xóa ${selectedGroupUsers.length} group user đã chọn?`,
            onOk: () =>
                dispatch(groupUserAction.deleteGroupUsers(selectedGroupUsers)),
            centered: true,
        });
    };

    const onTableChange = async (pagination) => {
        dispatch(
            groupUserAction.fetchGroupUsers({
                ...pagination,
                name: searchName,
            })
        );
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "100px",
            fixed: "left",
        },
        {
            title: "Tên",
            // dataIndex: "name",
            width: "180px",
            fixed: "left",
            render: (record) => <a>{record.name}</a>,
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            width: "180px",
            render: (description) => (
                <Paragraph
                    ellipsis={{ rows: 2, tooltip: description }}
                    style={{ fontSize: "16px" }}
                >
                    {description}
                </Paragraph>
            ),
        },
        {
            title: "Project",
            dataIndex: "project_name",
            width: "140px",
        },
        {
            title: "Active user",
            dataIndex: "active_user_count",
            width: "120px",
            align: "center",
            render: (data) => data ? data : 0
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            width: "100px",
            align: "center",
        },
        {
            title: "Phase",
            dataIndex: "phase",
            width: "120px",
            align: "center",
            render: (phase) =>
                phase === "feed" ? (
                    <Tag color="processing">FEED</Tag>
                ) : (
                    <Tag color="warning">SEEDING</Tag>
                ),
        },
        {
            title: "ĐK chuyển nhóm",
            width: "180px",
            render: (record) => (
                <>
                    Số ngày nuôi : {record.num_days}
                    <br />
                    Số giờ nuôi: {record.num_hours}
                </>
            ),
        },
        {
            title: "Tài nguyên còn lại",
            width: "180px",
            render: (record) => (
                <>
                    Số kênh: {record.channel_count}
                    <br />
                    Số từ khóa : {record.keyword_count}
                    <br />
                    Số bình luận: {record.comment_count || 0}
                </>
            ),
        },
        {
            title: "Nhóm chuyển",
            dataIndex: "next_group_name",
            width: "150px",
        },
        {
            title: "Thao tác",
            align: "center",
            width: "150px",
            fixed: "right",
            render: (record) => (
                <Actions
                    record={record}
                    onEdit={onOpenDetailModal}
                    onDelete={onDelete}
                    onConfigKeywords={onOpenConfigKeywordsModal}
                    onConfigComments={onOpenConfigCommentsModal}
                    onConfigChannels={onOpenConfigChannelsModal}
                    onConfigHobbies={onOpenConfigHobbiesModal}
                    onConfigApp={onOpenConfigAppModal}
                />
            ),
        },
    ];

    const rowSelection = {
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    QUẢN LÝ GROUP USER
                </Typography.Title>
                <Space size="middle">
                    <Segmented
                        options={[
                            {
                                label: "Tất cả",
                                value: "",
                                icon: <BarsOutlined />,
                            },
                            {
                                label: "Feed",
                                value: "feed",
                                icon: <ExperimentOutlined />,
                            },
                            {
                                label: "Seeding",
                                value: "seeding",
                                icon: <RocketOutlined />,
                            },
                        ]}
                        size="large"
                        value={searchPhase}
                        onChange={onSelectPhaseSearch}
                    />
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Tìm theo project"
                        onChange={onSelectProjectSearch}
                    >
                        <Select.Option key="all" value="">
                            Tất cả
                        </Select.Option>
                        {projects.map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Input.Search
                        placeholder="Tìm kiếm tên group"
                        onChange={onChangeNameSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => onOpenDetailModal()}
                    >
                        Thêm mới
                    </Button>
                </Space>
            </Row>
            <br />
            {selectedRows.length ? (
                <Space size="middle" className="mb-4">
                    <Button type="primary" danger onClick={onDeleteMany}>
                        Xóa tất cả
                    </Button>
                </Space>
            ) : null}
            <Table
                bordered
                loading={isLoading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={groupUsers}
                rowKey={(record) => record.id}
                pagination={{
                    ...paginationParams,
                    ...pagination,
                }}
                scroll={{ x: 1500, y:'calc(100vh - 415px)'}}
                onChange={onTableChange}
            />
            {openDetailModal && (
                <GroupUserDetailModal
                    open={openDetailModal}
                    isProcessing={isProcessing}
                    onSubmit={onSubmit}
                    onCancel={onCloseDetailModal}
                    onConfigKeywords={onOpenConfigKeywordsModal}
                    onConfigHobbies={onOpenConfigHobbiesModal}
                    onConfigApp={onOpenConfigAppModal}
                    groupUser={selectedGroupUser}
                    groupUsers={listGroup}
                    projects={projects}
                    keywords={keywords}
                    hobbies={hobbies}
                />
            )}
            {openConfigKeywordsModal && (
                <ConfigKeywordsModal
                    open={openConfigKeywordsModal}
                    isProcessing={isProcessing}
                    onSubmit={onSaveConfigKeywords}
                    onCancel={onCloseConfigKeywordsModal}
                    group={selectedGroupUser}
                    hobbies={hobbies}
                />
            )}
            {openConfigCommentsModal && (
                <ConfigCommentsModal
                    open={openConfigCommentsModal}
                    isProcessing={isProcessing}
                    onCancel={onCloseConfigCommentsModal}
                    group={selectedGroupUser}
                    hobbies={hobbies}
                />
            )}
            {openConfigChannelsModal && (
                <ConfigChannelsModal
                    open={openConfigChannelsModal}
                    isProcessing={isProcessing}
                    onCancel={onCloseConfigChannelsModal}
                    group={selectedGroupUser}
                    hobbies={hobbies}
                />
            )}
            {openConfigHobbiesModal && (
                <ConfigHobbiesModal
                    open={openConfigHobbiesModal}
                    isProcessing={isProcessing}
                    onSubmit={onSaveConfigHobbies}
                    onCancel={onCloseConfigHobbiesModal}
                    group={selectedGroupUser}
                    hobbies={hobbies}
                />
            )}
            {openConfigAppModal && (
                <ConfigAppModal
                    open={openConfigAppModal}
                    isProcessing={isProcessing}
                    onSubmit={onSaveConfigApp}
                    onCancel={onCloseConfigAppModal}
                    group={selectedGroupUser}
                />
            )}
        </>
    );
}

export default GroupUser;
