import {
    Button,
    Card,
    Col,
    Input,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Typography,
} from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    HddOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    UploadOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import { toast } from "react-toastify";
import * as commentAction from "./slice";
import ImportModal from "./components/ImportModal";

function Comment() {
    const dispatch = useDispatch();
    const {
        comments,
        summary,
        filter,
        pagination,
        isProcessing,
        isLoading,
        isLoadingSummary,
    } = useSelector((state) => state.comment);

    const [searchTargetId, setSearchTargetId] = useState("");
    const [searchUsed, setSearchUsed] = useState("");
    const [openImportModal, setOpenImportModal] = useState(false);
    const [dataImport, setDataImport] = useState([]);

    useEffect(() => {
        dispatch(commentAction.fetchComments(pagination));
        dispatch(commentAction.fetchCommentSummary());
        dispatch(commentAction.fetchFilter());
    }, []);

    const onSelectTargetId = (target_id) => {
        setSearchTargetId(target_id);
        dispatch(
            commentAction.fetchComments({
                ...pagination,
                target_id: target_id,
                used: searchUsed,
                current: 1,
            })
        );
    };

    const onSelectUsed = (used) => {
        setSearchUsed(used);
        dispatch(
            commentAction.fetchComments({
                ...pagination,
                target_id: searchTargetId,
                used: used,
                current: 1,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            commentAction.fetchComments({
                ...pagination,
                target_id: searchTargetId,
                used: searchUsed,
            })
        );
    };

    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const onCloseImportModal = () => {
        setOpenImportModal(false);
        dispatch(commentAction.setIsProcessing(false));
    };

    const onImport = (dataImport) => {
        setDataImport(dataImport);
        dispatch(commentAction.insertComment(dataImport));
        setOpenImportModal(false);
        // dispatch(
        //     commentAction.fetchComments({
        //         ...pagination,
        //         current: 1,
        //     })
        // );
    };

    const onExport = () => {
        dispatch(commentAction.exportComment());
    };

    const columns = [
        {
            title: "ID",
            width: "30px",
            fixed: "left",
            render: (record) => {
                return record.key;
            },
        },
        {
            title: "Target",
            fixed: "left",
            width: "250px",
            render: (record) => {
                return record.is_play_list == 0 ? `Group ID: ${record.target_id} - ${record.gr_name}` : `Playlist ID: ${record.target_id} - ${record.pl_title}`;
            },
        },
        {
            title: "Is Playlist",
            dataIndex: "is_play_list",
            width: "60px",
        },
        {
            title: "Tổng số",
            dataIndex: "total",
            width: "60px",
        },
        {
            title: "New",
            dataIndex: "_NEW",
            width: "60px",
        },
        {
            title: "Used",
            dataIndex: "_USED",
            width: "60px",
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    QUẢN LÝ COMMENT
                </Typography.Title>
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => onOpenImportModal()}
                    >
                        Import
                    </Button>
                    <Button
                        className="bg-green-500"
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => onExport()}
                    >
                        Export
                    </Button>
                </Space>
            </Row>
            <br />
            <Row gutter={12} justify="start">
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Tổng số comment"
                            value={summary.total}
                            prefix={<HddOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Số comment đã sử dụng"
                            value={summary.used}
                            prefix={<PlayCircleOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Số comment chưa sử dụng"
                            value={summary.neww}
                            prefix={<PauseCircleOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                <>&nbsp;</>
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 120 }}
                        placeholder="Trạng thái"
                        onChange={onSelectUsed}
                    >
                        <Select.Option key="NEW" value="NEW">
                            NEW
                        </Select.Option>
                        <Select.Option key="USED" value="USED">
                            USED
                        </Select.Option>
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 120 }}
                        placeholder="Target Id"
                        onChange={onSelectTargetId}
                    >
                        {filter.map((e) => (
                            <Select.Option key={e.target_id} value={e.target_id}>
                                {e.target_id}
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            <Table
                bordered
                loading={isLoading}
                dataSource={comments.map((record, index) => ({...record, key: index + 1}))}
                columns={columns}
                rowKey={(record) => record.key}
                pagination={pagination}
                scroll={{ x: "100%", y: 'calc(100vh - 600px)' }}
                onChange={onTableChange}
            />
            {openImportModal && (
                <ImportModal
                    open={openImportModal}
                    isProcessing={isProcessing}
                    onSubmit={onImport}
                    onCancel={onCloseImportModal}
                    data={dataImport}
                />
            )}
        </>
    );
}

export default Comment;
