import { CopyOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Row } from "antd";
import { useEffect, useState } from "react";
import { KEY_ACTIONS_DEVICES_MANAGER as KEY_ACTIONS } from "utils/constants";
import EditAllModal from "./EditAllModal";
import { filter } from "utils/constants";
import * as deviceAction from "../slice";

export default function ActionAll(props) {
    const { onSubmit, options } = props;
    const [openModal, setOpenModal] = useState(false);
    const [actionKey, setActionKey] = useState(0);
    const [listOption, setListOption] = useState(options);

    const optionState = filter.device.state;
    const optionPhase = filter.device.phase;
    const optionOnOf = [
        {
            value: 1,
            label: "On",
        },
        {
            value: 2,
            label: "Off",
        },
    ];

    useEffect(() => {
        if (!openModal && actionKey != 0) setOpenModal(true);
    }, [actionKey]);

    const onCloseModal = () => {
        setOpenModal(false);
    };
    const onClick = (key) => {
        switch (key) {
            case KEY_ACTIONS.MOVE_GROUP_ALL:
                setListOption(options);
                break;
            case KEY_ACTIONS.SET_HOST_ALL:
                break;
            case KEY_ACTIONS.SET_STATE_ALL:
                setListOption(optionState);
                break;
            case KEY_ACTIONS.SET_PHASE_ALL:
                setListOption(optionPhase);
                break;
            case KEY_ACTIONS.SET_MAX_USER_ALL:
                break;
            case KEY_ACTIONS.SET_ACTIVITY_ALL:
                break;
            case KEY_ACTIONS.SET_TIME_PER_SESSION_ALL:
                break;
            case KEY_ACTIONS.SET_USE_STATIC_PROXY_ALL:
                setListOption(optionOnOf);
                break;
            case KEY_ACTIONS.SET_SKIP_WATCH_ALL:
                setListOption(optionOnOf);
                break;
            case KEY_ACTIONS.SET_VERSION_BYPASSS_ALL:
                break;
            default:
                break;
        }
        setActionKey(key);
    };

    const onUpdate = (value, keyAction) => {
        onSubmit(value, keyAction);
        onCloseModal();
    };

    const actions = [
        {
            key: KEY_ACTIONS.MOVE_GROUP_ALL,
            label: "Chuyển nhóm",
            icon: <EditOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_HOST_ALL,
            label: "Chuyển Host",
            icon: <CopyOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_STATE_ALL,
            label: "Chuyển State",
            icon: <DeleteOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_PHASE_ALL,
            label: "Chuyển Phase",
            icon: <DeleteOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_MAX_USER_ALL,
            label: "Cài Max User",
            icon: <DeleteOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_ACTIVITY_ALL,
            label: "Cài Activity",
            icon: <DeleteOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_TIME_PER_SESSION_ALL,
            label: "Cài Thời gian 1 phiên",
            icon: <DeleteOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_USE_STATIC_PROXY_ALL,
            label: "Cài sử dụng static proxy",
            icon: <DeleteOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_SKIP_WATCH_ALL,
            label: "Cài bỏ qua xem",
            icon: <DeleteOutlined />,
        },
        {
            key: KEY_ACTIONS.SET_VERSION_BYPASSS_ALL,
            label: "Cài phiên bản bypass",
            icon: <DeleteOutlined />,
        },
    ];

    // openModal && (
    //     <EditAllModal
    //         open={openModal}
    //         isProcessing={isProcessing}
    //         //onSubmit={onImport}
    //         onCancel={onCloseModal}
    //         //key={key}
    //         options={options}
    //     />
    // );

    return (
        <>
            <Row
                // justify="start"
                // align="middle"
                // wrap={true}
                gutter={[20, 20]}
            >
                {/* <Space size="middle"> */}
                {actions.map((e) => {
                    return (
                        <Button
                            style={{ marginLeft: "10px" }}
                            type="primary"
                            icon={e.icon}
                            key={e.key}
                            onClick={() => onClick(e.key)}
                        >
                            {e.label}
                        </Button>
                    );
                })}

                {/* </Space> */}
            </Row>

            {openModal && (
                <EditAllModal
                    open={openModal}
                    //isProcessing={isProcessing}
                    onSubmit={onUpdate}
                    onCancel={onCloseModal}
                    keyAction={actionKey}
                    options={listOption}
                />
            )}
        </>
    );
}
