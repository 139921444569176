import { Checkbox, Col, Divider, Modal, Row } from "antd";
import { useState } from "react";
import { COLUMNS } from "../columns2";

const CheckboxGroup = Checkbox.Group;

const columns = COLUMNS.map((param) => param.title);

function ExportModal(props) {
    const { open, onSubmit, onCancel, isProcessing } = props;
    const [checkedList, setCheckedList] = useState([
        "URL",
        "URL Type",
        "Title",
        "Group",
        "Album",
        "Tên Kênh",
        "Key Search",
        "Total times",
        "View hiện tại",
        "Enable",
    ]);
    const checkAll = columns.length === checkedList.length;
    const indeterminate =
        checkedList.length > 0 && checkedList.length < columns.length;
    const onChange = (list) => {
        setCheckedList(list);
    };
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? columns : []);
    };
    const onOk = () => {
        const fields = checkedList.map((field) => ({
            label: field,
            value: COLUMNS.find((e) => e.title === field).cKey,
        }));
        onSubmit(fields);
    };

    return (
        <Modal
            okText="Export"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOk}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={1000}
        >
            <Divider>Chọn các trường cần export</Divider>
            <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
                style={{ marginBottom: "10px" }}
            >
                <b>Chọn tất cả</b>
            </Checkbox>
            <br />
            <CheckboxGroup value={checkedList} onChange={onChange}>
                <Row gutter={[8, 5]}>
                    {columns.map((option, index) => (
                        <Col key={index} span={8}>
                            <Checkbox value={option}>{option}</Checkbox>
                        </Col>
                    ))}
                </Row>
            </CheckboxGroup>
        </Modal>
    );
}

export default ExportModal;
