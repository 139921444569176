import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/channels";
        return axiosClient.get(url, { params });
    },
    insert: (payload) => {
        const url = "/api/channels";
        return axiosClient.post(url, payload);
    },
    getListGroup: () => {
        const url = "/api/channels/list-group";
        return axiosClient.get(url);
    },
    exportChannel: () => {
        const url = `/api/channels/export`;
        return axiosClient.get(url);
    },
    deleteChannel: (payload) => {
        const url = `/api/channels/delete`;
        return axiosClient.post(url, payload);
    },
};
