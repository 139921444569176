import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import {
    Button,
    Col,
    Row,
    Table,
    Upload,
    Modal,
    Select,
    InputNumber,
} from "antd";
import { randomRanger } from "utils/common";

function ExportModal(props) {
    const {
        open,
        onSubmit,
        onCancel,
        isProcessing,
        data: _data,
    } = props;
    
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);
    const [status, setStatus] = useState(null);
    const [limit, setLimit] = useState(10);

    const handleChangeStatus = (value) => {
        setStatus(value);
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
    };

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const onOK = () => {
        const ids = dataImport.map((obj) => obj.id);
        onSubmit(status, limit, ids);
    };

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            fixed: "left",
            width: "80px",
        },
        {
            title: "Url",
            dataIndex: "url",
            fixed: "left",
            width: "200px",
        },
        {
            title: "Group ID",
            dataIndex: "group_id",
            width: "100px",
        },
    ];

    return (
        <Modal
            title="Xuất Report Playlist"
            okText="Xuất"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="7">
                    <span className="text-[21px]">Trạng thái</span>
                    <Select
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Xuất theo trạng thái...."
                        onChange={handleChangeStatus}
                        defaultValue={status}
                    >
                        <Select.Option key={null} value={null}>
                            Tất cả
                        </Select.Option>
                        <Select.Option key='DONE' value='DONE'>
                            Done
                        </Select.Option>
                        <Select.Option key='NEW' value='NEW'>
                            New
                        </Select.Option>
                        <Select.Option key='RUNING' value='RUNING'>
                            Runing
                        </Select.Option>
                    </Select>
                </Col>
                <Col span="7">
                    <span className="text-[21px]">Giới hạn</span>
                    <InputNumber
                        min={1}
                        max={10000}
                        onChange={handleChangeLimit}
                        style={{ width: 250 }}
                        placeholder="Nhập số bản ghi muốn xuất"
                        defaultValue={limit}
                    ></InputNumber>
                </Col>
                <Col span="6">
                    <span className="text-[21px]">Xuất theo file</span>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                </Col>
                
            </Row>
            <br />
            <Row guiter={50}>
                <Col span="24">
                    {showPreviewTable && (
                        <Table
                            bordered
                            columns={columns}
                            dataSource={dataImport}
                            scroll={{ x: "100%" }}
                            rowKey={() => Date.now() + randomRanger(0, 10000)}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default ExportModal;
