import { UploadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { Button, Col, Row, Table, Upload, Modal, Select } from "antd";
import { randomRanger } from "utils/common";

const { confirm } = Modal;

function ImportModal(props) {
    const {
        open,
        onSubmit,
        onCancel,
        isProcessing,
        data: _data,
        options,
    } = props;
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);
    const [selectedOption, setSelectedOption] = useState("ONLY IMPORT");

    const handleUChange = (value) => {
        setSelectedOption(value);
    };

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const acceptedColumns = ['id','email', 'password', 'recover_mail', 'recover_phone', 'secret2fa', 'username', 'birthday', 'service', 'platform', 'priority', 'status', 'group_id'];
            console.log(text);
            csvtojson({headers: acceptedColumns})
                .fromString(text)
                .then((jsonObject) => {
                    jsonObject = jsonObject.filter((row) =>
                        Object.values(row).some((value) => value !== "")
                    );
                    console.log(jsonObject);
                    for (const row of jsonObject) {
                        for (const key in row) {
                            if (!acceptedColumns.includes(key)) delete row[key];
                            if (row[key] === "") delete row[key];
                        }
                    }
                    console.log(jsonObject);
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const showConfirm = (ids) => {
        confirm({
            title: "Cánh báo!!",
            icon: <ExclamationCircleFilled />,
            content:
                "Bạn chắc chắn muốn chuyển nhóm tất cả profile email đã chọn?",
            onOk() {
                onSubmit(ids, selectedOption);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const onOK = () => {
        if (selectedOption == "ONLY IMPORT") {
            onSubmit(dataImport);
        } else {
            const ids = dataImport.map(obj => obj.id);
            showConfirm(ids);
        }
    };

    const filterOption = (input, option) => {
        return option.children.includes(input)
    }

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            fixed: "left",
            width: "100px",
        },
        {
            title: "Email",
            dataIndex: "email",
            fixed: "left",
            width: "200px",
        },
        {
            title: "Password",
            dataIndex: "password",
            fixed: "left",
            width: "150px",
        },
        {
            title: "Service",
            dataIndex: "service",
            width: "100px",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Group ID",
            dataIndex: "group_id",
            width: "100px",
        },
        {
            title: "Priority",
            dataIndex: "priority",
            width: "100px",
        },
    ];

    return (
        <Modal
            title="Nhập Danh Sách hoặc chuyển nhóm các profiles"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="24">
                    <Select
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Chuyển tới nhóm...."
                        onChange={handleUChange}
                        filterOption={filterOption}
                        value={selectedOption}
                        defaultValue={selectedOption}
                    >
                        <Select.Option key="ONLY IMPORT" value="ONLY IMPORT">
                            ONLY IMPORT
                        </Select.Option>
                        {options.map((e) => (
                            <Select.Option key={e.value} value={e.value}>
                                {e.label}
                            </Select.Option>
                        ))}
                    </Select>
                    &nbsp;&nbsp;
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`/files/temp/profile_template.csv`}>
                        Download temp profile email
                    </a>
                    <br />
                    <br />
                    {showPreviewTable && (
                        <Table
                            bordered
                            columns={columns}
                            dataSource={dataImport}
                            scroll={{ x: "100%" }}
                            rowKey={() => Date.now() + randomRanger(0, 10000)}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default ImportModal;
