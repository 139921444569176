import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/proxy";
        return axiosClient.get(url, { params });
    },
    getListGroup: () => {
        const url = "/api/proxy/get-list-group";
        return axiosClient.get(url);
    },
    insert: (payload) => {
        const url = `/api/proxy`;
        return axiosClient.post(url, payload);
    },
    updateEnable: (ids, enable) => {
        const url = "/api/proxy/update-enable";
        return axiosClient.patch(url, { ids, enable });
    },
    moveToGroup: (ids, group_id) => {
        const url = `/api/proxy/move-group`;
        return axiosClient.patch(url, { ids, group_id });
    },
};
