import {
    CopyOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

export default function Actions({ record, onEdit, onDelete }) {
    const KEY_ACTIONS = {
        EDIT: "edit",
        CLONE: "clone",
        DELETE: "delete",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.EDIT:
                onEdit(record);
                break;
            case KEY_ACTIONS.CLONE:
                break;
            case KEY_ACTIONS.DELETE:
                onDelete(record);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.EDIT,
            label: "Sửa",
            icon: <EditOutlined />,
        },
        {
            key: KEY_ACTIONS.CLONE,
            label: "Nhân bản",
            icon: <CopyOutlined />,
        },
        {
            key: KEY_ACTIONS.DELETE,
            danger: true,
            label: "Xóa",
            icon: <DeleteOutlined />,
        },
    ];

    return (
        <Dropdown menu={{ items: actions, onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
