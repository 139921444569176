import {
    CloseOutlined,
    UploadOutlined,
    UsergroupAddOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";

const {
    Modal,
    Row,
    Col,
    Button,
    Form,
    Space,
    Card,
    Select,
    InputNumber,
    Upload,
} = require("antd");

const TOPICS = [
    "Music",
    "Yoga",
    "Cosmetics",
    "Book discussion club",
    "Massage",
    "Shopping",
    "Fishing",
];

function ConfigHobbiesModal(props) {
    const {
        open,
        onSubmit,
        onCancel,
        isProcessing,
        group,
        hobbies: _hobbies,
    } = props;
    const [hobbies, setHobbies] = useState(group?.hobbies || _hobbies || "{}");

    const initialValues = {
        // hobbies: hobbies !== '{}' ? Object.keys(JSON.parse((group?.hobbies || _hobbies))).map(key => {
        //     return { topic: key, percent: JSON.parse((group?.hobbies || _hobbies))[key] };
        // }) : [],
        hobbies: Object.keys(JSON.parse(hobbies)).map((key) => {
            return { topic: key, percent: JSON.parse(hobbies)[key] };
        }),
    };

    const [form] = Form.useForm();

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    const hobbies = {};

                    jsonObject.forEach((item) => {
                        hobbies[item.topic] = item.percent ?? 0;
                    });

                    setHobbies(JSON.stringify(hobbies, null, 2));
                })
                .catch((err) => {
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            return false;
        },
    };

    const onGenerateHobbies = () => {
        const hobbiesItems = form
            .getFieldsValue()
            .hobbies?.filter((e) => e && !!e.topic);

        const hobbies = {};

        hobbiesItems.forEach((item) => {
            hobbies[item.topic] = item.percent ?? 0;
        });

        setHobbies(JSON.stringify(hobbies, null, 2));
    };

    const onOK = () => {
        onSubmit(hobbies);
    };

    return (
        <Modal
            title="CONFIG HOBBIES"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="660px"
        >
            {group && (
                <h3>
                    <a>
                        <UsergroupAddOutlined /> {group?.name}
                    </a>
                </h3>
            )}
            <Row gutter={50}>
                <Col span="12">
                    <Form form={form} initialValues={initialValues}>
                        <Form.List name="hobbies">
                            {(subFields, subOpt) => (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        rowGap: 16,
                                    }}
                                >
                                    {subFields.map((subField) => (
                                        <Space key={subField.key}>
                                            <Form.Item
                                                noStyle
                                                name={[subField.name, "topic"]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Trường này là bắt buộc",
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    allowClear
                                                    showSearch
                                                    style={{ width: 180 }}
                                                    placeholder="Chọn chủ đề"
                                                >
                                                    {TOPICS.map((e) => (
                                                        <Select.Option
                                                            key={e}
                                                            value={e}
                                                        >
                                                            {e.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                            <Form.Item
                                                noStyle
                                                name={[
                                                    subField.name,
                                                    "percent",
                                                ]}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            "Trường này là bắt buộc",
                                                    },
                                                ]}
                                            >
                                                <InputNumber placeholder="Tỉ lệ" />
                                            </Form.Item>
                                            <CloseOutlined
                                                onClick={() => {
                                                    subOpt.remove(
                                                        subField.name
                                                    );
                                                }}
                                            />
                                        </Space>
                                    ))}
                                    <Button
                                        type="dashed"
                                        onClick={() => subOpt.add()}
                                        block
                                    >
                                        + Add hobby
                                    </Button>
                                    <Button
                                        type="primary"
                                        block
                                        onClick={() => onGenerateHobbies()}
                                    >
                                        Generate hobbies {">"}
                                    </Button>
                                </div>
                            )}
                        </Form.List>
                    </Form>
                </Col>
                <Col span="12">
                    or&nbsp;&nbsp;
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>
                            Click to Upload
                        </Button>
                    </Upload>
                    <br />
                    <br />
                    <Card
                        style={{
                            minHeight: "200px",
                            maxHeight: "400px",
                            overflowY: "auto",
                        }}
                    >
                        <pre>{hobbies}</pre>
                    </Card>
                </Col>
            </Row>
        </Modal>
    );
}

export default ConfigHobbiesModal;
