import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    groupUsers: [],
    listGroup: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isLoadingListGroup: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "groupUser",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingListGroup: (state, action) => {
            state.isLoadingListGroup = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setGroupUsers: (state, action) => {
            state.groupUsers = action.payload;
        },
        setListGroup: (state, action) => {
            state.listGroup = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addGroupUser: (state, action) => {
            state.isCompleted = true;
            state.groupUsers.unshift(action.payload);
        },
        editGroupUser: (state, action) => {
            state.isCompleted = true;
            state.groupUsers = state.groupUsers.map((groupUser) =>
                groupUser.id === action.payload.id ? action.payload : groupUser
            );
        },
        removeGroupUser: (state, action) => {
            state.isCompleted = true;
            state.groupUsers = state.groupUsers.filter(
                (groupUser) => groupUser.id !== action.payload
            );
        },
        removeGroupUsers: (state, action) => {
            state.isCompleted = true;
            state.groupUsers = state.groupUsers.filter(
                (groupUser) => !action.payload.includes(groupUser.id)
            );
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingListGroup,
    setIsCompleted,
    setIsProcessing,
    setGroupUsers,
    setListGroup,
    setPagination,
    addGroupUser,
    editGroupUser,
    removeGroupUser,
    removeGroupUsers,
} = slice.actions;

export const fetchGroupUsers = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setGroupUsers(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const fetchListGroup = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoadingListGroup(true));
        try {
            const data = await api.getList(params);
            dispatch(setListGroup(data.data));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingListGroup(false));
        }
    };
};

export const createGroupUser = (groupUser) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.create(groupUser);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addGroupUser(data));
                toast.success("Thêm group user mới thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const updateGroupUser = (groupUser) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.update(groupUser);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editGroupUser(data));
                toast.success("Cập nhật group user thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const refreshGroupUser = (groupUser) => {
    console.log(groupUser);
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.get(groupUser.id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editGroupUser(data));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deleteGroupUser = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.delete(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removeGroupUser(id));
                toast.success("Xóa group user thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deleteGroupUsers = (ids) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.deleteMany(ids);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removeGroupUsers(ids));
                toast.success(
                    `Xóa ${ids.length} group user đã chọn thành công!`
                );
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
