import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/device-infos";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/device-infos/summary";
        return axiosClient.get(url, { params });
    },
    getListModel: () => {
        const url = "/api/device-infos/get-list-model";
        return axiosClient.get(url);
    },
    insert: (payload) => {
        const url = "/api/device-infos";
        return axiosClient.post(url, payload);
    },
    exportDevice: () => {
        const url = `/api/device-infos/export`;
        return axiosClient.get(url);
    },
};
