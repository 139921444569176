import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    hosts: [],
    summary: {},
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isLoadingFilter: false,
    isLoadingSummary: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "host",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingSummary: (state, action) => {
            state.isLoadingSummary = action.payload;
        },
        setIsLoadingFilter: (state, action) => {
            state.isLoadingFilter = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setHosts: (state, action) => {
            state.hosts = action.payload;
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        editHost: (state, action) => {
            console.log('Payload received:', action.payload);
            state.isCompleted = true;
            state.hosts = state.hosts.map((host) =>
                host.id === action.payload.id ? action.payload : host
            );
            
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingSummary,
    setIsLoadingFilter,
    setIsCompleted,
    setIsProcessing,
    setHosts,
    setSummary,
    setPagination,
    editHost,
} = slice.actions;

export const fetchHostSummary = () => {
    return async (dispatch) => {
        dispatch(setIsLoadingSummary(true));
        try {
            const data = await api.getSummary();
            dispatch(setSummary(data.data));
        } catch (err) {
            toast.error(err);
        } finally {
            dispatch(setIsLoadingSummary(false));
        }
    };
};
export const fetchHosts = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setHosts(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            toast.error(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const updateClick = (row) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.update(row);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editHost(data));
                if(row.is_clicking_ads == 1) {
                    toast.success(`Start click ads profile ${row.id}`);
                } else {
                    toast.info(`Stop click ads profile ${row.id}`)
                }
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const reportClick = (dataReport) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.report(dataReport);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Report thành công");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
