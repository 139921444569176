import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import {
    Button,
    Col,
    Row,
    Table,
    Upload,
    Modal,
    Select,
    InputNumber,
} from "antd";
import { randomRanger } from "utils/common";
import { filter } from "utils/constants";

function ExportModal(props) {
    const {
        open,
        onSubmit,
        onCancel,
        isProcessing,
        data: _data,
        options,
    } = props;
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);
    const [status, setStatus] = useState(null);
    const [group, setGroup] = useState(null);
    const [limit, setLimit] = useState(null);

    const handleChangeStatus = (value) => {
        setStatus(value);
    };

    const handleChangeGroup = (value) => {
        setGroup(value);
    };

    const handleChangeLimit = (value) => {
        setLimit(value);
    };

    const listStatus = filter.profile.status;

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const onOK = () => {
        const emails = dataImport.map((obj) => obj.email);
        onSubmit(status, group, limit, emails);
    };

    const filterOption = (input, option) => {
        return option.children.includes(input)
    }

    const columns = [
        {
            title: "Email",
            dataIndex: "email",
            fixed: "left",
            width: "200px",
        },
        {
            title: "Password",
            dataIndex: "password",
            fixed: "left",
            width: "150px",
        },
        {
            title: "Service",
            dataIndex: "service",
            width: "100px",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Group ID",
            dataIndex: "group_id",
            width: "100px",
        },
        {
            title: "Priority",
            dataIndex: "priority",
            width: "100px",
        },
    ];

    return (
        <Modal
            title="Xuất Danh Sách Profiles Email"
            okText="Xuất"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="7">
                    <span className="text-[21px]">Trạng thái</span>
                    <Select
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Xuất theo trạng thái...."
                        onChange={handleChangeStatus}
                        defaultValue={status}
                    >
                        {listStatus.map((e) => (
                            <Select.Option key={e} value={e}>
                                {e}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span="7">
                    <span className="text-[21px]">Nhóm</span>
                    <Select
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Xuất theo nhóm...."
                        onChange={handleChangeGroup}
                        filterOption={filterOption}
                        defaultValue={group}
                    >
                        {options.map((e) => (
                            <Select.Option key={e.value} value={e.value}>
                                {e.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span="7">
                    <span className="text-[21px]">Limit</span>
                    <InputNumber
                        min={1}
                        max={10000}
                        onChange={handleChangeLimit}
                        style={{ width: 250 }}
                        placeholder="Nhập số bản ghi muốn xuất"
                        defaultValue={limit}
                    ></InputNumber>
                </Col>
            </Row>
            <br />
            <Row guiter={50}>
                <Col span="24">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    <br />
                    <br />
                    {showPreviewTable && (
                        <Table
                            bordered
                            columns={columns}
                            dataSource={dataImport}
                            scroll={{ x: "100%" }}
                            rowKey={() => Date.now() + randomRanger(0, 10000)}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default ExportModal;
