export const delay = async (ms) =>
    await new Promise((resolve) => setTimeout(resolve, 1000));

export const randomRanger = (min, max) =>
    Math.floor(Math.random() * (max - min + 1)) + min;

export const randomEl = (arr) => arr[randomRanger(0, arr.length - 1)];

export const downloadFileResponse = (data, fileName) => {
    try {
        const blob = new Blob([data]);
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    } catch (error) {
        console.log(error);
    }
};
