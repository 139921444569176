import {
    Button,
    Card,
    Col,
    Input,
    Row,
    Select,
    Space,
    Table,
    Typography,
    Modal,
} from "antd";
const { confirm } = Modal;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, paginationParams } from "utils/constants";

import {
    DownloadOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    ReloadOutlined,
    UploadOutlined,
    ExclamationCircleFilled,
} from "@ant-design/icons";
import moment from "moment/moment";
import { toast } from "react-toastify";
import profileApi from "../profile/api";
import * as profileAction from "./slice";
import ImportModal from "./components/ImportModal";
import MoveGroupModal from "./components/MoveGroupModal";
import ExportModal from "./components/ExportModal";
import RestoreOrDeleteModal from "./components/RestoreOrDeleteModal";

function Profile() {
    const dispatch = useDispatch();
    const {
        profiles,
        summary,
        filterData,
        pagination,
        isLoading,
        isProcessing,
        isLoadingSummary,
        isLoadingFilter,
    } = useSelector((state) => state.profile);

    const [searchVal, setSearchVal] = useState("");
    const [searchGroup, setSearchGroup] = useState("");
    const [searchPlatform, setSearchPlatform] = useState("");
    const [searchService, setSearchService] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [platforms, setPlatforms] = useState([]);
    const [services, setServices] = useState([]);
    const [statuss, setStatuss] = useState([]);
    const [groups, setGroups] = useState([]);
    const [openMoveGroupModal, setOpenMoveGroupModal] = useState(false);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [dataImport, setDataImport] = useState([]);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [openRestoreOrDeleteModal, setOpenRestoreOrDeleteModal] =
        useState(false);

    useEffect(() => {
        dispatch(profileAction.fetchProfiles(pagination));
        dispatch(profileAction.fetchProfileSummary());

        const fetchListFilter = async () => {
            try {
                const listGroup = await profileApi.getListGroup();
                const filterProfile = filter.profile;
                if (listGroup.error) {
                    toast.error(listGroup.error);
                } else {
                    setGroups(listGroup);
                    setPlatforms(filterProfile.platform);
                    setServices(filterProfile.service);
                    setStatuss(filterProfile.status);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };
        fetchListFilter();
    }, []);

    const onChangeValSearch = (e) => {
        setSearchVal(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            profileAction.fetchProfiles({
                ...pagination,
                searchVal: searchVal,
                platform: searchPlatform,
                service: searchService,
                status: searchStatus,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const filterOption = (input, option) => {
        return option.children.includes(input)
    }

    const onTableChange = async (pagination) => {
        dispatch(
            profileAction.fetchProfiles({
                ...pagination,
                searchVal: searchVal,
                platform: searchPlatform,
                service: searchService,
                status: searchStatus,
                group_id: searchGroup,
            })
        );
    };

    const onSelectStatusSearch = (status) => {
        setSearchStatus(status);
        dispatch(
            profileAction.fetchProfiles({
                ...pagination,
                searchVal: searchVal,
                platform: searchPlatform,
                service: searchService,
                status: status,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const onSelectPlatformSearch = (platform) => {
        setSearchPlatform(platform);
        dispatch(
            profileAction.fetchProfiles({
                ...pagination,
                searchVal: searchVal,
                platform: platform,
                service: searchService,
                status: searchStatus,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const onSelectServiceSearch = (service) => {
        setSearchService(service);
        dispatch(
            profileAction.fetchProfiles({
                ...pagination,
                searchVal: searchVal,
                platform: searchPlatform,
                service: service,
                status: searchStatus,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const onSelectGroupSearch = (group_id) => {
        setSearchGroup(group_id);
        dispatch(
            profileAction.fetchProfiles({
                ...pagination,
                searchVal: searchVal,
                platform: searchPlatform,
                service: searchService,
                status: searchStatus,
                group_id: group_id,
                current: 1,
            })
        );
    };

    const onOpenMoveGroupModal = () => {
        setOpenMoveGroupModal(true);
    };

    const onCloseMoveGroupModal = () => {
        setOpenMoveGroupModal(false);
        dispatch(profileAction.setIsProcessing(false));
    };

    const onConvertOptions = () => {
        const arr = groups.map((e) => {
            return { value: e.id, label: e.name };
        });
        return arr;
    };

    const onMoveGroup = (group) => {
        dispatch(profileAction.moveToGroup(selectedRows, group));
        onCloseMoveGroupModal();
    };

    const showConfirmRestore = () => {
        confirm({
            title: "Cảnh báo !!!",
            icon: <ExclamationCircleFilled />,
            content: "Bạn có chắc chắn muốn restore các profile đã chọn?",
            onOk() {
                onRestore();
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const onRestore = () => {
        dispatch(profileAction.restore(selectedRows, 0));
    };

    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const onCloseImportModal = () => {
        setOpenImportModal(false);
        dispatch(profileAction.setIsProcessing(false));
    };

    const onImport = (dataImport, group) => {
        setDataImport(dataImport);
        if (!group) {
            dispatch(profileAction.insertProfile(dataImport));
        } else {
            dispatch(profileAction.moveToGroup(dataImport, group));
        }
        setOpenImportModal(false);
    };

    const onOpenExportModal = () => {
        setOpenExportModal(true);
    };

    const onCloseExportModal = () => {
        setOpenExportModal(false);
        dispatch(profileAction.setIsProcessing(false));
    };

    const onExport = (status, group_id, limit, emails) => {
        dispatch(
            profileAction.exportProfile({ status, group_id, limit, emails })
        );
        setOpenExportModal(false);
    };

    const onOpenRestoreOrDeleteModal = () => {
        setOpenRestoreOrDeleteModal(true);
    };

    const onCloseRestoreOrDeleteModal = () => {
        setOpenRestoreOrDeleteModal(false);
        dispatch(profileAction.setIsProcessing(false));
    };

    const onRestoreOrDelete = (dataImport, selectedOption) => {
        setDataImport(dataImport);
        if (selectedOption == "DELETE") {
            dispatch(profileAction.deleteProfile(dataImport));
        } else if (selectedOption == "RESTORE") {
            dispatch(profileAction.restore(dataImport, 1)); // 1 là byCSV, move bằng emails (csv)
        }
        setOpenRestoreOrDeleteModal(false);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "100px",
            fixed: "left",
        },
        {
            title: "Email",
            dataIndex: "email",
            width: "320px",
            fixed: "left",
        },
        {
            title: "Email khôi phục",
            dataIndex: "recover_mail",
            width: "320px",
        },
        {
            title: "Số điện thoại khôi phục",
            dataIndex: "recover_phone",
            width: "160px",
        },
        {
            title: "Khóa 2fa",
            dataIndex: "secret2fa",
            width: "120px",
        },
        {
            title: "Dịch vụ",
            dataIndex: "service",
            width: "150px",
        },
        {
            title: "Nền tảng",
            dataIndex: "platform",
            width: "140px",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            width: "120px",
        },
        {
            title: "Host ID",
            //dataIndex: "vm_id",
            width: "140px",
            render: (record) => <a>{record.vm_id}</a>,
        },
        {
            title: "Device ID",
            //dataIndex: "deviceId",
            width: "120px",
            render: (record) => <a>{record.deviceId}</a>,
        },
        {
            title: "User ID",
            dataIndex: "userId",
            width: "120px",
        },
        {
            title: "Proxy",
            dataIndex: "proxy",
            width: "120px",
            render: (data) => (data && data == null ? "" : <a>{data}</a>),
        },
        {
            title: "Fake ID ",
            dataIndex: "fakeId",
            width: "120px",
            render: (data) => <a>{data}</a>,
        },
        {
            title: "Sim ID ",
            dataIndex: "fakeId",
            width: "120px",
            render: (data) => <a>{data}</a>,
        },
        {
            title: "Group",
            //dataIndex: "group_id",
            width: "270px",
            render: (record) => <a>{record.gr_name}</a>,
        },
        {
            title: "Ưu tiên",
            dataIndex: "priority",
            width: "100px",
            align: "center",
        },
        {
            title: "Description",
            dataIndex: "description",
            width: "250px",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_time",
            width: "180px",

            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "updated_time",
            width: "180px",

            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ngày chuyển nhóm",
            dataIndex: "updated_group",
            width: "180px",
            render: (data) => {
                return data ? moment(data).format("DD/MM/YYYY HH:mm") : ''
            }
        },
    ];

    const rowSelection = {
        onChange: (_) => {
            setSelectedRows(_);
        },
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    QUẢN LÝ PROFILE EMAIL
                </Typography.Title>
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => onOpenImportModal()}
                    >
                        Import / Chuyển nhóm
                    </Button>
                    <Button
                        className="bg-green-500 "
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => onOpenExportModal()}
                    >
                        Export
                    </Button>
                    <Button
                        className="bg-amber-500 "
                        type="primary"
                        icon={<ReloadOutlined />}
                        onClick={() => onOpenRestoreOrDeleteModal()}
                    >
                        Restore / Xóa
                    </Button>
                </Space>
            </Row>

            <br />
            <Row gutter={12} justify="start">
                <Col span={7}>
                    <Card loading={isLoadingSummary}>
                        <div className="text-2xl">
                            Tổng số profile:{" "}
                            <span className=" font-semibold">
                                {summary.total}
                            </span>
                        </div>
                        <div className="flex text-base">
                            <span className="w-1/3">
                                Synced: {summary.synced}
                                <br />
                                Disable: {" "}
                                <span className="font-semibold text-red-600">
                                    {summary.disable}
                                </span>
                                <br />
                                Verify:{" "}
                                <span className="text-red-600 font-semibold">
                                    {summary.verify}
                                </span>
                            </span>
                            <span className="w-1/3">
                                Create: {summary.create}
                                <br />
                                Sycing: {summary.syncing}  
                                <br />
                                Error:{" "}
                                <span className="font-semibold text-red-600">
                                    {summary.error}
                                </span>
                            </span>
                            <span className="w-1/3">
                                New: {summary.new} 
                                <br />
                                Active: {filterData.active}  
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col span={7}>
                <Card loading={isLoadingFilter}>
                        <div className="text-2xl">
                            Filter:{" "}
                            <span className=" font-semibold">
                                {filterData.total}
                            </span>
                        </div>
                        <div className="flex text-base">
                            <span className="w-1/3">
                                Synced: {filterData.synced}
                                <br />
                                Disable: {" "}
                                <span className="font-semibold text-red-600">
                                    {filterData.disable}
                                </span>
                                <br />
                                Verify:{" "}
                                <span className="text-red-600 font-semibold">
                                    {filterData.verify}
                                </span>
                            </span>
                            <span className="w-1/3">
                                Create: {filterData.create}
                                <br />
                                Sycing: {filterData.syncing}
                                <br />
                                Error:{" "}
                                <span className="font-semibold text-red-600">
                                    {filterData.error}
                                </span>
                            </span>
                            <span className="w-1/3">
                                New: {filterData.new}   
                                <br />
                                Active: {filterData.active}
                            </span>
                        </div>
                    </Card>
                </Col>
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                {selectedRows.length ? (
                    <Space size="middle">
                        <Button
                            type="primary"
                            icon={<PauseCircleOutlined />}
                            onClick={onOpenMoveGroupModal}
                        >
                            Chuyển nhóm tất cả
                        </Button>
                        <Button
                            type="primary"
                            danger
                            icon={<PlayCircleOutlined />}
                            onClick={showConfirmRestore}
                        >
                            Restore tất cả
                        </Button>
                    </Space>
                ) : (
                    <>&nbsp;</>
                )}
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Nền tảng"
                        onChange={onSelectPlatformSearch}
                    >
                        {platforms.map((e) => (
                            <Select.Option key={e} value={e}>
                                {e}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Dịch vụ"
                        onChange={onSelectServiceSearch}
                    >
                        {services.map((e) => (
                            <Select.Option key={e} value={e}>
                                {e}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Trạng thái"
                        onChange={onSelectStatusSearch}
                    >
                        {statuss.map((e) => (
                            <Select.Option key={e} value={e}>
                                {e}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Group"
                        onChange={onSelectGroupSearch}
                        filterOption={filterOption}
                    >
                        {groups.map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Input.Search
                        placeholder="Tìm kiếm"
                        onChange={onChangeValSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            <Table
                bordered
                loading={isLoading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={profiles}
                rowKey={(record) => record.id}
                pagination={{
                    ...paginationParams,
                    ...pagination,
                }}
                scroll={{ x: "100%", y: 'calc(100vh - 660px)' }}
                onChange={onTableChange}
            />
            {openMoveGroupModal && (
                <MoveGroupModal
                    open={openMoveGroupModal}
                    options={onConvertOptions()}
                    isProcessing={isProcessing}
                    onSubmit={onMoveGroup}
                    onCancel={onCloseMoveGroupModal}
                />
            )}
            {openImportModal && (
                <ImportModal
                    open={openImportModal}
                    options={onConvertOptions()}
                    isProcessing={isProcessing}
                    onSubmit={onImport}
                    onCancel={onCloseImportModal}
                    data={dataImport}
                />
            )}
            {openExportModal && (
                <ExportModal
                    open={openExportModal}
                    options={onConvertOptions()}
                    isProcessing={isProcessing}
                    onSubmit={onExport}
                    onCancel={onCloseExportModal}
                    //data={dataImport}
                />
            )}
            {openRestoreOrDeleteModal && (
                <RestoreOrDeleteModal
                    open={openRestoreOrDeleteModal}
                    options={onConvertOptions()}
                    isProcessing={isProcessing}
                    onSubmit={onRestoreOrDelete}
                    onCancel={onCloseRestoreOrDeleteModal}
                    data={dataImport}
                />
            )}
        </>
    );
}

export default Profile;
