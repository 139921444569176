import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    devices: [],
    summary: {},
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isLoadingSummary: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "device",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingSummary: (state, action) => {
            state.isLoadingSummary = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setDevices: (state, action) => {
            state.devices = action.payload;
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addDevice: (state, action) => {
            state.isCompleted = true;
            state.devices.unshift(action.payload);
        },
        editDevice: (state, action) => {
            state.isCompleted = true;
            state.devices = state.devices.map((device) =>
                device.id === action.payload.id ? action.payload : device
            );
        },
        removeDevice: (state, action) => {
            state.isCompleted = true;
            state.devices = state.devices.filter(
                (device) => device.id !== action.payload.id
            );
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingSummary,
    setIsCompleted,
    setIsProcessing,
    setDevices,
    setSummary,
    setPagination,
    addDevice,
    editDevice,
    removeDevice,
} = slice.actions;

export const fetchDeviceSummary = () => {
    return async (dispatch) => {
        dispatch(setIsLoadingSummary(true));
        try {
            const data = await api.getSummary();
            dispatch(setSummary(data.data));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingSummary(false));
        }
    };
};
export const fetchDevices = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setDevices(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const insertDevice = (listDevice) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.insert(listDevice);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import danh sách device thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchDevices({ pagination: { current: 1, pageSize: 10 } })
                );
                dispatch(fetchDeviceSummary());
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const exportDevice = () => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.exportDevice();
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                exp(data);
                toast.success("Đã export danh sách device");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchDevices({
                        pagination: { current: 1, pageSize: 10 },
                    })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

const exp = (data) => {
    try {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `device.csv`;
        link.click();
    } catch (error) {
        console.log(error);
    }
};

export const updateDevice = (device) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.update(device);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editDevice(data));
                toast.success("Cập nhật device thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const updateAllSelected = (ids, value, keyAction) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.updateAllSelected(ids, value, keyAction);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Cập nhật device thành công!");
                dispatch(setIsCompleted(true));
                dispatch(fetchDeviceSummary());
                dispatch(
                    fetchDevices({
                        pagination: { current: 1, pageSize: 10 },
                    })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
