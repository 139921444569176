import { Layout, Menu } from "antd";
import { default as Icon, default as Logo } from "assets/img/icon.png";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { routers } from "router";
const { Sider } = Layout;

import {
    AppstoreOutlined,
    BarChartOutlined,
    CalendarOutlined,
    DashboardOutlined,
    ExceptionOutlined,
    LaptopOutlined,
    MobileOutlined,
    PhoneOutlined,
    PlaySquareOutlined,
    SettingOutlined,
    TeamOutlined,
    UserSwitchOutlined,
    UsergroupAddOutlined,
    WifiOutlined,
    CommentOutlined,
    YoutubeOutlined,
    AimOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";

export default function MySider() {
    const navigate = useNavigate();
    const location = useLocation();
    const [collapsed, setCollapsed] = useState(false);
    const [selectedKeys, setSelectedKeys] = useState([]);
    const [openKeys, setOpenKeys] = useState([]);
    const { userInfo } = useSelector((state) => state.auth);

    const menuItems = {
        admin: [
            {
                label: "Dashboard",
                key: routers.dashboard,
                icon: <DashboardOutlined />,
            },
            {
                label: "Config",
                key: routers.config.index,
                icon: <SettingOutlined />,
                children: [
                    {
                        label: "Project",
                        key: [
                            routers.config.index,
                            routers.config.project,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Group User",
                        key: [routers.config.index, routers.config.group].join(
                            "/"
                        ),
                        icon: <UsergroupAddOutlined />,
                    },
                    {
                        label: "Profile Email",
                        key: [
                            routers.config.index,
                            routers.config.profile,
                        ].join("/"),
                        icon: <UserSwitchOutlined />,
                    },
                    {
                        label: "Plan",
                        key: [routers.config.index, routers.config.plan].join(
                            "/"
                        ),
                        icon: <CalendarOutlined />,
                    },
                    {
                        label: "Playlist",
                        key: [
                            routers.config.index,
                            routers.config.playlist,
                        ].join("/"),
                        icon: <PlaySquareOutlined />,
                    },
                    // {
                    //     label: "Channel",
                    //     key: [
                    //         routers.config.index,
                    //         routers.config.channel,
                    //     ].join("/"),
                    //     icon: <YoutubeOutlined />,
                    // },
                    {
                        label: "Host",
                        key: [routers.config.index, routers.config.host].join(
                            "/"
                        ),
                        icon: <LaptopOutlined />,
                    },
                    {
                        label: "Device",
                        key: [routers.config.index, routers.config.device].join(
                            "/"
                        ),
                        icon: <MobileOutlined />,
                    },
                    {
                        label: "Device Info",
                        key: [
                            routers.config.index,
                            routers.config.deviceInfo,
                        ].join("/"),
                        icon: <ExceptionOutlined />,
                    },
                    {
                        label: "Sim Info",
                        key: [
                            routers.config.index,
                            routers.config.simInfo,
                        ].join("/"),
                        icon: <PhoneOutlined />,
                    },
                    {
                        label: "Proxy",
                        key: [routers.config.index, routers.config.proxy].join(
                            "/"
                        ),
                        icon: <WifiOutlined />,
                    },
                    {
                        label: "Comment",
                        key: [routers.config.index, routers.config.comment].join(
                            "/"
                        ),
                        icon: <CommentOutlined />,
                    },
                    {
                        label: "Channel",
                        key: [routers.config.index, routers.config.channel].join(
                            "/"
                        ),
                        icon: <YoutubeOutlined />,
                    },
                ],
            },
            {
                label: "Report",
                key: routers.report.index,
                icon: <BarChartOutlined />,
                children: [
                    {
                        label: "Plan",
                        key: [
                            routers.report.index,
                            routers.report.plan,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    // {
                    //     label: "Channels",
                    //     key: [routers.report.index, routers.report.channels].join("/"),
                    //     icon: <UsergroupAddOutlined />,
                    // },
                    {
                        label: "Playlist",
                        key: [
                            routers.report.index,
                            routers.report.playlist,
                        ].join("/"),
                        icon: <PlaySquareOutlined />,
                    },
                    {
                        label: "Profile",
                        key: [
                            routers.report.index,
                            routers.report.profile,
                        ].join("/"),
                        icon: <TeamOutlined />,
                    },
                    // {
                    //     label: "Scripts",
                    //     key: [routers.report.index, routers.report.scrips].join("/"),
                    //     icon: <PlaySquareOutlined />,
                    // },
                ],
            },
            {
                label: "Clicks",
                key: routers.clicks.index,
                icon: <AimOutlined />,
                children: [
                    {
                        label: "Manage",
                        key: [
                            routers.clicks.index,
                            routers.clicks.manage,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Report",
                        key: [
                            routers.clicks.index,
                            routers.clicks.report,
                        ].join("/"),
                        icon: <BarChartOutlined />,
                    },
                ],
            },
        ],
        customer: [
            {
                label: "Dashboard",
                key: routers.dashboard,
                icon: <DashboardOutlined />,
            },
            {
                label: "Config",
                key: routers.config.index,
                icon: <SettingOutlined />,
                children: [
                    {
                        label: "Project",
                        key: [
                            routers.config.index,
                            routers.config.project,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Group User",
                        key: [routers.config.index, routers.config.group].join(
                            "/"
                        ),
                        icon: <UsergroupAddOutlined />,
                    },
                    {
                        label: "Profile Email",
                        key: [
                            routers.config.index,
                            routers.config.profile,
                        ].join("/"),
                        icon: <UserSwitchOutlined />,
                    },
                    {
                        label: "Plan",
                        key: [routers.config.index, routers.config.plan].join(
                            "/"
                        ),
                        icon: <CalendarOutlined />,
                    },
                    {
                        label: "Playlist",
                        key: [
                            routers.config.index,
                            routers.config.playlist,
                        ].join("/"),
                        icon: <PlaySquareOutlined />,
                    },
                ],
            },
            {
                label: "Report",
                key: routers.report.index,
                icon: <BarChartOutlined />,
                children: [
                    {
                        label: "Plan",
                        key: [
                            routers.report.index,
                            routers.report.plan,
                        ].join("/"),
                        icon: <AppstoreOutlined />,
                    },
                    {
                        label: "Playlist",
                        key: [
                            routers.report.index,
                            routers.report.playlist,
                        ].join("/"),
                        icon: <PlaySquareOutlined />,
                    },
                    {
                        label: "Profile",
                        key: [
                            routers.report.index,
                            routers.report.profile,
                        ].join("/"),
                        icon: <TeamOutlined />,
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        setSelectedKeys(location.pathname.slice(1));
        setOpenKeys(location.pathname.split("/").slice(1));
    }, [location]);

    const onSelectMenuItem = (item) => {
        navigate(item.key);
    };

    const onOpenChangeSubMenu = (item) => {
        setOpenKeys(item);
    };

    return (
        <>
            <Sider
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                className="overflow-auto h-screen !sticky inset-0"
                width={250}
            >
                <div className="p-4" style={{ textAlign: "center" }}>
                    <img
                        src={collapsed ? Icon : Logo}
                        alt="logo"
                        className="w-full"
                        style={{ width: collapsed ? "50px" : "80px" }}
                    />
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={selectedKeys}
                    openKeys={openKeys}
                    items={menuItems[userInfo.group_user]}
                    onSelect={onSelectMenuItem}
                    onOpenChange={onOpenChangeSubMenu}
                />
            </Sider>
        </>
    );
}
