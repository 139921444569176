import { Col, Modal, Row, Space, Typography } from "antd";
const { Text } = Typography;
import { COLUMNS } from "../columns2";
import { useEffect, useState } from "react";

const Param = (props) => {
    const { title, cKey, show, onClick } = props;
    const [isShow, setIsShow] = useState(show);

    useEffect(() => setIsShow(show), [show]);

    const onClickParam = () => {
        if (cKey === "url") return;
        setIsShow(!isShow);
        onClick(cKey);
    };
    return (
        <Text delete={!isShow} onClick={onClickParam}>
            {title}
        </Text>
    );
};

function ConfigParaModal(props) {
    const { open, onSubmit, onCancel } = props;
    const [columns, setColumns] = useState(
        Array.from({ length: 3 }, (v, i) =>
            COLUMNS.slice(
                i * Math.ceil(COLUMNS.length / 3),
                (i + 1) * Math.ceil(COLUMNS.length / 3)
            )
        )
    );
    const [showParams, setShowParams] = useState(
        localStorage.getItem("playlist_show_params")?.split(",") ||
            COLUMNS.map((param) => param.cKey)
    );

    useEffect(() => {
        if (open) {
            const _columns = columns.map((column) =>
                column.map((param) => ({
                    ...param,
                    show: showParams.includes(param.cKey),
                }))
            );
            setColumns(_columns);
            console.log(_columns);
        }
    }, [open]);

    const onClickParam = (cKey) => {
        console.log(cKey);
        if (showParams.includes(cKey))
            setShowParams(showParams.filter((param) => param !== cKey));
        else setShowParams([...showParams, cKey]);
    };

    const onOk = () => {
        onSubmit(showParams);
    };

    return (
        <Modal
            centered
            open={open}
            onCancel={onCancel}
            onOk={onOk}
            // footer={[]}
            width="800px"
        >
            <Row gutter={12} justify="space-between" align="top">
                {columns.map((column, i) => (
                    <Col key={i} span={8}>
                        <Space direction="vertical">
                            {column.map((param, j) => (
                                <Param
                                    key={j}
                                    title={param.title}
                                    cKey={param.cKey}
                                    show={param.show}
                                    onClick={onClickParam}
                                />
                            ))}
                        </Space>
                    </Col>
                ))}
            </Row>
        </Modal>
    );
}

export default ConfigParaModal;
