import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    channels: [],
    listGroup: [],
    summary: 0,
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isLoadingListGroup: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "channel",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingListGroup: (state, action) => {
            state.isLoadingListGroup = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setChannel: (state, action) => {
            state.channels = action.payload;
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setListGroup: (state, action) => {
            state.listGroup = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingListGroup,
    setIsCompleted,
    setIsProcessing,
    setChannel,
    setSummary,
    setListGroup,
    setPagination,
} = slice.actions;

export const fetchChannels = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        console.log(params);
        try {
            const data = await api.getAll(params);
            dispatch(setChannel(data.data));
            dispatch(setPagination(data.meta));
            dispatch(setSummary(data.meta.total));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const fetchListGroup = () => {
    return async (dispatch) => {
        dispatch(setIsLoadingListGroup(true));
        try {
            const data = await api.getListGroup();
            dispatch(setListGroup(data.data.listGroup));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingListGroup(false));
        }
    };
};

export const insertChannel = (listChannel) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.insert(listChannel);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import danh sách channel thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchChannels({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const exportChannel = () => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.exportChannel();
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                exp(data);
                toast.success("Đã export danh sách channel");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchChannels({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

const exp = (data) => {
    try {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `channels.csv`;
        link.click();
    } catch (error) {
        console.log(error);
    }
};

export const deleteChannel = (ids) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.deleteChannel(ids);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Xóa thành công danh sách profiles");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchChannels({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
