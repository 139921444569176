import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";
import { downloadFileResponse } from "utils/common";

const initialState = {
    playlists: [],
    listUrl: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "playlist",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setPlaylists: (state, action) => {
            state.playlists = action.payload;
        },
        setListUrl: (state, action) => {
            state.listUrl = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addPlaylist: (state, action) => {
            state.isCompleted = true;
            state.playlists.unshift(action.payload);
        },
        editPlaylist: (state, action) => {
            state.isCompleted = true;
            state.playlists = state.playlists.map((playlist) =>
                playlist.id === action.payload.id ? action.payload : playlist
            );
        },
        removePlaylist: (state, action) => {
            state.isCompleted = true;
            state.playlists = state.playlists.filter(
                (playlist) => playlist.id !== action.payload
            );
        },
        removePlaylists: (state, action) => {
            state.isCompleted = true;
            state.playlists = state.playlists.filter(
                (playlist) => !action.payload.includes(playlist.id)
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setPlaylists,
    setListUrl,
    setPagination,
    addPlaylist,
    editPlaylist,
    removePlaylist,
    removePlaylists,
} = slice.actions;

export const fetchPlaylists = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            const listUrl = await api.getAllUrrl();
            dispatch(setPlaylists(data.data));
            dispatch(setListUrl(listUrl.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const updatePlaylist = (playlist) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.update(playlist);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editPlaylist(data));
                toast.success("Cập nhật playlist thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deletePlaylist = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.delete(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePlaylist(id));
                toast.success("Xóa playlist thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deletePlaylists = (ids) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.deleteMany(ids);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePlaylists(ids));
                toast.success(`Xóa ${ids.length} playlist đã chọn thành công!`);
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const importPlaylist = (playlists) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            dispatch(setIsCompleted(false));
            const data = await api.import_(playlists);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import playlist thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchPlaylists({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const exportPlaylist = (fields) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            dispatch(setIsCompleted(false));
            const data = await api.export_(fields);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                downloadFileResponse(data, "playlists.csv");
                toast.success("Export playlist thành công");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
