import { SyncOutlined } from "@ant-design/icons";
import { Switch, Tag, Typography } from "antd";
import dayjs from "dayjs";

const { Paragraph } = Typography;

export const COLUMNS = [
    {
        title: "ID",
        cKey: "id",
        dataIndex: "id",
        name: "id",
        editable: false,
        show: true,
        props: {},
        fixed: "left",
        width: "100px",
    },
    {
        title: "Lặp lại",
        cKey: "plan_type",
        dataIndex: "plan_type",
        name: "plan_type",
        editable: true,
        editType: "select",
        show: true,
        props: {
            options: [
                {
                    value: "only",
                    label: "Only",
                },
                {
                    value: "daily",
                    label: "Daily",
                },
            ],
        },
        width: "90px",
        align: "center",
        render: (data) => data[0].toUpperCase() + data.slice(1),
    },
    {
        title: "Thời gian bắt đầu",
        cKey: "start_time",
        dataIndex: "start_time",
        name: "start_time",
        editable: true,
        editType: "datetime",
        show: true,
        props: {},
        width: "180px",
        align: "center",
        render: (data) => (data ? dayjs(data).format("DD/MM/YYYY HH:mm") : ""),
    },
    {
        title: "Thời gian kết thúc",
        cKey: "stop_time",
        dataIndex: "stop_time",
        name: "stop_time",
        editable: true,
        editType: "datetime",
        show: true,
        props: {},
        width: "180px",
        align: "center",
        render: (data) => (data ? dayjs(data).format("DD/MM/YYYY HH:mm") : ""),
    },
    {
        title: "Giờ report",
        cKey: "report_time",
        dataIndex: "report_time",
        name: "report_time",
        editable: true,
        editType: "time",
        show: true,
        props: {},
        width: "120px",
        align: "center",
        render: (data) => (data ? dayjs(data).format("HH:mm") : ""),
    },
    {
        title: "Trạng thái",
        cKey: "status",
        dataIndex: "status",
        name: "status",
        editable: false,
        show: true,
        align: "center",
        width: 120,
        render: (data) => {
            if (data === "NEW") return <Tag color="blue">New</Tag>;
            if (data === "RUNNING")
                return (
                    <Tag icon={<SyncOutlined spin />} color="gold">
                        Running
                    </Tag>
                );
            if (data === "DONE") return <Tag color="green">Done</Tag>;
            return <Tag>Null</Tag>;
        },
    },
    {
        title: "Kết quả Report Script",
        cKey: "result_report",
        dataIndex: "result_report",
        name: "result_report",
        editable: false,
        show: true,
        align: "center",
        width: 130,
    },
    {
        title: "Group",
        cKey: "group_id",
        name: "group_id",
        editable: true,
        editType: "select_multiple",
        show: true,
        props: {},
        width: "250px",
        render: (data) => data.groups,
    },
    {
        title: "Playlist ID",
        cKey: "playlist_id",
        dataIndex: "playlist_id",
        name: "playlist_id",
        editable: true,
        editType: "number",
        show: true,
        props: {},
        align: "center",
        width: "160px",
    },
    {
        title: "Key Search",
        cKey: "key_search",
        dataIndex: "key_search",
        name: "key_search",
        editable: true,
        editType: "textarea",
        show: true,
        props: {},
        width: "220px",
        render: (data) => (
            <Paragraph
                ellipsis={{ rows: 2, tooltip: data }}
                style={{ fontSize: "16px" }}
            >
                {data}
            </Paragraph>
        ),
    },
    {
        title: "Max watch time",
        cKey: "max_watch_time",
        dataIndex: "max_watch_time",
        name: "max_watch_time",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Min watch time",
        cKey: "min_watch_time",
        dataIndex: "min_watch_time",
        name: "min_watch_time",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Video time",
        cKey: "video_time",
        dataIndex: "video_time",
        name: "video_time",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Max view",
        cKey: "max_view",
        dataIndex: "max_view",
        name: "max_view",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Suggest (%)",
        cKey: "suggest_percent",
        dataIndex: "suggest_percent",
        name: "suggest_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Suggest video",
        cKey: "suggest_videos",
        dataIndex: "suggest_videos",
        name: "suggest_videos",
        editable: true,
        editType: "textarea",
        show: true,
        props: {},
        width: "220px",
        render: (data) => (
            <Paragraph
                ellipsis={{ rows: 2, tooltip: data }}
                style={{ fontSize: "16px" }}
            >
                {data}
            </Paragraph>
        ),
    },
    {
        title: "Search (%)",
        cKey: "search_percent",
        dataIndex: "search_percent",
        name: "search_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "CTR Search (%)",
        cKey: "ctr_search",
        dataIndex: "ctr_search",
        name: "ctr_search",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 150,
    },
    {
        title: "Playlist (%)",
        cKey: "playlist_percent",
        dataIndex: "playlist_percent",
        name: "playlist_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Nguồn Playlist",
        cKey: "source_playlist",
        dataIndex: "source_playlist",
        name: "source_playlist",
        editable: true,
        editType: "textarea",
        show: true,
        props: {},
        width: "300px",
        render: (data) => (
            <Paragraph
                ellipsis={{ rows: 2, tooltip: data }}
                style={{ fontSize: "16px" }}
            >
                {data}
            </Paragraph>
        ),
    },
    {
        title: "CTR Playlist (%)",
        cKey: "ctr_playlist",
        dataIndex: "ctr_playlist",
        name: "ctr_playlist",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 150,
    },
    {
        title: "Tỉ lệ Drop (%)",
        cKey: "drop_percent",
        dataIndex: "drop_percent",
        name: "drop_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Tỉ lệ xem hết (%)",
        cKey: "view_full_percent",
        dataIndex: "view_full_percent",
        name: "view_full_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Direct (%)",
        cKey: "direct_percent",
        dataIndex: "direct_percent",
        name: "direct_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "External (%)",
        cKey: "external_percent",
        dataIndex: "external_percent",
        name: "external_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Nguồn External",
        cKey: "external_source",
        dataIndex: "external_source",
        name: "external_source",
        editable: true,
        editType: "textarea",
        show: true,
        props: {},
        width: 300,
        render: (data) => (
            <Paragraph
                ellipsis={{ rows: 2, tooltip: data }}
                style={{ fontSize: "16px" }}
            >
                {data}
            </Paragraph>
        ),
    },
    {
        title: "Trang kênh (%)",
        cKey: "channel_percent",
        dataIndex: "channel_percent",
        name: "channel_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Notify (%)",
        cKey: "browser_percent",
        dataIndex: "notify_percent",
        name: "notify_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Browser (%)",
        cKey: "notify_percent",
        dataIndex: "browser_percent",
        name: "browser_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "View/h",
        cKey: "hour_view",
        dataIndex: "hour_view",
        name: "hour_view",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Ưu tiên",
        cKey: "priority",
        dataIndex: "priority",
        name: "priority",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Số lần chạy lặp",
        cKey: "loop_count",
        dataIndex: "loop_count",
        name: "loop_count",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Xem channel",
        cKey: "page_watch",
        dataIndex: "page_watch",
        name: "page_watch",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
            max: 1,
        },
        align: "center",
        width: 120,
        render: (data) => {
            if(data == 1) {
                return 'Có';
            } else if(data == 0) {
                return 'Không';
            } else {
                return '';
            }
        }
    },
    {
        title: "Max Like",
        cKey: "max_like",
        dataIndex: "max_like",
        name: "max_like",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Like/view (%)",
        cKey: "like_percent",
        dataIndex: "like_percent",
        name: "like_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Max Dislike",
        cKey: "max_dislike",
        dataIndex: "max_dislike",
        name: "max_dislike",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Dislike/view (%)",
        cKey: "like_percent",
        dataIndex: "like_percent",
        name: "like_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Max Sub",
        cKey: "max_sub",
        dataIndex: "max_sub",
        name: "max_sub",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Sub/view (%)",
        cKey: "subscribe_percent",
        dataIndex: "subscribe_percent",
        name: "subscribe_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Max Comment",
        cKey: "max_comment",
        dataIndex: "max_comment",
        name: "max_comment",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Comment/view (%)",
        cKey: "cmt_percent",
        dataIndex: "cmt_percent",
        name: "cmt_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Comment user khác/view (%)",
        cKey: "cmt_user_percent",
        dataIndex: "cmt_user_percent",
        name: "cmt_user_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 150,
    },
    {
        title: "Xem dạo trước (%)",
        cKey: "before_view_per",
        dataIndex: "before_view_per",
        name: "before_view_per",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Xem dạo sau (%)",
        cKey: "after_view_per",
        dataIndex: "after_view_per",
        name: "after_view_per",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Time xem Ads (%)",
        cKey: "duration_view_ads",
        dataIndex: "duration_view_ads",
        name: "duration_view_ads",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Tỉ lệ Skip Ads (%)",
        cKey: "skip_ads_percent",
        dataIndex: "skip_ads_percent",
        name: "skip_ads_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Tỉ lệ Click Ads (%)",
        cKey: "click_ads_percent",
        dataIndex: "click_ads_percent",
        name: "click_ads_percent",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 120,
    },
    {
        title: "Time xem Ads trên trang đích (phút)",
        cKey: "view_page_ads",
        dataIndex: "view_page_ads",
        name: "view_page_ads",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        align: "center",
        width: 180,
    },
    {
        title: "Enable",
        cKey: "enable",
        dataIndex: "enable",
        name: "enable",
        editable: true,
        editType: "switch",
        show: true,
        props: {},
        align: "center",
        width: 120,
        render: (data) => <Switch checked={data && Number(data) === 1} />,
    },
    {
        title: "Thời gian tạo",
        cKey: "created_time",
        dataIndex: "created_time",
        name: "created_time",
        editable: false,
        show: true,
        width: "170px",
        render: (data) => (data ? dayjs(data).format("DD/MM/YYYY HH:mm") : ""),
    },
    {
        title: "Thời gian cập nhật",
        cKey: "updated_time",
        dataIndex: "updated_time",
        name: "updated_time",
        editable: false,
        show: true,
        width: "170px",
        render: (data) => (data ? dayjs(data).format("DD/MM/YYYY HH:mm") : ""),
    },
];
