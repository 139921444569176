import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/group-users";
        return axiosClient.get(url, { params });
    },
    getList: (params) => {
        const url = "/api/group-users/list";
        return axiosClient.get(url, { params });
    },
    get: (id) => {
        const url = `/api/group-users/${id}`;
        return axiosClient.get(url);
    },
    create: (payload) => {
        const url = `/api/group-users`;
        return axiosClient.post(url, payload);
    },
    update: (payload) => {
        const url = `/api/group-users/${payload.id}`;
        return axiosClient.put(url, payload);
    },
    delete: (id) => {
        const url = `/api/group-users/${id}`;
        return axiosClient.delete(url);
    },
    deleteMany: (ids) => {
        const url = `/api/group-users/delete-many`;
        return axiosClient.post(url, ids);
    },
    getComments: (id) => {
        const url = `/api/group-users/${id}/comments`;
        return axiosClient.get(url);
    },
    importComments: (id, comments) => {
        const url = `/api/group-users/${id}/comments`;
        return axiosClient.post(url, comments);
    },
    deleteComment: (id, cId) => {
        const url = `/api/group-users/${id}/comments/${cId}`;
        return axiosClient.delete(url);
    },
    deleteComments: (id) => {
        const url = `/api/group-users/${id}/comments`;
        return axiosClient.delete(url);
    },
    getChannels: (id) => {
        const url = `/api/group-users/${id}/channels`;
        return axiosClient.get(url);
    },
    importChannels: (id, channels) => {
        const url = `/api/group-users/${id}/channels`;
        return axiosClient.post(url, channels);
    },
    deleteChannel: (id, cId) => {
        const url = `/api/group-users/${id}/channels/${cId}`;
        return axiosClient.delete(url);
    },
    deleteChannels: (id) => {
        const url = `/api/group-users/${id}/channels`;
        return axiosClient.delete(url);
    },
    getConfigApps: (id) => {
        const url = `/api/group-users/${id}/config-apps`;
        return axiosClient.get(url);
    },
    createConfigApps: (id, config) => {
        const url = `/api/group-users/${id}/config-apps`;
        return axiosClient.post(url, config);
    },
    updateConfigApps: (id, config) => {
        const url = `/api/group-users/${id}/config-apps/${config.id}`;
        return axiosClient.put(url, config);
    },
    deleteConfigApps: (id, config) => {
        const url = `/api/group-users/${id}/config-apps/${config.id}`;
        return axiosClient.delete(url);
    },
};
