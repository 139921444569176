import {
    Button,
    Input,
    Row,
    Select,
    Space,
    Tag,
    Table,
    Typography,
} from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter } from "utils/constants";

import {
    PauseCircleOutlined,
    PlayCircleOutlined,
    UploadOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment/moment";
import { toast } from "react-toastify";
import proxyApi from "../proxy/api";
import * as proxyAction from "./slice";
import ImportModal from "./components/ImportModal";
import MoveGroupModal from "./components/MoveGroupModal";

function Proxy() {
    const dispatch = useDispatch();
    const { proxys, pagination, isLoading, isProcessing } = useSelector(
        (state) => state.proxy
    );

    const [searchServer, setSearchServer] = useState("");
    const [searchCountry, setSearchCountry] = useState("");
    const [searchType, setSearchType] = useState("");
    const [searchEnable, setSearchEnable] = useState("");
    const [searchGroup, setSearchGroup] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    //const [selectedProxy, setSelectedProxy] = useState(null);
    const [group, setGroup] = useState([]);
    const [countries, setCountries] = useState([]);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [dataImport, setDataImport] = useState([]);
    const [openMoveGroupModal, setOpenMoveGroupModal] = useState(false);

    useEffect(() => {
        dispatch(proxyAction.fetchProxys(pagination));

        const fetchListFilter = async () => {
            try {
                const listGroup = await proxyApi.getListGroup();
                const filterProxy = filter.proxy;
                if (listGroup.error) {
                    toast.error(listGroup.error);
                } else {
                    setGroup(listGroup);
                    setCountries(filterProxy.country);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };
        fetchListFilter();
    }, []);

    // useEffect(() => {
    //       if (isCompleted) {
    //         setSelectedProxy(null);
    //     }
    // }, [isCompleted]);

    const onChangeServerSearch = (e) => {
        setSearchServer(e.target.value);
    };

    const onSelectCountrySearch = (country) => {
        setSearchCountry(country);
        dispatch(
            proxyAction.fetchProxys({
                ...pagination,
                server: searchServer,
                country: country,
                type: searchType,
                enable: searchEnable,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const onSelectTypeSearch = (type) => {
        setSearchType(type);
        dispatch(
            proxyAction.fetchProxys({
                ...pagination,
                server: searchServer,
                country: searchCountry,
                type: type,
                enable: searchEnable,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const onSelectEnableSearch = (enable) => {
        setSearchEnable(enable);
        dispatch(
            proxyAction.fetchProxys({
                ...pagination,
                server: searchServer,
                country: searchCountry,
                type: searchType,
                enable: enable,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const onSelectGroupSearch = (group_id) => {
        setSearchGroup(group_id);
        dispatch(
            proxyAction.fetchProxys({
                ...pagination,
                server: searchServer,
                country: searchCountry,
                type: searchType,
                enable: searchEnable,
                group_id: group_id,
                current: 1,
            })
        );
    };

    const onSearch = (searchServer) => {
        dispatch(
            proxyAction.fetchProxys({
                ...pagination,
                server: searchServer,
                country: searchCountry,
                type: searchType,
                enable: searchEnable,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const filterOption = (input, option) => {
        return option.children.includes(input)
    }

    const onTableChange = async (pagination) => {
        dispatch(
            proxyAction.fetchProxys({
                ...pagination,
                server: searchServer,
                country: searchCountry,
                type: searchType,
                enable: searchEnable,
                group_id: searchGroup,
            })
        );
    };

    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const onCloseImportModal = () => {
        setOpenImportModal(false);
        dispatch(proxyAction.setIsProcessing(false));
    };

    const onImport = (dataImport) => {
        setDataImport(dataImport);
        dispatch(proxyAction.insertProxy(dataImport));
        setOpenImportModal(false);
        // dispatch(
        //     simInfoAction.fetchSimInfos({
        //         ...pagination,
        //         current: 1,
        //     })
        // );
    };

    const onRunAllSeleted = () => {
        dispatch(proxyAction.updateEnable(selectedRows, 1));
    };

    const onStopAllSeleted = () => {
        dispatch(proxyAction.updateEnable(selectedRows, 0));
    };

    const onOpenMoveGroupModal = () => {
        setOpenMoveGroupModal(true);
    };

    const onCloseMoveGroupModal = () => {
        setOpenMoveGroupModal(false);
        dispatch(proxyAction.setIsProcessing(false));
    };

    const onConvertOptions = () => {
        const arr = group.map((e) => {
            return { value: e.id, label: e.name };
        });
        return arr;
    };

    const onMoveGroup = (group) => {
        dispatch(proxyAction.moveToGroup(selectedRows, group));
        onCloseMoveGroupModal();
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "95px",
            fixed: "left",
        },
        {
            title: "Server",
            dataIndex: "server",
            fixed: "left",
            width: "200px",
        },
        {
            title: "User Name",
            dataIndex: "username",
            width: "200px",
        },
        {
            title: "Password",
            dataIndex: "password",
            width: "150px",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Host",
            dataIndex: "vm_id",
            width: "100px",
        },
        {
            title: "Port",
            dataIndex: "port",
            width: "100px",
        },
        {
            title: "Kiểu proxy",
            dataIndex: "type",
            width: "120px",
        },
        {
            title: "Device ID",
            dataIndex: "deviceId",
            width: "110px",
        },
        {
            title: "Profiles",
            dataIndex: "profiles",
            width: "90px",
        },
        {
            title: "Nhóm",
            dataIndex: "gr_name",
            width: "240px",
        },
        {
            title: "Country",
            dataIndex: "country",
            width: "100px",
        },
        {
            title: "Enable",
            dataIndex: "enable",
            width: "100px",
            render: (enable) =>
                enable == "1" ? (
                    <Tag color="warning">Enable</Tag>
                ) : (
                    <Tag>Disable</Tag>
                ),
        },
        {
            title: "Ngày tạo",
            dataIndex: "create_time",
            width: "160px",

            render: (data) => {
                return data === null
                    ? ""
                    : moment(data).format("DD/MM/YYYY HH:mm");
            },
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "update_time",
            width: "160px",

            render: (data) => {
                return data === null
                    ? ""
                    : moment(data).format("DD/MM/YYYY HH:mm");
            },
        },
    ];

    const rowSelection = {
        onChange: (_) => {
            setSelectedRows(_); // lấy mảng ids
            //setSelectedRows(selectedRows); // lấy mảng obj
        },
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>QUẢN LÝ PROXY</Typography.Title>
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => onOpenImportModal()}
                    >
                        Import
                    </Button>
                </Space>
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                {selectedRows.length ? (
                    <Space size="middle">
                        <Button
                            type="primary"
                            icon={<PauseCircleOutlined />}
                            onClick={onOpenMoveGroupModal}
                        >
                            Chuyển nhóm tất cả
                        </Button>
                        <Button
                            type="primary"
                            icon={<PlayCircleOutlined />}
                            onClick={() => onRunAllSeleted()}
                        >
                            Chạy tất cả
                        </Button>
                        <Button
                            type="primary"
                            icon={<PauseCircleOutlined />}
                            className="bg-amber-400"
                            onClick={() => onStopAllSeleted()}
                        >
                            Dừng tất cả
                        </Button>
                        <Button type="primary" danger icon={<DeleteOutlined />}>
                            Xóa tất cả
                        </Button>
                    </Space>
                ) : (
                    <>&nbsp;</>
                )}
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 120 }}
                        placeholder="Country"
                        onChange={onSelectCountrySearch}
                    >
                        {countries.map((e) => (
                            <Select.Option key={e} value={e}>
                                {e}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 120 }}
                        placeholder="Kiểu proxy"
                        onChange={onSelectTypeSearch}
                    >
                        <Select.Option key="ROTATE" value="ROTATE">
                            ROTATE
                        </Select.Option>
                        <Select.Option key="STATIC" value="STATIC">
                            STATIC
                        </Select.Option>
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 120 }}
                        placeholder="Bật/Tắt"
                        onChange={onSelectEnableSearch}
                    >
                        <Select.Option key="1" value="1">
                            Enable
                        </Select.Option>
                        <Select.Option key="0" value="0">
                            Disable
                        </Select.Option>
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Nhóm"
                        onChange={onSelectGroupSearch}
                        filterOption={filterOption}
                    >
                        {group.map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Input.Search
                        placeholder="Tìm kiếm"
                        onChange={onChangeServerSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            <Table
                bordered
                loading={isLoading}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={proxys}
                rowKey={(record) => record.id}
                pagination={pagination}
                scroll={{ x: "100%", y: 'calc(100vh - 460px)' }}
                onChange={onTableChange}
            />
            {openImportModal && (
                <ImportModal
                    open={openImportModal}
                    isProcessing={isProcessing}
                    onSubmit={onImport}
                    onCancel={onCloseImportModal}
                    data={dataImport}
                />
            )}
            {openMoveGroupModal && (
                <MoveGroupModal
                    open={openMoveGroupModal}
                    options={onConvertOptions()}
                    isProcessing={isProcessing}
                    onSubmit={onMoveGroup}
                    onCancel={onCloseMoveGroupModal}
                />
            )}
        </>
    );
}

export default Proxy;
