import {
    Button,
    Card,
    Col,
    Input,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    DownloadOutlined,
    HddOutlined,
    PauseCircleOutlined,
    PlayCircleOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import moment from "moment/moment";
import { toast } from "react-toastify";
import deviceInfoApi from "../device-info/api";
import * as deviceInfoAction from "./slice";
import ImportModal from "./components/ImportModal";

function DeviceInfo() {
    const dispatch = useDispatch();
    const {
        deviceInfos,
        summary,
        pagination,
        isProcessing,
        isLoading,
        isLoadingSummary,
    } = useSelector((state) => state.deviceInfo);

    const [searchModel, setSearchModel] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [models, setModels] = useState([]);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [dataImport, setDataImport] = useState([]);

    useEffect(() => {
        dispatch(deviceInfoAction.fetchDeviceInfos(pagination));
        dispatch(deviceInfoAction.fetchDeviceInfoSummary());

        const fetchListModel = async () => {
            try {
                const listModel = await deviceInfoApi.getListModel();
                if (listModel.error) {
                    toast.error(listModel.error);
                } else {
                    setModels(listModel);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };
        fetchListModel();
    }, []);

    const onSelectModelSearch = (model) => {
        setSearchModel(model);
        dispatch(
            deviceInfoAction.fetchDeviceInfos({
                ...pagination,
                model: model,
                status: searchStatus,
                current: 1,
            })
        );
    };

    const onSelectStatusSearch = (status) => {
        setSearchStatus(status);
        dispatch(
            deviceInfoAction.fetchDeviceInfos({
                ...pagination,
                model: searchModel,
                status: status,
                current: 1,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            deviceInfoAction.fetchDeviceInfos({
                ...pagination,
                model: searchModel,
                status: searchStatus,
            })
        );
    };

    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const onCloseImportModal = () => {
        setOpenImportModal(false);
        dispatch(deviceInfoAction.setIsProcessing(false));
    };

    const onImport = (dataImport) => {
        setDataImport(dataImport);
        dispatch(deviceInfoAction.insertDeviceInfo(dataImport));
        setOpenImportModal(false);
        // dispatch(
        //     deviceInfoAction.fetchSimInfos({
        //         ...pagination,
        //         current: 1,
        //     })
        // );
    };

    const onExport = () => {
        dispatch(deviceInfoAction.exportDeviceInfo());
    };

    const columns = [
        {
            title: "#",
            width: "25px",
            fixed: "left",
            render: (_, __, index) => {
                return index + 1;
            }
        },
        {
            title: "Model",
            dataIndex: "model",
            fixed: "left",
            width: "100px",
        },
        {
            title: "Total",
            dataIndex: "total",
            fixed: "left",
            width: "100px",
        },
        {
            title: "New",
            dataIndex: "_NEW",
            fixed: "left",
            width: "100px",
        },
        {
            title: "Used",
            dataIndex: "_USED",
            fixed: "left",
            width: "100px",
        },
        {
            title: "Setting",
            dataIndex: "_SETTING",
            fixed: "left",
            width: "100px",
        },

        
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    QUẢN LÝ THÔNG TIN FAKE DEVICES
                </Typography.Title>
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => onOpenImportModal()}
                    >
                        Import
                    </Button>
                    <Button
                        className="bg-green-500"
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => onExport()}
                    >
                        Export
                    </Button>
                </Space>
            </Row>

            <br />
            <Row gutter={12} justify="start">
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Tổng số thông tin fake"
                            value={summary.total}
                            prefix={<HddOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Số thông tin đang sử dụng"
                            value={summary.used}
                            prefix={<PlayCircleOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Số thông tin chưa sử dụng"
                            value={summary.neww}
                            prefix={<PauseCircleOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Số thông tin đang setting"
                            value={summary.setting}
                            prefix={<PauseCircleOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                <>&nbsp;</>
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Trạng thái"
                        onChange={onSelectStatusSearch}
                    >
                        <Select.Option key="NEW" value="NEW">
                            NEW
                        </Select.Option>
                        <Select.Option key="USED" value="USED">
                            USED
                        </Select.Option>
                        <Select.Option key="SETTING" value="SETTING">
                            SETTING
                        </Select.Option>
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Dòng máy"
                        onChange={onSelectModelSearch}
                    >
                        {models.map((e) => (
                            <Select.Option key={e.model} value={e.model}>
                                {e.model}
                            </Select.Option>
                        ))}
                    </Select>
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={deviceInfos}
                rowKey={(record) => record.model}
                pagination={pagination}
                scroll={{ x: "100%", y: 'calc(100vh - 600px)' }}
                onChange={onTableChange}
            />
            {openImportModal && (
                <ImportModal
                    open={openImportModal}
                    isProcessing={isProcessing}
                    onSubmit={onImport}
                    onCancel={onCloseImportModal}
                    data={dataImport}
                />
            )}
        </>
    );
}

export default DeviceInfo;
