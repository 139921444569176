import { UploadOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { Button, Col, Row, Table, Upload, Modal, Select } from "antd";
import { randomRanger } from "utils/common";

const { confirm } = Modal;

function DeleteModal(props) {
    const { open, onSubmit, onCancel, isProcessing, data: _data } = props;
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const showConfirm = (ids) => {
        confirm({
            title: "Cánh báo!!",
            icon: <ExclamationCircleFilled />,
            content: `Bạn chắc chắn muốn xóa tất cả kênh trong file CSV?`,
            onOk() {
                onSubmit(ids);
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const onOK = () => {
        const ids = dataImport.map((obj) => obj.id);
        showConfirm(ids);
    };

    const columns = [
        {
            title: "Id",
            dataIndex: "id",
            fixed: "left",
            width: "80px",
        },
        {
            title: "Tên Kênh",
            dataIndex: "name",
            fixed: "left",
            width: "250px",
        },
        {
            title: "Id Kênh",
            dataIndex: "channel_id",
            width: "250px",
        },
        {
            title: "Nhóm",
            dataIndex: "group_id",
            width: "100px",
        },
    ];

    return (
        <Modal
            title="Xóa kênh bằng file csv (xóa bằng id)"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="24">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    <br />
                    <br />
                    {showPreviewTable && (
                        <Table
                            bordered
                            columns={columns}
                            dataSource={dataImport}
                            scroll={{ x: "100%" }}
                            rowKey={() => Date.now() + randomRanger(0, 10000)}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default DeleteModal;
