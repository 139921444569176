import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout, Private } from "components";

import Login from "features/auth/login";
import Dashboard from "features/dashboard";
import ConfigProject from "features/project";
import ConfigGroup from "features/group-user";
import ConfigPlan from "features/plan";
import ConfigPlaylist from "features/playlist";
import ConfigHost from "features/host";
import ConfigDeviceInfo from "features/device-info";
import ConfigSimInfo from "features/sim-info";
import ConfigProfile from "features/profile";
import ConfigProxy from "features/proxy";
import ConfigChannel from "features/channel";
import ReportPlan from "features/report-plan";
import ReportPlaylist from "features/report-playlist";
import ConfigDevice from "features/device";
import ReportProfile from "features/report-profile";
import ConfigComment from "features/comment";

import ClicksManage from "features/clicks/manage";
import ClicksReport from "features/clicks/report";


export const routers = {
    dashboard: "",
    login: "login",
    config: {
        index: "config",
        project: "project",
        plan: "plan",
        playlist: "playlist",
        channel: "channel",
        group: "group-user",
        profile: "profile",
        host: "host",
        device: "device",
        deviceInfo: "device-info",
        simInfo: "sim-info",
        proxy: "proxy",
        comment: "comment",
    },
    report: {
        index: "report",
        plan: "plan",
        channel: "channel",
        playlist: "playlist",
        profile: "profile",
        scrip: "scrip",
    },
    clicks: {
        index: "clicks",
        manage: "manage",
        report: "report",
    }
};

const Routers = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route index element={<Private component={Dashboard} />} />
                    <Route path={routers.config.index}>
                        <Route
                            path={routers.config.project}
                            element={<Private component={ConfigProject} />}
                        />
                        <Route
                            path={routers.config.group}
                            element={<Private component={ConfigGroup} />}
                        />
                        <Route
                            path={routers.config.plan}
                            element={<Private component={ConfigPlan} />}
                        />
                        <Route
                            path={routers.config.playlist}
                            element={<Private component={ConfigPlaylist} />}
                        />
                        <Route
                            path={routers.config.channel}
                            element={<Private component={ConfigChannel} />}
                        />
                        <Route
                            path={routers.config.host}
                            element={<Private component={ConfigHost} />}
                        />
                        <Route
                            path={routers.config.deviceInfo}
                            element={<Private component={ConfigDeviceInfo} />}
                        />
                        <Route
                            path={routers.config.simInfo}
                            element={<Private component={ConfigSimInfo} />}
                        />
                        <Route
                            path={routers.config.profile}
                            element={<Private component={ConfigProfile} />}
                        />
                        <Route
                            path={routers.config.proxy}
                            element={<Private component={ConfigProxy} />}
                        />
                        <Route
                            path={routers.config.device}
                            element={<Private component={ConfigDevice} />}
                        />
                        <Route
                            path={routers.config.comment}
                            element={<Private component={ConfigComment} />}
                        />
                    </Route>
                    <Route path={routers.report.index}>
                        <Route
                            path={routers.report.plan}
                            element={<Private component={ReportPlan} />}
                        />
                        <Route
                            path={routers.report.playlist}
                            element={<Private component={ReportPlaylist} />}
                        />
                        <Route
                            path={routers.report.profile}
                            element={<Private component={ReportProfile} />}
                        />
                    </Route>
                    <Route path={routers.clicks.index}>
                        <Route
                            path={routers.clicks.manage}
                            element={<Private component={ClicksManage} />}
                        />
                        <Route
                            path={routers.clicks.report}
                            element={<Private component={ClicksReport} />}
                        />
                    </Route>
                </Route>
                <Route path={routers.login} element={<Login />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Routers;
