import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    projects: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "project",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setProjects: (state, action) => {
            state.projects = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addProject: (state, action) => {
            state.isCompleted = true;
            state.projects.unshift(action.payload);
        },
        editProject: (state, action) => {
            state.isCompleted = true;
            state.projects = state.projects.map((project) =>
                project.id === action.payload.id ? action.payload : project
            );
        },
        removeProject: (state, action) => {
            state.isCompleted = true;
            state.projects = state.projects.filter(
                (project) => project.id !== action.payload
            );
        },
        removeProjects: (state, action) => {
            state.isCompleted = true;
            state.projects = state.projects.filter(
                (project) => !action.payload.includes(project.id)
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setProjects,
    setPagination,
    addProject,
    editProject,
    removeProject,
    removeProjects,
} = slice.actions;

export const fetchProjects = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setProjects(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const createProject = (project) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.create(project);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(addProject(data));
                toast.success("Thêm project mới thành công!");
            }
        } catch (err) {
            console.error(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const updateProject = (project) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.update(project);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editProject(data));
                toast.success("Cập nhật project thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deleteProject = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.delete(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removeProject(id));
                toast.success("Xóa project thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deleteProjects = (ids) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.deleteMany(ids);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removeProjects(ids));
                toast.success(`Xóa ${ids.length} project đã chọn thành công!`);
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
