import {
    ExperimentOutlined,
    RocketOutlined,
    SettingOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import { formItemLayoutHorizontal } from "utils/constants";

const {
    Form,
    Modal,
    Input,
    Select,
    Tabs,
    Switch,
    InputNumber,
    Segmented,
} = require("antd");

const _formItemLayoutHorizontal = {
    ...formItemLayoutHorizontal,
    colon: false,
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
    },
};

function GroupUserDetailModal(props) {
    const [form] = Form.useForm();
    const {
        open,
        isProcessing,
        onSubmit,
        onCancel,
        onConfigKeywords,
        onConfigHobbies,
        onConfigApp,
        groupUser,
        groupUsers,
        projects,
        keywords,
        hobbies,
    } = props;
    const [phase, setPhase] = useState(groupUser?.phase || "feed");

    useEffect(() => {
        if (open) {
            form.setFieldValue("hobbies", hobbies);
        }
    }, [hobbies]);

    useEffect(() => {
        if (open) {
            form.setFieldValue("keywords", keywords);
        }
    }, [keywords]);

    const filterOption = (input, option) => {
        return option.children.includes(input)
    }

    const initialValues = groupUser
        ? {
              ...groupUser,
              priority: groupUser.priority || 0,
          }
        : {
              name: "",
              description: "",
              country: "",
              phase: "feed",
              service: null,
              next_group: null,
              project_id: null,
              priority: 0,
              num_days: 0,
              num_hours: 0,
              keywords: keywords,
              hobbies: hobbies || "{}",
          };

    const onOK = () => {
        form.validateFields()
            .then((values) => {
                onSubmit({ ...initialValues, ...values });
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const onChangePhase = (phase) => setPhase(phase);

    return (
        <Modal
            title="GROUP USER"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            // width="560px"
        >
            <Form
                {..._formItemLayoutHorizontal}
                labelWrap
                form={form}
                initialValues={initialValues}
            >
                <Form.Item
                    name="name"
                    label="Tên nhóm"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Input placeholder="Tên groupUser" />
                </Form.Item>
                <Form.Item name="description" label="Mô tả">
                    <Input.TextArea placeholder="Mô tả" rows={2} />
                </Form.Item>
                <Form.Item
                    name="service"
                    label="Service"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        // style={{ width: 200 }}
                        placeholder="Chọn service"
                    >
                        <Select.Option key={"GOOGLE"} value={"GOOGLE"}>
                            GOOGLE
                        </Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="country" label="Quốc gia">
                    <Input placeholder="Nhóm quốc giá" />
                </Form.Item>
                <Form.Item
                    name="project_id"
                    label="Project"
                    rules={[
                        {
                            required: true,
                            message: "Trường này là bắt buộc",
                        },
                    ]}
                >
                    <Select
                        allowClear
                        showSearch
                        // style={{ width: 200 }}
                        placeholder="Chọn project"
                    >
                        {projects.map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="next_group" label="Auto chuyển nhóm">
                    <Select
                        allowClear
                        showSearch
                        // style={{ width: 200 }}
                        placeholder="Chọn nhóm"
                        filterOption={filterOption}
                    >
                        {groupUsers.map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="phase" label="Phase">
                    <Segmented
                        options={[
                            {
                                label: "Feed",
                                value: "feed",
                                icon: <ExperimentOutlined />,
                            },
                            {
                                label: "Seeding",
                                value: "seeding",
                                icon: <RocketOutlined />,
                            },
                        ]}
                        onChange={onChangePhase}
                    />
                </Form.Item>
                <Form.Item name="priority" label="Độ ưu tiên">
                    <InputNumber
                        placeholder="Độ ưu tiên"
                        min={0}
                        style={{ width: "170px" }}
                    />
                </Form.Item>
                {phase === "feed" ? (
                    <>
                        <Form.Item name="num_days" label="Số ngày nuôi">
                            <InputNumber
                                placeholder="Số ngày nuôi"
                                min={0}
                                style={{ width: "170px" }}
                            />
                        </Form.Item>
                        <Form.Item name="num_hours" label="Số giờ nuôi">
                            <InputNumber
                                placeholder="Số giờ nuôi"
                                min={0}
                                style={{ width: "170px" }}
                            />
                        </Form.Item>
                        <Form.Item name="keywords" label="Từ khóa">
                            <div
                                onClick={() => onConfigKeywords(groupUser)}
                                style={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                    color: "#1890ff",
                                    margin: "4px 8px",
                                }}
                            >
                                <SettingOutlined /> Cấu hình từ khóa
                            </div>
                        </Form.Item>
                        <Form.Item name="hobbies" label="Sở thích">
                            <div
                                onClick={() => onConfigHobbies(groupUser)}
                                style={{
                                    display: "inline-block",
                                    cursor: "pointer",
                                    color: "#1890ff",
                                    margin: "4px 8px",
                                }}
                            >
                                <SettingOutlined /> Cấu hình sở thích
                            </div>
                        </Form.Item>
                        {groupUser && (
                            <>
                                <Form.Item name="appConfig" label="App nuôi">
                                    <div
                                        onClick={() => onConfigApp(groupUser)}
                                        style={{
                                            display: "inline-block",
                                            cursor: "pointer",
                                            color: "#1890ff",
                                            margin: "4px 8px",
                                        }}
                                    >
                                        <SettingOutlined /> Cấu hình app nuôi
                                    </div>
                                </Form.Item>
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <Form.Item name="num_days" label="Số ngày MKT">
                            <InputNumber
                                placeholder="Số ngày MKT"
                                min={0}
                                style={{ width: "170px" }}
                            />
                        </Form.Item>
                        <Form.Item name="num_hours" label="Số giờ chạy">
                            <InputNumber
                                placeholder="Số giờ chạy"
                                min={0}
                                style={{ width: "170px" }}
                            />
                        </Form.Item>
                        {groupUser && (
                            <>
                                <Form.Item name="appConfig" label="App chạy">
                                    <div
                                        onClick={() => onConfigApp(groupUser)}
                                        style={{
                                            display: "inline-block",
                                            cursor: "pointer",
                                            color: "#1890ff",
                                            margin: "4px 8px",
                                        }}
                                    >
                                        <SettingOutlined /> Cấu hình app chạy
                                    </div>
                                </Form.Item>
                            </>
                        )}
                    </>
                )}
            </Form>
        </Modal>
    );
}

export default GroupUserDetailModal;
