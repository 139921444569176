import { DownOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Dropdown, Space } from "antd";

export default function Actions({ record, onEdit }) {
    const KEY_ACTIONS = {
        EDIT: "edit",
    };

    const onClick = ({ key }) => {
        switch (key) {
            case KEY_ACTIONS.EDIT:
                onEdit(record);
                break;
            default:
                break;
        }
    };

    const actions = [
        {
            key: KEY_ACTIONS.EDIT,
            label: "Sửa",
            icon: <EditOutlined />,
        },
    ];

    return (
        <Dropdown menu={{ items: actions, onClick }}>
            <Button>
                <Space>
                    Thao tác
                    <DownOutlined />
                </Space>
            </Button>
        </Dropdown>
    );
}
