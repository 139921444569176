import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Spin, Table, Tag, Typography, Upload } from "antd";
import * as csvtojson from "csvtojson";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../api";
import { randomRanger } from "utils/common";

const { Paragraph } = Typography;

function ConfigChannelsModal(props) {
    const { open, onCancel, isProcessing, group } = props;
    const [channels, setChannels] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchChannels = async () => {
        setIsLoading(true);
        try {
            const data = await api.getChannels(group.id);
            setChannels(data);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (open && group) {
            fetchChannels();
        }
    }, [open]);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    const data = jsonObject
                        .filter((e) => !!e.name)
                        .map((channel) => ({
                            import: true,
                            id: randomRanger(1, 99999) + Date.now(),
                            name: channel.name,
                            channel_id: channel.channel_id,
                            url: channel.url,
                            keywords: channel.keywords,
                        }));

                    setChannels([...channels, ...data]);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            return false;
        },
    };

    const onRemoveChannel = (channel) => {
        console.log(channel);
        if (channel.import) {
            setChannels(channels.filter((c) => c.id !== channel.id));
        } else {
            Modal.confirm({
                title: `Xác nhận xóa kênh`,
                content: `Bạn có chắc chắn muốn xóa kênh này?`,
                centered: true,
                onOk: async () => {
                    try {
                        await api.deleteChannel(group.id, channel.id);
                        setChannels(
                            channels.filter((c) => !c.id || c.id !== channel.id)
                        );
                        toast.success("Xóa kênh thành công!");
                    } catch (err) {
                        console.log(err);
                    }
                },
            });
        }
    };

    const onRemoveAllChannel = () => {
        if (!channels.filter((c) => !c.import).length) {
            setChannels([]);
        } else {
            Modal.confirm({
                title: `Xác nhận xóa tất cả kênh`,
                content: `Bạn có chắc chắn muốn xóa tất cả kênh của group này?`,
                centered: true,
                onOk: async () => {
                    try {
                        await api.deleteChannels(group.id);
                        setChannels([]);
                        toast.success("Xóa tất cả kênh thành công!");
                    } catch (err) {
                        console.log(err);
                    }
                },
            });
        }
    };

    const onOK = async () => {
        setIsLoading(true);
        try {
            const importData = channels.filter((e) => e.import);
            if (importData.length) {
                await api.importChannels(group.id, importData);
                fetchChannels();
                toast.success("Cập nhật channel cho group thành công!");
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = [
        {
            title: "Name",
            dataIndex: "name",
            fixed: "left",
            width: "240px",
        },
        {
            title: "Channel ID",
            dataIndex: "channel_id",
            width: "240px",
        },
        {
            title: "URL",
            dataIndex: "url",
            width: "300px",
        },
        {
            title: "Keywords",
            dataIndex: "keywords",
            render: (data) => (
                <Paragraph
                    ellipsis={{ rows: 3, tooltip: data }}
                    style={{ fontSize: "16px" }}
                >
                    {data}
                </Paragraph>
            ),
        },
        {
            title: "Thao tác",
            align: "center",
            width: "150px",
            fixed: "right",
            render: (record) => (
                <Button danger={record.import}  size="small" onClick={() => onRemoveChannel(record)}>Xóa</Button>
            ),
        },
    ];

    return (
        <Modal
            title="Cấu hình kênh nuôi"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={1200}
        >
            <Spin spinning={isLoading} tip="Loading...">
                <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`/files/temp/group_channels_template.csv`}>
                        Download temp
                    </a>
                    <br />
                    <Row justify="space-between" align="middle">
                        <h3>
                            Danh sách kênh nuôi (
                            {channels.filter((e) => !e.import).length}):
                        </h3>
                        {channels.length ? (
                            <Button
                                size="small"
                                type="text"
                                danger
                                onClick={onRemoveAllChannel}
                            >
                                Xóa tất cả
                            </Button>
                        ) : null}
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={channels}
                        pagination={{ pageSize: 5 }}
                    />
                    {/* {channels.map((e) => (
                        <Tag
                            closeIcon
                            key={e.id}
                            onClose={(ev) => onRemoveChannel(ev, e)}
                            color={e.import ? "processing" : "default"}
                            style={{
                                fontSize: 14,
                                lineHeight: "14px",
                                padding: "4px 8px 6px",
                                marginBottom: "8px",
                            }}
                        >
                            {e.data}
                        </Tag>
                    ))} */}
                </div>
            </Spin>
        </Modal>
    );
}

export default ConfigChannelsModal;
