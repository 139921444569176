const { useDispatch, useSelector } = require("react-redux");
import {
    CalendarOutlined,
    CarryOutOutlined,
    DownOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import {
    Button,
    Checkbox,
    DatePicker,
    Divider,
    Dropdown,
    Input,
    Row,
    Segmented,
    Select,
    Space,
    Table,
    Typography,
    theme,
} from "antd";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { paginationParams } from "utils/constants";
import groupApi from "../group-user/api";
import projectApi from "../project/api";
import { COLUMNS } from "./columns";
import * as reportProfileAction from "./slice";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;

const { useToken } = theme;

const rangePresets = [
    {
        label: "Hôm nay",
        value: [dayjs(), dayjs()],
    },
    {
        label: "Hôm qua",
        value: [dayjs().add(-1, "d"), dayjs().add(-1, "d")],
    },
    {
        label: "Tuần này",
        value: [dayjs().startOf("week"), dayjs()],
    },
    {
        label: "Tháng này",
        value: [dayjs().startOf("month"), dayjs()],
    },
];

function ReportProfile() {
    const { token } = useToken();
    const dispatch = useDispatch();
    const { profiles, pagination, isLoading } = useSelector(
        (state) => state.reportProfile
    );
    const [selectedRows, setSelectedRows] = useState([]);
    const [openSelectApp, setOpenSelectApp] = useState(false);
    const [selectingApps, setSelectingApps] = useState(
        localStorage.getItem("report_profile_show_apps")?.split(",") || []
    );
    const [selectedApps, setSelectedApps] = useState(selectingApps);
    const [projects, setProjects] = useState([]);
    const [groups, setGroups] = useState([]);
    const [searchValue, setSearchValue] = useState({
        status: null,
        state: null,
        project: null,
        group: null,
        profile: null,
        apps: selectingApps.join(","),
        range: [dayjs(), dayjs()],
        from: dayjs().format("YYYY-MM-DD"),
        to: dayjs().format("YYYY-MM-DD"),
    });
    const [searchState, setSearchState] = useState(searchValue);

    const columns = COLUMNS.filter(
        (col) => !col.children || selectedApps.includes(col.title)
    );

    const apps = [
        {
            key: "GMail",
            label: <Checkbox value={"GMail"}>GMail</Checkbox>,
        },
        {
            key: "GDrive",
            label: <Checkbox value={"GDrive"}>GDrive</Checkbox>,
        },
        {
            key: "GMap",
            label: <Checkbox value={"GMap"}>GMap</Checkbox>,
        },
        {
            key: "GStore",
            label: <Checkbox value={"GStore"}>GStore</Checkbox>,
        },
        {
            key: "GNews",
            label: <Checkbox value={"GNews"}>GNews</Checkbox>,
        },
        {
            key: "GChrome",
            label: <Checkbox value={"GChrome"}>GChrome</Checkbox>,
        },
        {
            key: "Youtube",
            label: <Checkbox value={"Youtube"}>Youtube</Checkbox>,
        },
    ];

    useEffect(() => {
        dispatch(
            reportProfileAction.fetchProfiles({ ...pagination, ...searchValue })
        );
        const fetchProjects = async () => {
            try {
                const projects = await projectApi.getAll();
                if (projects.error) {
                    toast.error(projects.error);
                } else {
                    setProjects(projects.data);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };
        const fetchGroups = async () => {
            try {
                const groups = await groupApi.getAll();
                if (groups.error) {
                    toast.error(groups.error);
                } else {
                    setGroups(groups.data);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };

        fetchProjects();
        fetchGroups();
    }, []);

    useEffect(() => {
        setSearchValue({ ...searchValue, apps: selectingApps.join(",") });
    }, [selectingApps]);

    const handleDropdownChange = () => {
        const close = !openSelectApp;
        if (close) setSelectingApps(selectedApps);
        setOpenSelectApp(close);
    };

    const handleChangeApp = () => {
        localStorage.setItem(
            "report_profile_show_apps",
            selectingApps.join(",")
        );
        setSelectedApps(selectingApps);
        setOpenSelectApp(false);
        dispatch(
            reportProfileAction.fetchProfiles({
                ...pagination,
                ...searchValue,
                apps: selectingApps.join(","),
            })
        );
    };

    const handleSelectApp = (apps) => setSelectingApps(apps);

    const onChangeSearchRange = (dates) => {
        setSearchValue((prev) => ({
            ...prev,
            range: dates,
            from: dates[0].format("YYYY-MM-DD"),
            to: dates[1].format("YYYY-MM-DD"),
        }));
    };

    const onChangeSearchValue = (property, value) => {
        setSearchValue((prev) => ({ ...prev, [property]: value }));
    };

    const onSearch = () => {
        setSearchState(searchValue);
        dispatch(
            reportProfileAction.fetchProfiles({
                ...pagination,
                ...searchValue,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            reportProfileAction.fetchProfiles({
                ...pagination,
                ...searchState,
            })
        );
    };

    const rowSelection = {
        onChange: (_, selectedRows) => {
            setSelectedRows(selectedRows);
        },
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>REPORT PROFILE</Typography.Title>
                <Space
                    size="middle"
                    style={{ paddingTop: "1.2rem", marginBottom: "0.9rem" }}
                >
                    <Checkbox.Group
                        onChange={handleSelectApp}
                        defaultValue={selectedApps}
                    >
                        <Dropdown
                            menu={{ items: apps }}
                            onOpenChange={handleDropdownChange}
                            open={openSelectApp}
                            trigger={["click"]}
                            dropdownRender={(menu) => (
                                <div
                                    style={{
                                        backgroundColor: token.colorBgElevated,
                                        borderRadius: token.borderRadiusLG,
                                        boxShadow: token.boxShadowSecondary,
                                    }}
                                >
                                    {React.cloneElement(menu, {
                                        style: { boxShadow: "none" },
                                    })}
                                    <Divider
                                        style={{
                                            margin: 0,
                                        }}
                                    />
                                    <div
                                        style={{
                                            padding: 8,
                                        }}
                                    >
                                        <Button
                                            type="primary"
                                            block
                                            onClick={handleChangeApp}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </div>
                            )}
                        >
                            <Button>
                                <Space>
                                    App
                                    <DownOutlined />
                                </Space>
                            </Button>
                        </Dropdown>
                    </Checkbox.Group>
                    <RangePicker
                        presets={rangePresets}
                        value={searchValue.range}
                        onChange={onChangeSearchRange}
                    />
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 120 }}
                        placeholder="Status"
                        value={searchValue.status}
                        onChange={(value) =>
                            onChangeSearchValue("status", value)
                        }
                    >
                        <Select.Option key="all" value="">
                            ALL STATUS
                        </Select.Option>
                        {["CREATE", "RESTORE", "SYNCED", "ERROR", "VERIFY"].map(
                            (e) => (
                                <Select.Option
                                    key={e.toLowerCase()}
                                    value={e.toLowerCase()}
                                >
                                    {e}
                                </Select.Option>
                            )
                        )}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 120 }}
                        placeholder="State"
                        value={searchValue.state}
                        onChange={(value) =>
                            onChangeSearchValue("state", value)
                        }
                    >
                        <Select.Option key="all" value="">
                            ALL STATE
                        </Select.Option>
                        {["DONE", "RUNNING"].map((e) => (
                            <Select.Option
                                key={e.toLowerCase()}
                                value={e.toLowerCase()}
                            >
                                {e}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 160 }}
                        placeholder="Tìm theo project"
                        value={searchValue.project}
                        onChange={(value) =>
                            onChangeSearchValue("project", value)
                        }
                    >
                        <Select.Option key="all" value="">
                            ALL PROJECT
                        </Select.Option>
                        {projects.map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 160 }}
                        placeholder="Tìm theo group"
                        value={searchValue.group}
                        onChange={(value) =>
                            onChangeSearchValue("group", value)
                        }
                    >
                        <Select.Option key="all" value="">
                            ALL GROUP
                        </Select.Option>
                        {groups.map((e) => (
                            <Select.Option key={e.id} value={e.id}>
                                {e.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Input.Search
                        placeholder="Tìm kiếm profile"
                        value={searchValue.profile}
                        onChange={(e) =>
                            onChangeSearchValue("profile", e.target.value)
                        }
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button type="primary" icon={<DownloadOutlined />}>
                        Export
                    </Button>
                </Space>
            </Row>
            <br />
            {selectedRows.length ? (
                <Space size="middle" className="mb-4">
                    <Button type="primary" danger>
                        Xóa tất cả
                    </Button>
                </Space>
            ) : null}
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={profiles}
                rowKey={(record) => record.id}
                rowSelection={rowSelection}
                pagination={{
                    ...paginationParams,
                    ...pagination,
                }}
                scroll={{ x: "max-content", y: 500 }}
                sticky={{ offsetHeader: 75 }}
                onChange={onTableChange}
            />
        </>
    );
}

export default ReportProfile;
