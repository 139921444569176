import { UploadOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";

const {
    Button,
    Space,
    Card,
    Select,
    InputNumber,
    Upload,
    Row,
} = require("antd");
const { Option } = Select;

const APPS = [
    "GNews",
    "GMail",
    "GMap",
    "GDrive",
    "GStore",
    "GChrome",
    "Youtube",
];

const AppConfig = (props) => {
    const { group, apps, tab, onChangeAppName, onSaveConfig } = props;
    const [app, setApp] = useState(tab.label);
    const [appName, setAppName] = useState(tab.label.toLowerCase());
    const [percent, setPercent] = useState(tab.percent);
    const [config, setConfig] = useState(tab?.config || "{}");

    useEffect(() => {
        let _appName = app;
        if(_appName === 'Youtube') {
            _appName = `${app} ${group?.phase === 'feed' ? 'Feed' : 'Seeding'}`;
        }
        setAppName(_appName);
    }, [group, app]);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    setConfig(JSON.stringify(jsonObject[0], null, 2));
                })
                .catch((err) => {
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            return false;
        },
    };

    const onChangeApp = (app) => {
        setApp(app);
        onChangeAppName(tab.key, app || "New App");
    };

    const onChangePercent = (percent) => setPercent(percent);

    const handleSaveConfig = () => {
        if (percent === undefined || percent === null)
            toast.error(`Bạn chưa nhập tỉ lệ app ${app}`);
        else
            onSaveConfig({
                ...tab,
                name: app,
                percent,
                config,
            });
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Space>
                    <Select
                        value={app}
                        onChange={onChangeApp}
                        placeholder="Chọn app"
                        style={{ width: 180 }}
                    >
                        {APPS.map((app) => (
                            <Option
                                key={app}
                                value={app}
                                disabled={apps.includes(app)}
                            >
                                {app}
                            </Option>
                        ))}
                    </Select>
                    <InputNumber
                        type="text"
                        value={percent}
                        onChange={onChangePercent}
                        min={0}
                        max={100}
                        placeholder="Tỉ lệ"
                        style={{ width: 100 }}
                    />
                </Space>
                {app !== "New App" ? (
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>
                            Click to Upload Config
                        </Button>
                    </Upload>
                ) : null}
            </Row>
            {APPS.includes(app) && (
                <Row justify="end" style={{ marginTop: "8px" }}>
                    <a
                        href={`/files/temp/config_${appName
                            .toLowerCase()
                            .replace(/\s/gm, "_")}_template.csv`}
                    >
                        Download temp config {appName}
                    </a>
                </Row>
            )}
            <br />
            <Card
                style={{
                    minHeight: "200px",
                    maxHeight: "400px",
                    overflowY: "auto",
                }}
            >
                <pre>{config}</pre>
            </Card>
            <br />
            <Button
                type="primary"
                block
                size="large"
                disabled={!APPS.includes(app)}
                onClick={handleSaveConfig}
            >
                {`${tab.id ? "Cập nhật" : "Thêm"} config ${
                    APPS.includes(app) ? app : ""
                }`}
            </Button>
        </>
    );
};

export default AppConfig;
