import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    simInfos: [],
    summary: {},
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isLoadingSummary: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "simInfo",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingSummary: (state, action) => {
            state.isLoadingSummary = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setSimInfos: (state, action) => {
            state.simInfos = action.payload;
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingSummary,
    setIsCompleted,
    setIsProcessing,
    setSimInfos,
    setSummary,
    setPagination,
} = slice.actions;

export const fetchSimInfoSummary = () => {
    return async (dispatch) => {
        dispatch(setIsLoadingSummary(true));
        try {
            const data = await api.getSummary();
            dispatch(setSummary(data.data));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingSummary(false));
        }
    };
};
export const fetchSimInfos = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setSimInfos(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const insertSimInfo = (listSim) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.insert(listSim);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import danh sách sim thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchSimInfos({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const exportSimInfo = () => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.exportSim();
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                exp(data);
                toast.success("Đã export danh sách sim info");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchSimInfos({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

const exp = (data) => {
    try {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `sim_infos.csv`;
        link.click();
    } catch (error) {
        console.log(error);
    }
};

export default slice.reducer;
