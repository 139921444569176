import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { Button, Col, Row, Table, Upload, Modal, Typography } from "antd";

const { Paragraph } = Typography;

function ImportModal(props) {
    const { open, onSubmit, onCancel, isProcessing, data: _data } = props;
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const onOK = () => {
        onSubmit(dataImport);
    };

    const columns = [
        {
            title: "Tên kênh",
            dataIndex: "name",
            fixed: "left",
            width: "170px",
        },
        {
            title: "Kênh ID",
            dataIndex: "channel_id",
            fixed: "left",
            width: "170px",
        },
        {
            title: "Đường dẫn (url)",
            dataIndex: "url",
            width: "170px",
        },
        {
            title: "Id Nhóm",
            dataIndex: "group_id",
            width: "100px",
        },
        {
            title: "Keywords",
            dataIndex: "keywords",
            render: (data) => (
                <Paragraph
                    ellipsis={{ rows: 3, tooltip: data }}
                    style={{ fontSize: "16px" }}
                >
                    {data}
                </Paragraph>
            ),
        },
        {
            title: "Enable Sub",
            dataIndex: "enable_sub",
            width: "120px",
        },
    ];

    return (
        <Modal
            title="Nhập Danh Sách Kênh"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="24">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`/files/temp/channel_template.csv`}>
                        Download temp import channel
                    </a>
                    <br />
                    <br />
                    {showPreviewTable && (
                        <Table
                            scroll={{ x: "100%", y: 400 }}

                            columns={columns}
                            dataSource={dataImport}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default ImportModal;
