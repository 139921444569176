import { createSlice } from "@reduxjs/toolkit";
import api from "./api";

const initialState = {
    profiles: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
};

export const slice = createSlice({
    name: "reportProfile",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setProfiles: (state, action) => {
            state.profiles = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const { setIsLoading, setProfiles, setPagination } = slice.actions;

export const fetchProfiles = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getReport(params);
            dispatch(setProfiles(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export default slice.reducer;
