import { SyncOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Tag, Typography, List, Switch, Modal } from "antd";
import moment from "moment/moment";

const { confirm } = Modal;

const { Text } = Typography;

const onChangeUsp = (data) => {
    confirm({
        title: data == 1 ? "Sử dụng static proxy?" : "Sử dụng proxy xoay?",
        icon: <ExclamationCircleFilled />,
        onOk() {
            console.log("OK, call api update");
        },
        onCancel() {
            console.log("Cancel");
        },
    });
};

const onChangeSkipWatch = (data) => {
    confirm({
        title: data == 1 ? "Skip watch?" : "Tắt skip watch?",
        icon: <ExclamationCircleFilled />,
        onOk() {
            console.log("OK, call api update");
        },
        onCancel() {
            console.log("Cancel");
        },
    });
};

export const COLUMNS = [
    {
        title: "ID",
        cKey: "id",
        dataIndex: "id",
        editable: false,
        show: true,
        props: {},
        width: "100px",
        fixed: "left",
        render: (data) => <a>{data}</a>,
    },
    {
        title: "Địa chỉ IP",
        cKey: "ipAddr",
        dataIndex: "ipAddr",
        editable: false,
        show: true,
        props: {},
        width: "250px",
    },
    {
        title: "Host ID",
        cKey: "vm_id",
        dataIndex: "vm_id",
        editable: true,
        editType: "number",
        show: true,
        props: {},
        width: "130px",
    },
    {
        title: "Trạng thái",
        cKey: "status",
        dataIndex: "status",
        editable: false,
        show: true,
        props: {},
        width: "100px",
        align: "center",
        render: (data) => {
            if (data === "1") return <Tag color="green">On</Tag>;
            if (data === "0") return <Tag color="red">Off</Tag>;
            return (
                <Tag icon={<SyncOutlined spin />} color="gold">
                    ...
                </Tag>
            );
        },
    },
    {
        title: "Current User",
        cKey: "currentUser",
        dataIndex: "currentUser",
        editable: false,
        show: true,
        props: {},
        width: "100px",
    },

    {
        title: "Tổng số user",
        cKey: "numUsers",
        dataIndex: "numUsers",
        editable: false,
        show: true,
        props: {},
        width: "100px",
    },
    {
        title: "User Service",
        cKey: "user_service",
        editable: false,
        show: true,
        props: {},
        width: "140px",
        render: (record) => {
            return (
                <List>
                    <List.Item className="text-sm">
                        GOOGLE: {record._GOOGLE || 0}
                    </List.Item>
                    
                </List>
            );
        },
    },
    {
        title: "Nhóm",
        cKey: "group_id",
        name: "group_id",
        editable: true,
        editType: "select",
        show: true,
        props: {},
        width: 250,
        render: (record) => {
            return record.gr_name;
        },
    },
    {
        title: "Description",
        cKey: "descriptions",
        dataIndex: "descriptions",
        editable: true,
        editType: "text",
        show: true,
        props: {},
        width: 250,
    },
    {
        title: "Số user tối đa",
        cKey: "maxUser",
        dataIndex: "maxUser",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        width: "140px",
    },
    {
        title: "State",
        cKey: "state",
        dataIndex: "state",
        editable: true,
        editType: "select",
        show: true,
        props: {
            options: [],
        },
        width: "140px",
    },
    {
        title: "Phase",
        cKey: "phase",
        dataIndex: "phase",
        editable: true,
        editType: "select",
        show: true,
        props: {
            options: [],
        },
        width: "140px",
    },
    {
        title: "Activity",
        cKey: "activity",
        dataIndex: "activity",
        editable: true,
        editType: "text",
        show: true,
        props: {},
        width: "200px",
    },
    {
        title: "Thời gian / phiên",
        cKey: "time_per_ses",
        dataIndex: "time_per_ses",
        editable: true,
        editType: "number",
        show: true,
        props: {
            min: 0,
        },
        width: "125px",
    },
    {
        title: "Sử dụng static proxy",
        cKey: "use_static_proxy",
        dataIndex: "use_static_proxy",
        editable: false,
        show: true,
        align: "center",
        width: "130px",
        render: (data) => (
            <Switch defaultChecked={data} onChange={onChangeUsp} />
        ),
    },
    {
        title: "Skip watch",
        cKey: "skip_watch",
        dataIndex: "skip_watch",
        editable: false,
        show: true,
        align: "center",
        width: "130px",
        render: (data) => (
            <Switch defaultChecked={data} onChange={onChangeSkipWatch} />
        ),
    },
    {
        title: "Phiên bản Bypass",
        cKey: "version_bypass",
        dataIndex: "version_bypass",
        editable: true,
        editType: "text",
        show: true,
        props: {},
        width: "120px",
    },
    {
        title: "Nền tảng",
        cKey: "platform",
        dataIndex: "platform",
        editable: false,
        show: true,
        width: "130px",
    },
    {
        title: "Nhiệt độ",
        cKey: "temperature",
        dataIndex: "temperature",
        editable: false,
        show: true,
        align: "center",
        width: "100px",
        render: (data) => {
            if (data) {
                return (
                    <Tag
                        color={
                            data <= 50000
                                ? "green"
                                : data >= 80000
                                ? "red"
                                : "gold"
                        }
                    >
                        {data / 1000} °C
                    </Tag>
                );
            }
            return "";
        },
    },
    {
        title: "Dung lượng (GB)",
        cKey: "storage",
        dataIndex: "storage",
        editable: false,
        show: true,
        width: "160px",
        render: (data) => {
            if (data) {
                const arr = data.split("/");
                const use = Number(arr[0]) / 1024;
                const full = Number(arr[1]) / 1024;
                return (
                    <Text
                        className="text-base font-semibold"
                        type={
                            full - use < 1
                                ? "danger"
                                : full - use < 5
                                ? "warning"
                                : "success"
                        }
                    >
                        {use.toFixed(2)} / {full.toFixed(2)}
                    </Text>
                );
            } else {
                return "_";
            }
        },
    },
    {
        title: "Dòng máy",
        cKey: "model",
        dataIndex: "model",
        editable: false,
        show: true,
        align: "center",
        width: "115px",
    },
    {
        title: "Mã máy",
        cKey: "serialNo",
        dataIndex: "serialNo",
        editable: false,
        show: true,
        width: "200px",
    },
    {
        title: "Phiên bản",
        cKey: "code_version",
        dataIndex: "code_version",
        editable: false,
        show: true,
        align: "center",
        width: "110px",
    },
    {
        title: "Thời gian cập nhật",
        cKey: "time_update",
        dataIndex: "time_update",
        editable: false,
        show: true,
        width: "190px",
        render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
    },
];
