import {
    Button,
    Input,
    Card,
    Col,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Typography,
} from "antd";

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    PlayCircleOutlined,
    UploadOutlined,
    DownloadOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import moment from "moment/moment";
import * as channelAction from "./slice";
import ImportModal from "./components/ImportModal";
import DeleteModal from "./components/DeleteModal";

function Channel() {
    const dispatch = useDispatch();
    const {
        channels,
        listGroup,
        summary,
        pagination,
        isProcessing,
        isLoading,
        isLoadingListGroup,
    } = useSelector((state) => state.channel);

    const [searchVal, setSearchVal] = useState("");
    const [searchGroup, setSearchGroup] = useState("");
    const [openImportModal, setOpenImportModal] = useState(false);
    const [dataImport, setDataImport] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    useEffect(() => {
        dispatch(channelAction.fetchChannels(pagination));
        dispatch(channelAction.fetchListGroup(pagination));
    }, []);

    const onChangeValSearch = (e) => {
        setSearchVal(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            channelAction.fetchChannels({
                ...pagination,
                searchVal: searchVal,
                group_id: searchGroup,
                current: 1,
            })
        );
    };

    const onSelectGroup = (group_id) => {
        setSearchGroup(group_id);
        dispatch(
            channelAction.fetchChannels({
                ...pagination,
                searchVal: searchVal,
                group_id: group_id,
                current: 1,
            })
        );
    };

    const onTableChange = async (pagination) => {
        dispatch(
            channelAction.fetchChannels({
                ...pagination,
                group_id: searchGroup,
                searchVal: searchVal,

            })
        );
    };

    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const onCloseImportModal = () => {
        setOpenImportModal(false);
        dispatch(channelAction.setIsProcessing(false));
    };

    const onImport = (dataImport) => {
        setDataImport(dataImport);
        dispatch(channelAction.insertChannel(dataImport));
        setOpenImportModal(false);
        // dispatch(
        //     channelAction.fetchChannels({
        //         ...pagination,
        //         current: 1,
        //     })
        // );
    };

    const onExport = () => {
        dispatch(channelAction.exportChannel());
    };

    const onOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const onCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        dispatch(channelAction.setIsProcessing(false));
    };

    const onDelete = (dataImport) => {
        setDataImport(dataImport);
        dispatch(channelAction.deleteChannel(dataImport));
        setOpenDeleteModal(false);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "80px",
            fixed: "left",
        },
        {
            title: "ID Kênh",
            dataIndex: "channel_id",
            width: "300px",
            fixed: "left",
        },
        {
            title: "Tên Kênh",
            dataIndex: "name",
            width: "300px",
        },
        {
            title: "Url",
            dataIndex: "url",
            width: "250px",
        },
        {
            title: "Group",
            //dataIndex: "group_id",
            width: "200px",
            render: (record) => <a>{record.gr_name}</a>,
        },
        {
            title: "Từ khóa tìm kiếm",
            dataIndex: "keywords",
            width: "400px",
        },
        {
            title: "Enable Sub",
            dataIndex: "enable_sub",
            width: "140px",
        },
        {
            title: "Ngày tạo",
            dataIndex: "created_time",
            width: "180px",

            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
        {
            title: "Ngày cập nhật",
            dataIndex: "updated_time",
            width: "180px",

            render: (data) => moment(data).format("DD/MM/YYYY HH:mm"),
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>
                    QUẢN LÝ KÊNH
                </Typography.Title>
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => onOpenImportModal()}
                    >
                        Import
                    </Button>
                    <Button
                        className="bg-green-500"
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => onExport()}
                    >
                        Export
                    </Button>
                    <Button
                        className="bg-amber-500 "
                        type="primary"
                        icon={<DeleteOutlined />}
                        onClick={() => onOpenDeleteModal()}
                    >
                        Xóa
                    </Button>
                </Space>
            </Row>
            <br />
            <Row gutter={12} justify="start">
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Tổng số channel"
                            value={summary}
                            prefix={<PlayCircleOutlined />}
                            loading={isLoading}
                        />
                    </Card>
                </Col>
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                <>&nbsp;</>
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Nhóm"
                        onChange={onSelectGroup}
                        loading={isLoadingListGroup}
                    >
                        {listGroup.map((e) => (
                            <Select.Option key={e.group_id} value={e.group_id}>
                                {e.gr_name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Input.Search
                        placeholder="Tìm kiếm"
                        onChange={onChangeValSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            <Table
                bordered
                loading={isLoading}
                dataSource={channels.map((record, index) => ({...record, key: index + 1}))}
                columns={columns}
                rowKey={(record) => record.key}
                pagination={pagination}
                scroll={{ x: "100%", y: 'calc(100vh - 600px)' }}


                onChange={onTableChange}
            />
            {openImportModal && (
                <ImportModal
                    open={openImportModal}
                    isProcessing={isProcessing}
                    onSubmit={onImport}
                    onCancel={onCloseImportModal}
                    data={dataImport}
                />
            )}
            {openDeleteModal && (
                <DeleteModal
                    open={openDeleteModal}
                    isProcessing={isProcessing}
                    onSubmit={onDelete}
                    onCancel={onCloseDeleteModal}
                    data={dataImport}
                />
            )}
        </>
    );
}

export default Channel;
