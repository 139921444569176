/* eslint-disable no-undef */
export const API_PATH = process.env.REACT_APP_API_URL;
export const CDN_PATH = process.env.REACT_APP_CDN;

export const HTTP_METHODS = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    PATCH: "PATCH",
    DELETE: "DELETE",
};

export const formItemLayoutHorizontal = {
    // size: 'large',
    labelAlign: "right",
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

export const paginationParams = {
    pageSize: 10,
    showTitle: true,
    showSizeChanger: true,
    showTotal: (total, range) =>
        `Show ${range[0]}-${range[1]} of ${total} items`,
    pageSizeOptions: [10, 20, 50, 100, 500, 1000],
};

export const filter = {
    proxy: {
        country: ["US", "UK", "GB"],
    },
    simInfo: {
        country: ["US", "DE", "GB"],
    },
    profile: {
        service: ["GOOGLE", "TIKTOK"],
        platform: ["ANDROID", "IOS"],
        status: ["SYNCED", "CREATE", "RESTORE", "SYNCING", "VERIFY", "ERROR", "NEW", "ACTIVE"],
    },
    device: {
        state: [
            { value: "WORKING", label: "WORKING" },
            { value: "SLEEP", label: "SLEEP" },
            { value: "STOP", label: "STOP" },
            { value: "REBOOT", label: "REBOOT" },
            { value: "RESET_FACTORY", label: "RESET_FACTORY" },
            { value: "UPDATE_OS", label: "UPDATE_OS" },
            { value: "UPDATE_NETWORK", label: "UPDATE_NETWORK" },
            { value: "UPDATE_APP", label: "UPDATE_APP" },
            { value: "UPDATE_CONFIG", label: "UPDATE_CONFIG" },
        ],
        phase: [
            { value: "init", label: "Init" },
            { value: "feed", label: "Feed" },
            { value: "seeding", label: "Seeding" },
        ],
    },
};

export const KEY_ACTIONS_DEVICES_MANAGER = {
    MOVE_GROUP_ALL: 1,
    SET_HOST_ALL: 2,
    SET_STATE_ALL: 3,
    SET_PHASE_ALL: 4,
    SET_MAX_USER_ALL: 5,
    SET_ACTIVITY_ALL: 6,
    SET_TIME_PER_SESSION_ALL: 7,
    SET_USE_STATIC_PROXY_ALL: 8,
    SET_SKIP_WATCH_ALL: 9,
    SET_VERSION_BYPASSS_ALL: 10,
};
