import {
    DownloadOutlined,
    SaveOutlined,
    UploadOutlined,
} from "@ant-design/icons";
import {
    Button,
    Form,
    Input,
    InputNumber,
    Popconfirm,
    Row,
    Select,
    Space,
    Table,
    Typography,
    Card,
    Col,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter } from "utils/constants";
import { COLUMNS } from "./columns";
import { toast } from "react-toastify";
import Actions from "./components/Action";
import * as deviceAction from "./slice";
import deviceApi from "./api";
import ImportModal from "./components/ImportModal";
import ActionAll from "./components/ActionAll";

const { Option } = Select;

const inputNode = (inputType, inputProps) => {
    switch (inputType) {
        case "number":
            return <InputNumber {...inputProps} />;
        case "text":
            return <Input {...inputProps} />;
        case "select":
            return (
                <Select>
                    {inputProps?.options?.map((e, i) => (
                        <Option key={i} value={e.value}>
                            {e.label}
                        </Option>
                    ))}
                </Select>
            );
        default:
            return <></>;
    }
};

const EditableCell = (props) => {
    const {
        editing,
        dataIndex,
        name,
        title,
        inputType,
        inputProps,
        record,
        index,
        children,
        ...restProps
    } = props;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={name}
                    style={{ margin: 0 }}
                    valuePropName={inputType === "switch" ? "checked" : "value"}
                >
                    {inputNode(inputType, inputProps)}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const Device = () => {
    const dispatch = useDispatch();
    const {
        devices,
        summary,
        pagination,
        isProcessing,
        isCompleted,
        isLoading,
        isLoadingSummary,
    } = useSelector((state) => state.device);

    const [form] = Form.useForm();

    const [searchVal, setSearchVal] = useState("");
    const [searchGroup, setSearchGroup] = useState("");
    const [searchState, setSearchState] = useState("");
    const [searchPhase, setSearchPhase] = useState("");
    const [searchStatus, setSearchStatus] = useState("");
    const [searchPlatform, setSearchPlatform] = useState("");
    const [editingKey, setEditingKey] = useState("");
    const [selectedRows, setSelectedRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [states, setStates] = useState([]);
    const [phases, setPhases] = useState([]);
    const [groups, setGroups] = useState([]);
    const [openImportModal, setOpenImportModal] = useState(false);
    const [dataImport, setDataImport] = useState([]);

    const actionColumn = {
        title: "Thao tác",
        align: "center",
        width: "140px",
        fixed: "right",
        render: (record) => {
            const editable = record.id === editingKey;
            return editable ? (
                <Space size="small">
                    <Popconfirm
                        title="Sure to save?"
                        onConfirm={() => save(record.id)}
                    >
                        <Button type="primary" icon={<SaveOutlined />}>
                            Lưu
                        </Button>
                    </Popconfirm>
                    <a onClick={onCancelEdit}>Hủy</a>
                </Space>
            ) : (
                <Actions record={record} onEdit={onEdit} />
            );
        },
    };

    useEffect(() => {
        dispatch(deviceAction.fetchDevices(pagination));
        dispatch(deviceAction.fetchDeviceSummary());
        const fetchListFilter = async () => {
            try {
                const filterDevice = filter.device;
                const listGroup = await deviceApi.getList();
                if (listGroup.error) {
                    toast.error(listGroup.error);
                } else {
                    const _listGroup = listGroup.data.map((e) => ({
                        value: e.id,
                        label: e.name,
                    }));
                    setGroups(_listGroup);
                    setStates(filterDevice.state);
                    setPhases(filterDevice.phase);
                }
            } catch (err) {
                console.log(err);
                toast.error(err.message);
            }
        };
        fetchListFilter();
    }, []);

    useEffect(() => {
        if (isCompleted) {
            setOpenImportModal(false);
            if (editingKey) onCancelEdit();
        }
    }, [isCompleted]);

    useEffect(() => {
        setColumns([...COLUMNS, actionColumn]);
    }, [editingKey]);

    const onChangeValSearch = (e) => {
        setSearchVal(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            deviceAction.fetchDevices({
                ...pagination,
                searchVal: searchVal,
                state: searchState,
                phase: searchPhase,
                status: searchStatus,
                group_id: searchGroup,
                platform: searchPlatform,
                current: 1,
            })
        );
    };

    const filterOption = (input, option) => {
        return option.children.includes(input)
    }

    const onTableChange = async (pagination) => {
        dispatch(
            deviceAction.fetchDevices({
                ...pagination,
                searchVal: searchVal,
                state: searchState,
                phase: searchPhase,
                status: searchStatus,
                group_id: searchGroup,
                platform: searchPlatform,
            })
        );
    };

    const onSelectPlatformSearch = (platform) => {
        setSearchPlatform(platform);
        dispatch(
            deviceAction.fetchDevices({
                ...pagination,
                searchVal: searchVal,
                state: searchState,
                phase: searchPhase,
                status: searchStatus,
                group_id: searchGroup,
                platform: platform,
                current: 1,
            })
        );
    };

    const onSelectStatusSearch = (status) => {
        setSearchStatus(status);
        dispatch(
            deviceAction.fetchDevices({
                ...pagination,
                searchVal: searchVal,
                state: searchState,
                phase: searchPhase,
                status: status,
                group_id: searchGroup,
                platform: searchPlatform,
                current: 1,
            })
        );
    };

    const onSelectPhaseSearch = (phase) => {
        setSearchPhase(phase);
        dispatch(
            deviceAction.fetchDevices({
                ...pagination,
                searchVal: searchVal,
                state: searchState,
                phase: phase,
                status: searchStatus,
                group_id: searchGroup,
                platform: searchPlatform,
                current: 1,
            })
        );
    };

    const onSelectStateSearch = (state) => {
        setSearchState(state);
        dispatch(
            deviceAction.fetchDevices({
                ...pagination,
                searchVal: searchVal,
                state: state,
                phase: searchPhase,
                status: searchStatus,
                group_id: searchGroup,
                platform: searchPlatform,
                current: 1,
            })
        );
    };

    const onSelectGroupSearch = (group_id) => {
        setSearchGroup(group_id);
        dispatch(
            deviceAction.fetchDevices({
                ...pagination,
                searchVal: searchVal,
                state: searchState,
                phase: searchPhase,
                status: searchStatus,
                group_id: group_id,
                platform: searchPlatform,
                current: 1,
            })
        );
    };

    const onOpenImportModal = () => {
        setOpenImportModal(true);
    };

    const onCloseImportModal = () => {
        setOpenImportModal(false);
        dispatch(deviceAction.setIsProcessing(false));
    };

    const onImport = (dataImport) => {
        setDataImport(dataImport);
        dispatch(deviceAction.insertDevice(dataImport));
        setOpenImportModal(false);
    };

    const onExport = () => {
        dispatch(deviceAction.exportDevice());
    };

    const onUpdateAll = (value, keyAction) => {
        dispatch(
            deviceAction.updateAllSelected(selectedRows, value, keyAction)
        );
    };

    const onEdit = (record) => {
        const data = { ...record };
        form.setFieldsValue({
            ...data,
        });
        setEditingKey(record.id);
    };

    const onCancelEdit = () => {
        setEditingKey("");
    };

    const save = async (key) => {
        const editData = await form.validateFields();
        const recordData = devices.find((e) => e.id === key);
        const updateData = { ...recordData, ...editData };

        dispatch(deviceAction.updateDevice(updateData));
    };

    const mergedColumns = columns.map((col) => {
        if (!col.editable) return col;

        return {
            ...col,
            onCell: (record) => {
                const props = {
                    record,
                    inputType: COLUMNS.find((e) => e.cKey === col.cKey)
                        .editType,
                    inputProps: COLUMNS.find((e) => e.cKey === col.cKey).props,
                    name:
                        COLUMNS.find((e) => e.cKey === col.cKey).name ||
                        col.dataIndex,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: record.id === editingKey,
                };
                if (col.cKey === "group_id") {
                    props.inputProps.options = groups;
                }
                if (col.cKey === "state") {
                    props.inputProps.options = states;
                }
                if (col.cKey === "phase") {
                    props.inputProps.options = phases;
                }
                return props;
            },
        };
    });

    const rowSelection = {
        onChange: (_, selectedRows) => {
            setSelectedRows(_);
            setEditingKey("");
        },
    };

    return (
        <>
            <Row justify="space-between" align="middle">
                <Space size="middle">
                    <Typography.Title level={2}>
                        QUẢN LÝ DEVICE
                    </Typography.Title>
                </Space>
                <Space size="middle">
                    <Button
                        type="primary"
                        icon={<UploadOutlined />}
                        onClick={() => onOpenImportModal()}
                    >
                        Import
                    </Button>
                    <Button
                        className="bg-green-500 "
                        type="primary"
                        icon={<DownloadOutlined />}
                        onClick={() => onExport()}
                    >
                        Export
                    </Button>
                </Space>
            </Row>
            <br />
            <Row gutter={12} justify="start">
                <Col span={8}>
                    <Card loading={isLoadingSummary}>
                        <div className="text-2xl">
                            Tổng số profile:{" "}
                            <span className=" font-semibold">
                                {summary?.total || 0}
                            </span>
                        </div>
                        <div className="flex text-sm">
                            <span className="w-1/3">
                                WORKING: {summary?.working || 0}
                                <br />
                                REBOOT: {summary?.reboot || 0}
                                <br />
                                UPDATE NETWORK: {summary?.update_network || 0}
                                <br />
                                UNKNOWN STATE:{" "}
                                <span className="text-red-600 font-semibold">
                                    {summary?.total -
                                        (summary?.working +
                                            summary?.reboot +
                                            summary?.update_app +
                                            summary?.update_config +
                                            summary?.update_network +
                                            summary?.update_os +
                                            summary?.stop +
                                            summary?.sleep +
                                            summary?.reset_factory) || 0}
                                </span>
                            </span>
                            <span className="w-1/3">
                                STOP: {summary?.stop || 0}
                                <br />
                                RESET FACTORY: {summary?.reset_factory || 0}
                                <br />
                                UPDATE APP: {summary?.update_app || 0}
                            </span>
                            <span className="w-1/3">
                                SLEEP: {summary?.sleep || 0}
                                <br />
                                UPDATE OS: {summary?.update_os || 0}
                                <br />
                                UPDATE CONFIG: {summary?.update_config || 0}
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card loading={isLoadingSummary}>
                        <div className="text-2xl">
                            Tổng số profile:{" "}
                            <span className=" font-semibold">
                                {summary?.total || 0}
                            </span>
                        </div>
                        <div className="flex text-sm">
                            <span className="w-1/2">
                                FEED: {summary?.feed || 0}
                                <br />
                                SEEDING: {summary?.seeding || 0}
                                <br />
                                INIT: {summary?.init || 0}
                                <br />
                                UNKNOWN:{" "}
                                <span className="text-red-600 font-semibold">
                                    {summary?.total -
                                        (summary?.feed +
                                            summary?.seeding +
                                            summary?.init) || 0}
                                </span>
                            </span>
                            <span className="w-1/2">
                                (PLATFORM)
                                <br />
                                ANDROID: {summary?.android || 0}
                                <br />
                                IOS: {summary?.ios || 0}
                                <br />
                                UNKNOWN:{" "}
                                <span className="text-red-600 font-semibold">
                                    {summary?.total -
                                        (summary?.android + summary?.ios) || 0}
                                </span>
                            </span>
                        </div>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card loading={isLoadingSummary}>
                        <div className="text-2xl">
                            Tổng số profile:{" "}
                            <span className=" font-semibold">
                                {summary?.total}
                            </span>{" "}
                            (Nhiệt độ)
                        </div>
                        <div className="text-sm">
                            Nguy hiểm: {summary?.danger || 0} (Cao hơn 80 °C)
                            <br />
                            Cảnh báo: {summary?.warning || 0} (Cao hơn 50 °C)
                            <br />
                            Ổn định: {summary?.ok || 0} (Thấp hơn 50 °C)
                            <br />
                            UNKNOWN:{" "}
                            <span className="text-red-600 font-semibold">
                                {summary?.total -
                                    (summary?.ok +
                                        summary?.warning +
                                        summary?.danger) || 0}
                            </span>
                        </div>
                    </Card>
                </Col>
            </Row>
            <br />
            {selectedRows.length ? (
                <ActionAll onSubmit={onUpdateAll} options={groups} />
            ) : (
                <>&nbsp;</>
            )}
            <br />

            <Row justify="space-between" align="middle">
                <>&nbsp;</>
                <Space size="middle">
                <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Nền tảng"
                        onChange={onSelectPlatformSearch}
                    >
                        <Select.Option key="ANDROID" value="ANDROID">
                            ANDROID
                        </Select.Option>
                        <Select.Option key="IOS" value="IOS">
                            IOS
                        </Select.Option>
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Trạng thái"
                        onChange={onSelectStatusSearch}
                    >
                        <Select.Option key="1" value="1">
                            On
                        </Select.Option>
                        <Select.Option key="0" value="0">
                            Off
                        </Select.Option>
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Tình trạng"
                        onChange={onSelectStateSearch}
                    >
                        {states.map((e) => (
                            <Select.Option key={e.value} value={e.value}>
                                {e.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Nhóm"
                        onChange={onSelectGroupSearch}
                        filterOption={filterOption}
                    >
                        {groups.map((e) => (
                            <Select.Option key={e.value} value={e.value}>
                                {e.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 110 }}
                        placeholder="Phase"
                        onChange={onSelectPhaseSearch}
                    >
                        {phases.map((e) => (
                            <Select.Option key={e.value} value={e.value}>
                                {e.label}
                            </Select.Option>
                        ))}
                    </Select>
                    <Input.Search
                        placeholder="Tìm kiếm"
                        onChange={onChangeValSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                </Space>
            </Row>
            <br />
            <Form form={form} component={false}>
                <Table
                    scroll={{ x: "100%", y: 'calc(100vh - 680px)' }}
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                    sticky={{ offsetHeader: 65 }}
                    bordered
                    dataSource={devices}
                    rowKey={(record) => record.id}
                    columns={mergedColumns}
                    rowSelection={rowSelection}
                    loading={isLoading}
                    rowClassName="editable-row"
                    pagination={pagination}
                    // pagination={onTableChange}
                    onChange={onTableChange}
                />
            </Form>
            {openImportModal && (
                <ImportModal
                    open={openImportModal}
                    isProcessing={isProcessing}
                    onSubmit={onImport}
                    onCancel={onCloseImportModal}
                    data={dataImport}
                />
            )}
        </>
    );
};
export default Device;
