import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { Button, Col, Row, Table, Upload, Modal } from "antd";

function ImportModal(props) {
    const { open, onSubmit, onCancel, isProcessing, data: _data } = props;
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const onOK = () => {
        onSubmit(dataImport);
    };

    const columns = [
        {
            title: "Model",
            dataIndex: "model",
            fixed: "left",
            width: "130px",
        },
        {
            title: "Root Emei",
            dataIndex: "root_emei",
            fixed: "left",
            width: "190px",
        },
        {
            title: "Imei",
            dataIndex: "imei",
            width: "190px",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Root Serial",
            dataIndex: "root_serial",
            width: "170px",
        },
        {
            title: "Serial",
            dataIndex: "serial",
            width: "170px",
        },
        {
            title: "Android ID",
            dataIndex: "androidId",
            width: "180px",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            width: "130px",
        },
        {
            title: "Public IP",
            dataIndex: "public_ip",
            width: "160px",
        },
    ];

    return (
        <Modal
            title="Nhập Danh Sách Device Info"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="24">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`/files/temp/device_info_template.csv`}>
                        Download temp device info
                    </a>
                    <br />
                    <br />
                    {showPreviewTable && (
                        <Table
                            scroll={{ x: "100%", y: 400 }}
                            columns={columns}
                            dataSource={dataImport}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default ImportModal;
