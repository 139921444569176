const { useDispatch, useSelector } = require("react-redux");
import {
    CalendarOutlined,
    CarryOutOutlined,
    DownloadOutlined,
} from "@ant-design/icons";
import { Button, Input, Row, Segmented, Space, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { paginationParams } from "utils/constants";
import * as reportPlaylistAction from "./slice";
import ExportModal from "./components/ExportModal";

const { Paragraph } = Typography;

function ReportPlaylist() {
    const dispatch = useDispatch();
    const { playlists, pagination, isLoading, isProcessing } = useSelector(
        (state) => state.reportPlaylist
    );
    const [searchVal, setSearchVal] = useState("");
    const [searchTime, setSearchTime] = useState("");
    const [openExportModal, setOpenExportModal] = useState(false);

    useEffect(() => {
        dispatch(reportPlaylistAction.fetchPlaylists(pagination));
    }, []);

    const onTableChange = async (pagination) => {
        dispatch(reportPlaylistAction.fetchPlaylists({ ...pagination }));
    };

    const onChangeValSearch = (e) => {
        setSearchVal(e.target.value);
    };

    const onSearch = () => {
        dispatch(
            reportPlaylistAction.fetchPlaylists({
                ...pagination,
                searchVal: searchVal,
                searchTime: searchTime,
                current: 1,
            })
        );
    };

    const onSelectTimeSearch = (time) => {
        setSearchTime(time);
        dispatch(
            reportPlaylistAction.fetchPlaylists({
                ...pagination,
                searchVal: searchVal,
                searchTime: time,
                current: 1,
            })
        );
    };

    const onOpenExportModal = () => {
        setOpenExportModal(true);
    };

    const onCloseExportModal = () => {
        setOpenExportModal(false);
        dispatch(reportPlaylistAction.setIsProcessing(false));
    };

    const onExport = (chooseTime, enable, limit, ids) => {
        dispatch(reportPlaylistAction.exportReport({chooseTime, enable, limit, ids}));
        setOpenExportModal(false);
    };

    const columns = [
        {
            title: "ID",
            dataIndex: "id",
            width: "80px",
            fixed: "left",
        },
        {
            title: "Url",
            dataIndex: "url",
            width: "200px",
            fixed: "left",
            render: (name) => <a>{name}</a>,
        },
        {
            title: "Tiêu đề",
            dataIndex: "title",
            width: "260px",
            render: (data) => (
                <Paragraph
                    ellipsis={{ rows: 4, tooltip: data }}
                    style={{ fontSize: "16px" }}
                >
                    {data}
                </Paragraph>
            ),
        },
        {
            title: "Nhóm",
            dataIndex: "gr_name",
            width: "260px",
            render: (data) => {
                return data ? <div dangerouslySetInnerHTML={{ __html: data.replace(/,/g, ' , <br/>') }} /> : '';
            }
        },
        {
            title: "Thống kê Views - Giờ xem",
            children: [
                {
                    title: "Tổng Views đã xem",
                    dataIndex: "current_view",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
                {
                    title: "Tổng Giờ xem (h)",
                    dataIndex: "watch_time",
                    align: "center",
                    width: "120px",
                    render: (data) => (data/(60*60*1000)).toFixed(2) || 0
                },
            ],
        },
        {
            title: "Thống kê views nguồn views",
            children: [
                {
                    title: "Tìm kiếm",
                    dataIndex: "_SOURCE_SEARCH",
                    align: "center",
                    width: "120px",
                },
                {
                    title: "Đề xuất",
                    dataIndex: "_SOURCE_SUGGESTION",
                    align: "center",
                    width: "120px",
                },
                {
                    title: "Trang kênh",
                    dataIndex: "_VIEW_CHANNEL",
                    align: "center",
                    width: "120px",
                },
                {
                    title: "Nguồn khác",
                    dataIndex: "_SOURCE_EXTERNAL",
                    align: "center",
                    width: "120px",
                },
            ],
        },
        {
            title: "Thống kê tương tác",
            children: [
                {
                    title: "Like",
                    dataIndex: "total_like",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
                {
                    title: "Dislike",
                    dataIndex: "total_dislike",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
                {
                    title: "Sub",
                    dataIndex: "total_sub",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
                {
                    title: "CMT",
                    dataIndex: "total_comment",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
            ],
        },
        {
            title: "Thống kê xem dạo",
            children: [
                {
                    title: "Số lần xem dạo trước khi vào video",
                    dataIndex: "before_view_count",
                    align: "center",
                    width: "130px",
                    render: (data) => data || 0
                },
                {
                    title: "Số lần xem dạo sau khi vào video",
                    dataIndex: "after_view_count",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
            ],
        },
        {
            title: "Thống kê Ads",
            children: [
                {
                    title: "Số lần hiển thị Ads",
                    dataIndex: "total_ads",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
                {
                    title: "Watch Ads (No data)",
                    dataIndex: "watch_ads_count",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
                {
                    title: "Skip Ads (No data)",
                    dataIndex: "skip_ads_count",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
                {
                    title: "Click Ads",
                    dataIndex: "total_click_ads",
                    align: "center",
                    width: "120px",
                    render: (data) => data || 0
                },
            ],
        },
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>REPORT PLAYLIST</Typography.Title>
                <Space size="middle">
                    <Segmented
                        options={[
                            {
                                label: "Tất cả",
                                value: "",
                                icon: <CalendarOutlined />,
                            },
                            {
                                label: "Hôm nay",
                                value: "today",
                                icon: <CarryOutOutlined />,
                            },
                        ]}
                        size="large"
                        // value={searchPhase}
                        onChange={onSelectTimeSearch}
                    />
                    <Input.Search
                        placeholder="Tìm kiếm theo ID"
                        onChange={onChangeValSearch}
                        onSearch={onSearch}
                        enterButton
                        allowClear
                    />
                    <Button type="primary" icon={<DownloadOutlined />} onClick={() => onOpenExportModal()}>
                        Export
                    </Button>
                </Space>
            </Row>
            <br />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={playlists}
                rowKey={(record) => record.id}
                pagination={{
                    ...paginationParams,
                    ...pagination,
                }}
                scroll={{ x: "max-content", y: 'calc(100vh - 520px' }}
                sticky={{ offsetHeader: 75 }}
                onChange={onTableChange}
            />
            {openExportModal && (
                <ExportModal
                    open={openExportModal}
                    isProcessing={isProcessing}
                    onSubmit={onExport}
                    onCancel={onCloseExportModal}
                    //data={dataImport}
                />
            )}
        </>
    );
}

export default ReportPlaylist;
