import { UsergroupAddOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import AppConfig from "./AppConfig";
import api from "../api";
import { toast } from "react-toastify";

const { Modal, Row, Button, Tabs, Spin } = require("antd");

const defaultApp = { key: "app0", label: "New App" };

function ConfigAppModal(props) {
    const { open, onCancel, isProcessing, group } = props;

    const [activeKey, setActiveKey] = useState(
        `app${group?.configApp?.length - 1 || 0}`
    );
    const [tabs, setTabs] = useState([]);

    const [isLoading, setIsLoading] = useState(true);
    const [apps, setApps] = useState([]);
    const newTabIndex = useRef(group?.configApp?.length - 1 || 0);

    useEffect(() => {
        const fetchConfigs = async () => {
            setIsLoading(true);
            try {
                const data = await api.getConfigApps(group.id);
                if (data?.length)
                    setTabs(
                        data.map((e, i) => ({
                            key: `app${i}`,
                            label: e.name,
                            ...e,
                        }))
                    );
                else setTabs([defaultApp]);
            } catch (err) {
                setTabs([defaultApp]);
                console.log(err);
            } finally {
                setIsLoading(false);
            }
        };
        if (open) {
            if (group) {
                fetchConfigs();
            } else {
                setTabs([defaultApp]);
            }
        }
    }, [open]);

    useEffect(() => {
        setApps(tabs.map((e) => e.label));
    }, [tabs]);

    const onChange = (key) => {
        setActiveKey(key);
    };

    const onChangeAppName = (targetKey, appName) => {
        const targetTab = tabs.find((tab) => tab.key === targetKey);
        targetTab.label = appName;
        // console.log(items);
        setTabs([...tabs.filter((tab) => tab.key !== targetKey), targetTab]);
    };

    const add = () => {
        const newActiveKey = `app${Date.now()}`;
        setTabs([...tabs, { key: newActiveKey, label: `New App` }]);
        setActiveKey(newActiveKey);
    };

    const remove = (targetKey) => {
        const targetTab = tabs.find((pane) => pane.key === targetKey);
        if (targetTab.id) {
            Modal.confirm({
                title: `Xác nhận xóa config app ${targetTab.label}`,
                onOk: async () => {
                    // dispatch remove config
                    await onDeleteConfig(targetTab);
                },
                centered: true,
            });
        } else onRemove(targetKey);
    };

    const onRemove = (targetKey) => {
        const targetIndex = tabs.findIndex((pane) => pane.key === targetKey);
        const newPanes = tabs.filter((pane) => pane.key !== targetKey);
        if (newPanes.length && targetKey === activeKey) {
            const { key } =
                newPanes[
                    targetIndex === newPanes.length
                        ? targetIndex - 1
                        : targetIndex
                ];
            setActiveKey(key);
        }
        setTabs(newPanes);
    };

    const onDeleteConfig = async (config) => {
        setIsLoading(true);
        try {
            await api.deleteConfigApps(group.id, config);
            onRemove(config.key);
            toast.success(`Xóa config app ${config.name} thành công!`);
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const onSaveConfig = async (config) => {
        setIsLoading(true);
        try {
            const isCreate = !config.id;
            const data = isCreate
                ? await api.createConfigApps(group.id, config)
                : await api.updateConfigApps(group.id, config);
            toast.success(
                `${isCreate ? "Thêm" : "Cập nhật"} config app ${
                    config.name
                } thành công!`
            );
            if (isCreate) {
                tabs.find((e) => e.key === config.key).id = data;
                setTabs(tabs);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const onEdit = (targetKey, action) => {
        if (action === "add") {
            add();
        } else {
            remove(targetKey);
        }
    };

    return (
        <Modal
            title="CONFIG APP"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="660px"
            footer={[]}
        >
            <Row justify="space-between" align="middle">
                {group ? (
                    <h3>
                        <a>
                            <UsergroupAddOutlined /> {group?.name}
                        </a>
                    </h3>
                ) : (
                    <>&nbsp;</>
                )}
                <Button type="primary" ghost onClick={add}>
                    + Config new app
                </Button>
            </Row>
            <Spin spinning={isLoading} tip="Loading...">
                <Tabs
                    hideAdd
                    onChange={onChange}
                    activeKey={activeKey}
                    type="editable-card"
                    onEdit={onEdit}
                >
                    {tabs.map((tab) => (
                        <Tabs.TabPane tab={tab.label} key={tab.key}>
                            <AppConfig
                                tab={tab}
                                group={group}
                                apps={apps}
                                onSaveConfig={onSaveConfig}
                                onChangeAppName={onChangeAppName}
                            />
                        </Tabs.TabPane>
                    ))}
                </Tabs>
            </Spin>
        </Modal>
    );
}

export default ConfigAppModal;
