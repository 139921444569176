import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    plans: [],
    pagination: {
        showSizeChanger: true,
        showTotal: true,
        current: 1,
        pageSize: 10,
    },
    isLoading: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "plan",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setPlans: (state, action) => {
            state.plans = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
        addPlan: (state, action) => {
            state.isCompleted = true;
            state.plans.unshift(action.payload);
        },
        editPlan: (state, action) => {
            state.isCompleted = true;
            state.plans = state.plans.map((plan) =>
                plan.id === action.payload.id ? action.payload : plan
            );
        },
        removePlan: (state, action) => {
            state.isCompleted = true;
            state.plans = state.plans.filter(
                (plan) => plan.id !== action.payload
            );
        },
        removePlans: (state, action) => {
            state.isCompleted = true;
            state.plans = state.plans.filter(
                (plan) => !action.payload.includes(plan.id)
            );
        },
    },
});

export const {
    setIsLoading,
    setIsCompleted,
    setIsProcessing,
    setPlans,
    setPagination,
    addPlan,
    editPlan,
    removePlan,
    removePlans,
} = slice.actions;

export const fetchPlans = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setPlans(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const updatePlan = (plan) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.update(plan);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(editPlan(data));
                toast.success("Cập nhật plan thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deletePlan = (id) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.delete(id);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePlan(id));
                toast.success("Xóa plan thành công!");
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const deletePlans = (ids) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.deleteMany(ids);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                dispatch(removePlans(ids));
                toast.success(`Xóa ${ids.length} plan đã chọn thành công!`);
                dispatch(setIsCompleted(true));
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const importPlan = (plans) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            dispatch(setIsCompleted(false));
            const data = await api.import_(plans);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import plan thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchPlans({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export default slice.reducer;
