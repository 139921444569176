import { createSlice } from "@reduxjs/toolkit";
import api from "./api";
import { toast } from "react-toastify";

const initialState = {
    comments: [],
    summary: {},
    filter: [],
    pagination: { current: 1, pageSize: 10 },
    isLoading: false,
    isLoadingFilter: false,
    isLoadingSummary: false,
    isProcessing: false,
    isCompleted: false,
};

export const slice = createSlice({
    name: "comment",
    initialState,
    reducers: {
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setIsLoadingFilter: (state, action) => {
            state.isLoadingFilter = action.payload;
        },
        setIsLoadingSummary: (state, action) => {
            state.isLoadingSummary = action.payload;
        },
        setIsProcessing: (state, action) => {
            if (action.payload === true) state.isCompleted = false;
            state.isProcessing = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.isCompleted = action.payload;
        },
        setComment: (state, action) => {
            state.comments = action.payload;
        },
        setSummary: (state, action) => {
            state.summary = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setPagination: (state, action) => {
            state.pagination = action.payload;
        },
    },
});

export const {
    setIsLoading,
    setIsLoadingFilter,
    setIsLoadingSummary,
    setIsCompleted,
    setIsProcessing,
    setComment,
    setSummary,
    setFilter,
    setPagination,
} = slice.actions;

export const fetchCommentSummary = () => {
    return async (dispatch) => {
        dispatch(setIsLoadingSummary(true));
        try {
            const data = await api.getSummary();
            dispatch(setSummary(data.data));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingSummary(false));
        }
    };
};

export const fetchFilter = () => {
    return async (dispatch) => {
        dispatch(setIsLoadingFilter(true));
        try {
            const data = await api.getFilter();
            dispatch(setFilter(data.data.target));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoadingFilter(false));
        }
    };
};

export const fetchComments = (params) => {
    return async (dispatch) => {
        dispatch(setIsLoading(true));
        try {
            const data = await api.getAll(params);
            dispatch(setComment(data.data));
            dispatch(setPagination(data.meta));
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsLoading(false));
        }
    };
};

export const insertComment = (listComment) => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.insert(listComment);
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                toast.success("Import danh sách comment thành công");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchComments({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

export const exportComment = () => {
    return async (dispatch) => {
        try {
            dispatch(setIsProcessing(true));
            const data = await api.exportComment();
            if (data.error) {
                toast.error(data.error.msg);
            } else {
                exp(data);
                toast.success("Đã export danh sách comment");
                dispatch(setIsCompleted(true));
                dispatch(
                    fetchComments({ pagination: { current: 1, pageSize: 10 } })
                );
            }
        } catch (err) {
            console.log(err);
        } finally {
            dispatch(setIsProcessing(false));
        }
    };
};

const exp = (data) => {
    try {
        var blob = new Blob([data]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `comments.csv`;
        link.click();
    } catch (error) {
        console.log(error);
    }
};

export default slice.reducer;
