import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/clicks/manage";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/clicks/manage/summary";
        return axiosClient.get(url, { params });
    },
    getFilter: (params) => {
        const url = "/api/clicks/manage/filter";
        return axiosClient.get(url, { params });
    },
    checking: (params) => {
        const url = "/api/clicks/manage/checking";
        return axiosClient.get(url, { params });
    },
    update: (payload) => {
        const url = `/api/clicks/manage/${payload.id}`;
        return axiosClient.put(url, payload);
    },
    report: (payload) => {
        const url = `/api/clicks/manage/report`;
        return axiosClient.post(url, payload);
    },
    getTotalReport: (params) => {
        const url = "/api/clicks/manage/totalReport";
        return axiosClient.get(url, { params });
    },
};
