import axiosClient from "utils/api/axios-client";

export default {
    getAll: (params) => {
        const url = "/api/devices";
        return axiosClient.get(url, { params });
    },
    getSummary: (params) => {
        const url = "/api/devices/summary";
        return axiosClient.get(url, { params });
    },

    getList: (params) => {
        const url = "/api/group-users/list";
        return axiosClient.get(url, { params });
    },
    insert: (payload) => {
        const url = `/api/devices`;
        return axiosClient.post(url, payload);
    },
    exportDevice: () => {
        const url = `/api/devices/export`;
        return axiosClient.get(url);
    },
    update: (payload) => {
        const url = `/api/devices/${payload.id}`;
        return axiosClient.put(url, payload);
    },

    updateAllSelected: (ids, value, keyAction) => {
        const url = `/api/devices/update-all-selected`;
        return axiosClient.patch(url, { ids, value, keyAction });
    },
};
