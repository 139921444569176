import { configureStore } from "@reduxjs/toolkit";

import auth from "features/auth/slice";
import project from "features/project/slice";
import groupUser from "features/group-user/slice";
import host from "features/host/slice";
import deviceInfo from "features/device-info/slice";
import simInfo from "features/sim-info/slice";
import profile from "features/profile/slice";
import proxy from "features/proxy/slice";
import device from "features/device/slice";
import plan from "features/plan/slice";
import playlist from "features/playlist/slice";
import reportPlan from "features/report-plan/slice";
import reportPlaylist from "features/report-playlist/slice";
import reportProfile from "features/report-profile/slice";
import comment from "features/comment/slice";
import channel from "features/channel/slice";

export const store = configureStore({
    reducer: {
        auth,
        project,
        groupUser,
        host,
        deviceInfo,
        simInfo,
        profile,
        proxy,
        plan,
        playlist,
        channel,
        reportPlan,
        reportPlaylist,
        device,
        reportProfile,
        comment,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});
