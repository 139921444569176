const { useDispatch, useSelector } = require("react-redux");
import { Button, Row, Table, Typography, Tag, Select, Space } from "antd";
import * as reportPlanAction from "./slice";
import { paginationParams } from "utils/constants";
import { useEffect, useState } from "react";
import { DownloadOutlined, SyncOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ExportModal from "./components/ExportModal";

const { Paragraph } = Typography;

function ReportPlan() {
    const dispatch = useDispatch();
    const { plans, pagination, isLoading, isProcessing } = useSelector(
        (state) => state.reportPlan
    );

    const [openExportModal, setOpenExportModal] = useState(false);

    useEffect(() => {
        dispatch(reportPlanAction.fetchPlans(pagination));
    }, []);

    const onTableChange = async (pagination) => {
        dispatch(reportPlanAction.fetchPlans({ ...pagination }));
    };

    const onSelectSearchStatus = (status) => {
        dispatch(
            reportPlanAction.fetchPlans({
                ...pagination,
                status: status,
            })
        );
    };

    const onOpenExportModal = () => {
        setOpenExportModal(true);
    };

    const onCloseExportModal = () => {
        setOpenExportModal(false);
        dispatch(reportPlanAction.setIsProcessing(false));
    };

    const onExport = (status, limit, ids) => {
        dispatch(reportPlanAction.exportReport({status, limit, ids}));
        setOpenExportModal(false);
    };

    const columns = [
        {
            title: "Playlist ID",
            dataIndex: "playlist_id",
            width: "120px",
            fixed: "left",
            // render: (name) => <a>{name}</a>,
        },
        {
            title: "Url",
            dataIndex: "url",
            fixed: "left",
            width: "200px",
            render: (data) => <a>{data}</a>
        },
        {
            title: "Tiêu đề",
            dataIndex: "title",
            width: "220px",
            render: (data) => (
                <Paragraph
                    ellipsis={{ rows: 2, tooltip: data }}
                >
                    {data}
                </Paragraph>
            ),
        },
        {
            title: "Thời gian bắt đầu",
            dataIndex: "start_time",
            width: "180px",
            align: "left",
            render: (data) => (data ? dayjs(data).format("DD/MM/YYYY HH:mm") : ""),
        },
        {
            title: "Thời gian kết thúc",
            dataIndex: "stop_time",
            width: "180px",
            align: "left",
            render: (data) => (data ? dayjs(data).format("DD/MM/YYYY HH:mm") : ""),
        },
        {
            title: "Giờ report",           
            dataIndex: "report_time",
            width: "120px",
            align: "center",
            render: (data) => (data ? dayjs(data).format("HH:mm") : ""),
        },
        {
            title: "Kết quả Report",   
            dataIndex: "result_report",
            align: "center",
            width: "150px",
        },
        {
            title: "Trạng thái",  
            dataIndex: "status",
            align: "center",
            width: 120,
            render: (data) => {
                if (data === "NEW") return <Tag color="blue">New</Tag>;
                if (data === "RUNNING")
                    return (
                        <Tag icon={<SyncOutlined spin />} color="gold">
                            Running
                        </Tag>
                    );
                if (data === "DONE") return <Tag color="green">Done</Tag>;
                return <Tag>Null</Tag>;
            },
        },
        {
            title: "Giờ xem (h)",
            dataIndex: "watch_time",
            align: "center",
            width: "120px",
            render: (data) => (data/(60*60*1000)).toFixed(2) || 0
        },
        {
            title: "Views đã xem",
            dataIndex: "current_view",
            align: "center",
            width: "140px",
            render: (data) => data || 0
        },
        {
            title: "Lượt like",
            dataIndex: "current_like",
            align: "center",
            width: "100px",
            render: (data) => data || 0
        },
        {
            title: "Số bình luận",
            dataIndex: "current_comment",
            align: "center",
            width: "130px",
            render: (data) => data || 0
        },
        {
            title: "Số Sub",
            dataIndex: "current_sub",
            align: "center",
            width: "100px",
            render: (data) => data || 0
        },
        {
            title: "Lượt click Ads",
            dataIndex: "current_clickAds",
            align: "center",
            width: "140px",
            render: (data) => data || 0
        },
  
    ];

    return (
        <>
            <Row justify="space-between" align="middle">
                <Typography.Title level={2}>REPORT PLAN</Typography.Title>
                <Space size="middle">
                <Select
                        allowClear
                        showSearch
                        style={{ width: 200 }}
                        placeholder="Tìm theo trạng thái"
                        onChange={onSelectSearchStatus}
                    >
                        <Select.Option key="new" value="NEW">
                            New
                        </Select.Option>
                        <Select.Option key="running" value="RUNNING">
                            Running
                        </Select.Option>
                        <Select.Option key="done" value="DONE">
                            Done
                        </Select.Option>
                    </Select>
                <Button type="primary" icon={<DownloadOutlined />} onClick={() => onOpenExportModal()}>
                    Export
                </Button>
                </Space>
            </Row>
            <br />
            <Table
                bordered
                loading={isLoading}
                columns={columns}
                dataSource={plans}
                rowKey={(record) => record.id}
                pagination={{
                    ...paginationParams,
                    ...pagination,
                }}
                scroll={{ x: "max-content", y: 'calc(100vh - 410px' }}
                onChange={onTableChange}
            />
            {openExportModal && (
                <ExportModal
                    open={openExportModal}
                    isProcessing={isProcessing}
                    onSubmit={onExport}
                    onCancel={onCloseExportModal}
                    //data={dataImport}
                />
            )}
        </>
    );
}

export default ReportPlan;
