import { UploadOutlined } from "@ant-design/icons";
import { useState } from "react";
import * as csvtojson from "csvtojson";
import { toast } from "react-toastify";
import { Button, Col, Row, Table, Upload, Modal } from "antd";

function ImportModal(props) {
    const { open, onSubmit, onCancel, isProcessing, data: _data } = props;
    const [dataImport, setDataImport] = useState(_data || []);
    const [showPreviewTable, setShowPreviewTable] = useState(false);

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson()
                .fromString(text)
                .then((jsonObject) => {
                    setDataImport(jsonObject);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            setShowPreviewTable(true);
            return false;
        },
    };

    const onOK = () => {
        onSubmit(dataImport);
    };

    const columns = [
        {
            title: "Serial No",
            dataIndex: "serialNo",
            fixed: "left",
            width: "200px",
        },
        {
            title: "Platform",
            dataIndex: "platform",
            fixed: "left",
            width: "120px",
        },
        {
            title: "ipAddr",
            dataIndex: "ipAddr",
            width: "170px",
            ellipsis: {
                showTitle: false,
            },
        },
        {
            title: "Host",
            dataIndex: "vm_id",
            width: "120px",
        },
        {
            title: "Nhóm",
            dataIndex: "group_id",
            width: "100px",
        },
        {
            title: "Max User",
            dataIndex: "maxUser",
            width: "120px",
        },
        {
            title: "State",
            dataIndex: "state",
            width: "120px",
        },
        {
            title: "Phase",
            dataIndex: "phase",
            width: "90px",
        },
        {
            title: "Activity",
            dataIndex: "activity",
            width: "150px",
        },
        {
            title: "Time/Ses",
            dataIndex: "time_per_ses",
            width: "150px",
        },
        {
            title: "Use Static",
            dataIndex: "use_static_proxy",
            width: "150px",
        },
        {
            title: "Skip Watch",
            dataIndex: "skip_watch",
            width: "150px",
        },
        {
            title: "Ver Bypass",
            dataIndex: "version_bypass",
            width: "150px",
        },
    ];

    return (
        <Modal
            title="Nhập Danh Sách Device"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width="1000px"
        >
            <Row gutter={50}>
                <Col span="24">
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                    </Upload>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <a href={`/files/temp/device_template.csv`}>
                        Download temp device
                    </a>
                    <br />
                    <br />
                    {showPreviewTable && (
                        <Table
                            scroll={{ x: "100%", y: 400 }}
                            columns={columns}
                            dataSource={dataImport}
                            pagination={{ pageSize: 5 }}
                        />
                    )}
                </Col>
            </Row>
        </Modal>
    );
}

export default ImportModal;
