import { UploadOutlined } from "@ant-design/icons";
import { Button, Modal, Tag, Upload } from "antd";
import * as csvtojson from "csvtojson";
import { useState } from "react";
import { toast } from "react-toastify";

function ConfigKeywordsModal(props) {
    const {
        open,
        onSubmit,
        onCancel,
        isProcessing,
        group,
        data: _data,
    } = props;
    const [keywords, setKeywords] = useState(
        group?.keywords?.split(",") || _data || []
    );

    const handleUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            csvtojson({ noheader: true })
                .fromString(text)
                .then((jsonObject) => {
                    const data = jsonObject
                        .map((e) => e.field1.trim())
                        .filter((e) => !!e);
                    setKeywords(data);
                })
                .catch((err) => {
                    console.log(err);
                    toast.error("File not valid");
                });
        };
        reader.readAsText(file);
        return false;
    };

    const uploadProps = {
        showUploadList: false,
        beforeUpload: (file) => {
            handleUpload(file);
            return false;
        },
    };

    const onOK = () => {
        onSubmit(keywords.join(","));
    };

    return (
        <Modal
            title="Cấu hình từ khóa"
            okText="Lưu"
            cancelText="Huỷ"
            centered
            open={open}
            onOk={onOK}
            onCancel={onCancel}
            confirmLoading={isProcessing}
            width={600}
        >
            <div style={{ maxHeight: "500px", overflowY: "auto" }}>
                <Upload {...uploadProps}>
                    <Button icon={<UploadOutlined />}>Chọn file CSV</Button>
                </Upload>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <a href={`/files/temp/group_keywords_template.csv`}>
                    Download temp
                </a>
                <br />
                <h3>Danh sách từ khóa ({keywords.length}):</h3>
                {keywords
                    .filter((e) => !!e)
                    .map((e) => (
                        <Tag
                            bordered={false}
                            key={e}
                            style={{
                                fontSize: 16,
                                lineHeight: "16px",
                                padding: "4px 8px 6px",
                                marginBottom: "8px",
                            }}
                        >
                            {e}
                        </Tag>
                    ))}
            </div>
        </Modal>
    );
}

export default ConfigKeywordsModal;
