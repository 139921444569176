function Dashboard() {
    return (
        <div
            className="flex justify-center items-center"
            style={{ height: "80vh" }}
        >
            <h2>From BoostGo With Love ❤️</h2>
        </div>
    );
}
export default Dashboard;
