import {
    Button,
    Card,
    Col,
    Modal,
    Row,
    Select,
    Space,
    Statistic,
    Table,
    Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AndroidOutlined, UserOutlined, FolderOpenOutlined } from "@ant-design/icons";
import { paginationParams } from "utils/constants";
import * as hostAction from "./slice";
import hostApi from "./api";
import moment from "moment/moment";

function Host() {
    const dispatch = useDispatch();
    const { hosts, summary, pagination, isLoading, isLoadingSummary } =
        useSelector((state) => state.host);

    const [searchPlaylistId, setSearchPlaylistId] = useState("");

    const [lpl, setLpl] = useState([]);
    const [showCardDetail, setShowCardDetail] = useState(false);
    const [idd, setIdd] = useState(null);
    const [title, setTitle] = useState("Profiles");
    const [countEmail, setCountEmail] = useState(0);
    const [countReport, setCountReport] = useState(0);
    const [lastClick, setLastClick] = useState("");
    const [showTable, setShowTable] = useState(false);


    const onClickClick = async (id, subable) => {
        const checkFunc = async () => {
            try {
                const res = await hostApi.checking({ id, subable });
                if (res.error) {
                    toast.error(res.error);
                } else {
                    if (res.checking) {
                        Modal.confirm({
                            title: `Xác nhận click!`,
                            content: (
                                <>
                                    Thông tin device:
                                    <br />
                                    Ip Address: {res.info?.ipAddr}
                                    <br />
                                    Remote Id: {res.info?.remote_id}
                                    <br />
                                    Password: {res.info?.password}
                                </>
                            ),
                            centered: true,
                        });
                        onOkClick(id);
                    } else {
                        toast.error(
                            `Phiên của email này đã thay đổi, vui lòng chọn email khác hoặc "Refresh" data`
                        );
                    }
                }
            } catch (error) {
                toast.error(error);
            }
        };
        checkFunc();
    };

    const onOkClick = async (key) => {
        const recordData = hosts.find((e) => e.id === key);
        const updateData = { ...recordData, is_clicking_ads: 1 };
        dispatch(hostAction.updateClick(updateData));
    };

    const onClickShowInfo = async (id, subable) => {
        const infoFunc = async () => {
            try {
                const res = await hostApi.checking({ id, subable });
                if (res.error) {
                    toast.error(res.error);
                } else {
                    Modal.confirm({
                        title: `Thông tin cần cho việc click!!`,
                        content: (
                            <>
                                Ip Address: {res.info?.ipAddr}
                                <br />
                                Remote Id: {res.info?.remote_id}
                                <br />
                                Password: {res.info?.password}
                            </>
                        ),
                        centered: true,
                    });
                }
            } catch (error) {
                toast.error(error);
            }
        };
        infoFunc();
    };

    const onCancel = async (id, subable) => {
        Modal.confirm({
            title: `Xác nhận Cancel click`,
            content: `Bạn xác nhận sẽ cancel hành động click lần này!!`,
            onOk: () => onOkCancel(id, subable),
            centered: true,
        });
    };

    const onOkCancel = async (key, subable) => {
        const recordData = hosts.find((e) => e.id === key);
        const updateData = { ...recordData, subable: null, is_clicking_ads: 0 };
        dispatch(hostAction.updateClick(updateData));
        dispatch(hostAction.fetchHosts({ id: subable }));
    };

    const onReport = async (id, subable) => {
        Modal.confirm({
            title: `Report`,
            content: (<>
                Nhấn <b>OK</b> nếu bạn đã hoàn thành việc click!!
            </>),
            onOk: () => onOkReport(id, subable),
            centered: true,
        });
    };

    const onOkReport = async (id, subable) => {
        const infoFunc = async () => {
            try {
                const res = await hostApi.report({id, subable});
                if (res.error) {
                    toast.error(res.error);
                } else {
                    toast.success("Report thành công");
                }
            } catch (error) {
                toast.error(error);
            }
        };
        await infoFunc();
        dispatch(hostAction.fetchHosts({ id: subable }));
    }

    useEffect(() => {
        dispatch(hostAction.fetchHostSummary());
        const fetchListFilter = async () => {
            try {
                const listPlaylist = await hostApi.getFilter();
                if (listPlaylist.error) {
                    toast.error(listPlaylist.error);
                } else {
                    const _listPlaylist = listPlaylist.data.playlists.map(
                        (e) => ({
                            value: e.id,
                            label: `${e.title} (${e.url_type})`,
                        })
                    );
                    setLpl(_listPlaylist);
                }
            } catch (err) {
                console.log(err);
                // toast.error(err.message);
            }
        };
        fetchListFilter();
    }, []);

    const onSelectSearchPlaylist = (option) => {
        if (option == undefined) {
            setIdd(null);
            setShowTable(false);
            setShowCardDetail(false);
        } else {
            setShowTable(true);
            setShowCardDetail(true);
            setIdd(option.value);
            setSearchPlaylistId(option.value);
            setTitle(option.label);
            dispatch(
                hostAction.fetchHosts({
                    ...pagination,
                    id: option.value,
                })
            );
        }
    };

    const onClickRefresh = (id) => {
        dispatch(
            hostAction.fetchHosts({
                id: id,
            })
        );
        toast.info('Đã làm mới data');
    }

    useEffect(() => {
        if (idd || hosts.length > 0) {
            
            setCountEmail(pagination.total);
            const getTotalReport = async () => {
                try {
                    const getTotal = await hostApi.getTotalReport({idd});
                    if (getTotal.error) {
                        toast.error(getTotal.error);
                    } else {
                        setCountReport(getTotal.data.total);
                        console.log('abc');
                        if(getTotal.data.lastClick.length > 0) {
                            setLastClick(moment(getTotal.data.lastClick[0].create_time).format("HH:mm DD/MM"));
                        } else {
                            setLastClick("null")
                        }
                        
                    }
                } catch (err) {
                    toast.error(err.message);
                }
            };
            getTotalReport();
        }
    }, [hosts]);

    const filterOption = (input, option) => {
        return option.children.includes(input);
    };

    const onTableChange = async (pagination) => {
        dispatch(
            hostAction.fetchHosts({
                ...pagination,
                id: searchPlaylistId,
            })
        );
    };

    const columns = [
        {
            title: "Email",
            dataIndex: "email",
            width: "140px",
            fixed: "left",
        },
        {
            title: "Playlist ID",
            dataIndex: "subable",
            width: "45px",
        },
        {
            title: "URL",
            dataIndex: "url",
            width: "130px",
        },
        {
            title: "URL Type",
            dataIndex: "url_type",
            width: "45px",
        },
        {
            title: "Title",
            dataIndex: "title",
            width: "100px",
            align: "left",
        },
        {
            title: "Thao tác",
            align: "left",
            width: "100px",
            render: (record) => {
                return (
                    <>
                        {record.is_clicking_ads == 0 && (
                            <Button
                                className="mr-1"
                                onClick={() =>
                                    onClickClick(record.id, record.subable)
                                }
                            >
                                Click
                            </Button>
                        )}
                        {record.is_clicking_ads == 1 && (
                            <>
                                <Button
                                    danger
                                    className="mr-1"
                                    onClick={() =>
                                        onCancel(record.id, record.subable)
                                    }
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="mr-1 border-blue-500 text-blue-500"
                                    onClick={() =>
                                        onReport(record.id, record.subable)
                                    }
                                >
                                    Report
                                </Button>
                                <Button
                                    className="border-green-500 text-green-500"
                                    onClick={() =>
                                        onClickShowInfo(
                                            record.id,
                                            record.subable
                                        )
                                    }
                                >
                                    Info
                                </Button>
                            </>
                        )}
                    </>
                );
            },
        },
    ];
    return (
        <>
            <Typography.Title level={2}>Clicks Mobile Ads</Typography.Title>
            <br />
            <Row gutter={12} justify="start">
                <Col span={6}>
                    <Card>
                        <Statistic
                            title="Playlist Enable"
                            value={summary.total}
                            prefix={<AndroidOutlined />}
                            loading={isLoadingSummary}
                        />
                    </Card>
                </Col>
                {showCardDetail && (
                    <>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title={title}
                                value={countEmail}
                                prefix={<UserOutlined />}
                            />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card>
                            <Statistic
                                title={`Total Report Click - ( ${lastClick} )`}
                                value={countReport}
                                prefix={<FolderOpenOutlined />}
                            />
                        </Card>
                    </Col>
                    </>
                    
                    
                )}
            </Row>
            <br />
            <Row justify="space-between" align="middle">
                <Space size="middle">
                    <Select
                        allowClear
                        showSearch
                        style={{ width: 250 }}
                        placeholder="Tìm theo playlist"
                        onChange={onSelectSearchPlaylist}
                        filterOption={filterOption}
                        labelInValue
                    >
                        {lpl?.map((e) => (
                            <Select.Option key={e.value} value={e.value}>
                                {e.label}
                            </Select.Option>
                        ))}
                    </Select>
                    {
                        idd && <Button type="primary" onClick={() => onClickRefresh(idd)}>Refresh </Button>
                    }
                    
                </Space>
            </Row>
            {/* <Divider /> */}
            <br />
            {showTable && (
                <Table
                    bordered
                    loading={isLoading}
                    columns={columns}
                    dataSource={hosts}
                    rowKey={(record) => record.id}
                    pagination={{
                        ...paginationParams,
                        ...pagination,
                    }}
                    scroll={{ x: 1500, y: "calc(100vh - 600px)" }}
                    onChange={onTableChange}
                />
            )}
        </>
    );
}

export default Host;
